import FullscreenModal from 'components/FullscreenModal';
import IconButton from 'components/IconButton';
import Typography from 'components/Typography';

import { schema, useRole, useTraining } from 'hooks/data';
import { useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import { getVideoUrl } from 'utils/jwplayer';
import useResponsive from 'hooks/useResponsive';
import { rgba } from 'polished';
import MascotMessage from 'components/MascotMessage';
import Spacer from 'components/Spacer';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import ConfirmationModal from 'components/ConfirmationModal';
import ProgressBar from 'components/ProgressBar';
import { UserRole } from 'utils/user';
import { ReactComponent as BackIcon } from './back.svg';
import { ReactComponent as ForwardIcon } from './forward.svg';
import { ReactComponent as PauseIcon } from './pause.svg';
import { ReactComponent as PlayIcon } from './play.svg';
import { ReactComponent as ExitIcon } from './exit.svg';
import { ReactComponent as OverlayBackIcon } from './overlay_back.svg';

type UsePlayerOptions = {
  onComplete?: () => any;
};

function usePlayer({ onComplete }: UsePlayerOptions) {
  const [videoEl, setVideoEl] = useState<HTMLVideoElement | null>(null);
  const [current, setCurrent] = useState(0);
  const [playlist, setPlaylist] = useState<string[]>([]);
  const [paused, setPaused] = useState(false);
  const [completed, setCompleted] = useState(false);
  const reset = useCallback(() => {
    setCurrent(0);
    setPlaylist([]);
    setPaused(false);
    setCompleted(false);
  }, []);
  const skip = useCallback(
    (time: number) => {
      if (videoEl) {
        videoEl.currentTime = videoEl.currentTime + time;
      }
    },
    [videoEl]
  );
  useEffect(() => {
    if (videoEl && playlist[current!]) {
      videoEl.src = playlist[current!];
      videoEl.play();
    }
  }, [current, playlist, videoEl]);
  return {
    videoEl,
    current,
    setCurrent,
    playlist,
    setPlaylist,
    reset,
    paused,
    back: () => skip(-10),
    forward: () => skip(10),
    pause: () => videoEl?.pause(),
    play: () => {
      videoEl?.play();
      completed && setCompleted(false);
    },
    videoProps: {
      ref: setVideoEl,
      onEnded: () => {
        if (current === playlist.length - 1) {
          // complete
          setCompleted(true);
          onComplete?.();
        } else {
          // next video
          setCurrent((prev) => prev + 1);
        }
      },
      onPause: () => setPaused(true),
      onPlay: () => setPaused(false),
    },
    completed,
  };
}

export default function TrainingVideoModal() {
  const history = useHistory();
  const { isNotDesktop } = useResponsive();
  const { trainingVideo } = useStore();
  const role = useRole();
  const training = useTraining(trainingVideo.state?.training);
  const [isClose, setClose] = useState(false);
  const exercises = useMemo(() => {
    const ret: Array<schema['Exercise']> = [];
    training.data?.exercise_types.forEach((exerciseType) => {
      exerciseType.exercises.forEach((exercise) => {
        if (exercise.video_code) {
          ret.push(exercise);
        }
      });
    });
    return ret;
  }, [training.data?.exercise_types]);
  const {
    setPlaylist,
    reset,
    paused,
    videoProps,
    videoEl,
    pause,
    play,
    back,
    forward,
    completed,
    current,
  } = usePlayer({
    onComplete: () => {
      if (trainingVideo.state?.schedule) {
        history.push(`/feedback/${trainingVideo.state?.schedule}`);
        onRequestClose();
      }
    },
  });
  const totalDuration = useMemo(() => {
    let total = 0;
    exercises.forEach((exercise) => {
      total += exercise.video_duration!;
    });
    return total;
  }, [exercises]);
  const [currentDuration, setCurrentDuration] = useState(0);
  const onRequestClose = useCallback(() => {
    trainingVideo.setState(undefined);
    setCurrentDuration(0);
    reset();
    setClose(false);
  }, [reset, trainingVideo]);
  const manualOnRequestClose = useCallback(() => {
    if (role === UserRole.STUDENT) {
      setClose(true);
    } else {
      onRequestClose();
    }
  }, [onRequestClose, role]);
  const onTimeUpdate = useCallback(() => {
    if (videoEl) {
      let passedDuration = 0;
      exercises.forEach((exercise, i) => {
        if (i < current) {
          passedDuration += exercise.video_duration!;
        }
      });
      setCurrentDuration(passedDuration + videoEl.currentTime);
    }
  }, [current, exercises, videoEl]);

  useEffect(() => {
    setPlaylist(exercises.map((exercise) => getVideoUrl(exercise.video_code!)));
  }, [exercises, setPlaylist]);

  return (
    <TrainingVideoModal.Root
      isOpen={!!trainingVideo.state}
      onRequestClose={manualOnRequestClose}
    >
      <TrainingVideoModal.VideoContainer>
        <video playsInline onTimeUpdate={onTimeUpdate} {...videoProps} />
        {(paused || completed) && (
          <TrainingVideoModal.VideoOverlay>
            <Spacer />
            <MascotMessage>
              {completed
                ? 'Тренировка закончена'
                : 'Не прерывай тренировку дольше 5 минут, иначе мышцы остынут и заниматься будет сложнее!'}
            </MascotMessage>
            <Spacer />
            {!completed && (
              <TrainingVideoModal.VideoOverlayBack>
                Вернись к тренировке
                <OverlayBackIcon />
              </TrainingVideoModal.VideoOverlayBack>
            )}
          </TrainingVideoModal.VideoOverlay>
        )}
      </TrainingVideoModal.VideoContainer>
      <ProgressBar
        value={Math.min((currentDuration / totalDuration) * 100, 100)}
      />
      <TrainingVideoModal.Controls>
        <IconButton onClick={back}>
          <BackIcon />
        </IconButton>
        <IconButton onClick={paused ? play : pause}>
          {paused ? <PlayIcon /> : <PauseIcon />}
        </IconButton>
        <IconButton onClick={forward}>
          <ForwardIcon />
        </IconButton>
      </TrainingVideoModal.Controls>
      {isNotDesktop && (
        <TrainingVideoModal.Exit onClick={manualOnRequestClose}>
          <ExitIcon />
          Выйти из тренировки
        </TrainingVideoModal.Exit>
      )}

      <ConfirmationModal
        isOpen={isClose}
        onConfirm={onRequestClose}
        onRequestClose={() => setClose(false)}
        text="Ты точно хочешь выйти?"
        description="Если ты прервёшь тренировку, то прогресс не сохранится"
        confirmText="Да, я хочу выйти"
        cancelText="Нет"
      />
    </TrainingVideoModal.Root>
  );
}

TrainingVideoModal.VideoOverlay = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: ${(props) => rgba(props.theme.colors.textPrimary, 0.9)};
  padding: 24px 96px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${mq.notDesktop} {
    padding: 24px 16px;

    ${MascotMessage.Root} {
      max-width: 343px;
      flex-direction: column;
      align-items: flex-start;

      & > * + * {
        margin-left: 0;
        margin-top: 20px;
      }

      ${MascotMessage.Message} {
        border-radius: 1px 10px 10px 10px;
      }
    }
  }
`;

TrainingVideoModal.VideoOverlayBack = styled(Typography)`
  color: ${(props) => props.theme.colors.textLight};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

TrainingVideoModal.Exit = styled(Typography).attrs({ as: 'button' })`
  background: ${(props) => props.theme.colors.backgroundAccent};
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 15px;
  }
`;

TrainingVideoModal.VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 736px;
  flex: 1;
  position: relative;

  video {
    width: 100%;
    height: 100%;
  }

  @media ${mq.notDesktop} {
    video {
      background: ${(props) => props.theme.colors.textPrimary};
    }
  }
`;

TrainingVideoModal.Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;

  & > * + * {
    margin-left: 48px;
  }

  & > ${IconButton} {
    &:hover {
      &:nth-child(1),
      &:nth-child(3) {
        svg circle {
          fill: ${(props) => props.theme.colors.primary};
        }
      }

      &:nth-child(2) {
        svg circle {
          fill: ${(props) => props.theme.colors.accentDark};
        }
      }
    }
  }
`;

TrainingVideoModal.Root = styled(FullscreenModal)`
  ${FullscreenModal.Content} {
    display: flex;
    flex-direction: column;
  }

  @media ${mq.notDesktop} {
    .content {
      padding-top: 0;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }

    ${FullscreenModal.Content} {
      padding: 0;
      height: 100%;
    }

    ${TrainingVideoModal.VideoContainer} {
      width: 100%;
    }

    ${FullscreenModal.Close} {
      display: none;
    }
  }
`;

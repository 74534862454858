import List from 'components/List';
import moment from 'moment';
import { ComponentProps, useMemo } from 'react';
import CalendarListItem from 'components/CalendarListItem';
import { schema } from 'hooks/data';
import { toApiDate } from 'utils/date';

type CalendarListProps = ComponentProps<typeof List> & {
  month: string;
  schedulesByDate: Partial<
    Record<string, Array<schema['TrainingsScheduleItemDetail']>>
  >;
} & Pick<
    ComponentProps<typeof CalendarListItem>,
    'onDelete' | 'onMove' | 'onUpdateTraining' | 'onAddTraining'
  >;

export default function CalendarList({
  month,
  schedulesByDate,
  onDelete,
  onUpdateTraining,
  onAddTraining,
  onMove,
  ...restProps
}: CalendarListProps) {
  const monthMoment = useMemo(() => moment(month), [month]);
  const items = useMemo(() => {
    const start = monthMoment.clone().startOf('month');
    const end = monthMoment.clone().endOf('month');
    const ret: moment.Moment[] = [];
    while (start.isSameOrBefore(end)) {
      ret.push(start.clone());
      start.add(1, 'day');
    }
    return ret;
  }, [monthMoment]);
  return (
    <List {...restProps}>
      {items.map((item) => (
        <CalendarListItem
          schedules={schedulesByDate[toApiDate(item)]}
          key={item.toISOString()}
          item={item}
          onDelete={onDelete}
          onUpdateTraining={onUpdateTraining}
          onAddTraining={onAddTraining}
          onMove={onMove}
        />
      ))}
    </List>
  );
}

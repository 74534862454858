import Container from 'components/Container';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import { schema, useSkills, useStudent, useStudentProgress } from 'hooks/data';
import ErrorPage from 'pages/_error';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import StudentInfo from 'components/StudentInfo';
import useNow from 'hooks/useNow';
import { toApiDate } from 'utils/date';
import { useState } from 'react';
import ProgressPeriodToggle, {
  ProgressPeriod,
} from 'components/ProgressPeriodToggle';
import Typography from 'components/Typography';
import List from 'components/List';
import ProgressListItem from 'components/ProgressListItem';
import Button from 'components/Button';
import FeedbackItem from 'components/FeedbackItem';
import { mq } from 'styles/theme';
import useResponsive from 'hooks/useResponsive';
import MoveToGroupModal from 'components/MoveToGroupModal';
import useDataLayer from 'hooks/useDataLayer';

export default function StudentPage() {
  const match = useRouteMatch<{ id: string; url: string; path: string }>();
  useDataLayer({ title: match.path, alias: match.url });
  const id = Number(match.params.id);
  const student = useStudent(id);
  const now = useNow();
  const progress = useStudentProgress(id, toApiDate(now));
  const [isMove, setMove] = useState(false);

  if (student.error) {
    return <ErrorPage statusCode={student.error.status} />;
  }

  if (student.data && progress.data) {
    return (
      <StudentPage.Root>
        <StudentPage.InfoSection>
          <StudentInfo info={student.data} />
          <StudentProgress
            onMove={() => setMove(true)}
            progress={progress.data}
          />
        </StudentPage.InfoSection>
        <StudentPage.FeedbackSection>
          <Container>
            <StudentFeedback progress={progress.data} />
          </Container>
        </StudentPage.FeedbackSection>

        <MoveToGroupModal
          state={isMove ? [student.data.id] : undefined}
          onRequestClose={() => setMove(false)}
          excludedGroup={student.data.group?.id}
          onMoved={() => {
            student.revalidate();
            progress.revalidate();
          }}
        />
      </StudentPage.Root>
    );
  }

  return <Loader />;
}

StudentPage.InfoSection = styled(Container).attrs({ as: 'section' })`
  padding-top: 64px;
  padding-bottom: 88px;

  ${StudentInfo.Root} {
    margin-bottom: 60px;
  }
`;

StudentPage.FeedbackSection = styled.section`
  background: ${(props) => props.theme.colors.backgroundAccent};
  padding: 65px 0;
`;

StudentPage.Root = styled(Layout)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${Layout.Content} {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  ${StudentPage.FeedbackSection} {
    flex: 1;
  }

  @media ${mq.notDesktop} {
    ${StudentPage.FeedbackSection} {
      padding: 47px 0;
    }

    ${StudentPage.InfoSection} {
      padding-top: 20px;
      padding-bottom: 36px;
    }
  }
`;

type StudentProgressProps = {
  progress: schema['StudentProgressTotal'];
  onMove?: () => any;
};

function StudentProgress({ progress, onMove }: StudentProgressProps) {
  const [period, setPeriod] = useState(ProgressPeriod.WEEK);
  const progressData = progress[period];
  const skills = useSkills();

  return (
    <StudentProgress.Root>
      <StudentProgress.Head>
        <StudentProgress.Title variant="h3">
          Прогресс по навыкам
        </StudentProgress.Title>
        <ProgressPeriodToggle value={period} onChange={setPeriod} />
      </StudentProgress.Head>
      <List>
        {skills?.map((skill) => (
          <ProgressListItem
            key={skill.id}
            skill={skill}
            progress={progressData.progress.find(
              (item) => item.skill.id === skill.id
            )}
          />
        ))}
      </List>
      <StudentProgress.Move onClick={onMove}>
        Перенести в другую группу
      </StudentProgress.Move>
    </StudentProgress.Root>
  );
}

StudentProgress.Move = styled(Button).attrs({
  variant: 'contained',
  color: 'accent',
})``;

StudentProgress.Title = styled(Typography)``;

StudentProgress.Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

StudentProgress.Root = styled.div`
  ${List} {
    margin-bottom: 56px;
  }

  @media ${mq.mobile} {
    ${StudentProgress.Head} {
      flex-direction: column;
      align-items: stretch;

      & > * + * {
        margin-top: 24px;
      }
    }

    ${StudentProgress.Move} {
      width: 100%;
    }

    ${List} {
      margin-bottom: 48px;
    }
  }
`;

type StudentFeedbackProps = {
  progress: schema['StudentProgressTotal'];
};

function StudentFeedback({ progress }: StudentFeedbackProps) {
  const { isNotDesktop } = useResponsive();
  return (
    <StudentFeedback.Root>
      <StudentFeedback.Title variant={isNotDesktop ? 'h3' : 'h2'}>
        Обратная связь по тренировкам
      </StudentFeedback.Title>
      <List>
        {progress.month.feedbacks.map((feedback, i) => (
          <FeedbackItem key={i} feedback={feedback} />
        ))}
      </List>
    </StudentFeedback.Root>
  );
}

StudentFeedback.Root = styled.div`
  ${FeedbackItem.Card} {
    margin-bottom: 24px;
  }
`;

StudentFeedback.Title = styled(Typography)`
  margin-bottom: 41px;
`;

import Typography from 'components/Typography';
import { useRef } from 'react';
import styled from 'styled-components/macro';
import useOnClickOutside from 'use-onclickoutside';

type ActionsMenuProps = GetStyledProps<typeof ActionsMenu['Root']> & {
  actions: Array<{ title: string; onClick?: () => any }>;
  onClose: () => any;
};
export default function ActionsMenu({
  actions,
  onClose,
  ...restProps
}: ActionsMenuProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(rootRef, onClose);
  return (
    <ActionsMenu.Root ref={rootRef} {...restProps}>
      {actions.map((action, key) => (
        <ActionsMenu.Action key={key} onClick={action.onClick}>
          {action.title}
        </ActionsMenu.Action>
      ))}
    </ActionsMenu.Root>
  );
}

ActionsMenu.Action = styled(Typography).attrs({ as: 'button' })`
  color: ${(props) => props.theme.colors.textLight};
  padding: 12px 24px;
  white-space: nowrap;
  &:hover {
    background: ${(props) => props.theme.colors.textAccent};
  }
`;

ActionsMenu.Root = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.primary};
  padding: 9px 0;
  border-radius: 5px;
`;

import Checkbox from 'components/Checkbox';
import Chip from 'components/Chip';
import Container from 'components/Container';
import ExerciseListItem from 'components/ExerciseListItem';
import Filters from 'components/Filters';
import FormField from 'components/FormField';
import List from 'components/List';
import ListItem from 'components/ListItem';
import ListView from 'components/ListView';
import OrderBy from 'components/OrderBy';
import {
  schema,
  useAddToTraining,
  useExercises,
  useExercisesTypes,
  useLevels,
  useSkills,
} from 'hooks/data';
import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as PlusLargeIcon } from 'assets/icons/plus_large.svg';
import ListItemActions from 'components/ListItemActions';
import ListAction from 'components/ListAction';
import useResponsive from 'hooks/useResponsive';
import FabAdd from 'components/FabAdd';
import { Link, useHistory } from 'react-router-dom';
import * as qs from 'utils/qs';
import TrainingsModal from 'components/TrainingsModal';
import Button from 'components/Button';
import { useStore } from 'store';
import Typography from 'components/Typography';
import styled from 'styled-components/macro';
import useDebouncedValue from 'hooks/useDebouncedValue';

export default function ExercisesFragment() {
  const history = useHistory();
  const skills = useSkills();
  const levels = useLevels();
  const exercisesTypes = useExercisesTypes();
  const [filtersData, setFiltersData] = useState<{
    level?: Array<schema['Level']['id']>;
    skill?: Array<schema['Skill']['id']>;
    exercise_type?: Array<schema['ExerciseType']['id']>;
  }>();
  const filters = useMemo(
    () => (
      <Filters onClear={() => setFiltersData(undefined)}>
        <FormField label={<Typography>По навыкам</Typography>}>
          <Filters.Chips>
            {skills?.map((item) => {
              const isActive = filtersData?.skill?.includes(item.id);
              return (
                <Chip
                  active={isActive}
                  onClick={() =>
                    setFiltersData((prev) => ({
                      ...prev,
                      skill: isActive
                        ? prev?.skill?.filter((i) => i !== item.id)
                        : [...(prev?.skill || []), item.id],
                    }))
                  }
                  key={item.id}
                >
                  {item.name}
                </Chip>
              );
            })}
          </Filters.Chips>
        </FormField>
        <FormField label={<Typography>По уровню</Typography>}>
          <Filters.Chips>
            {levels?.map((item) => {
              const isActive = filtersData?.level?.includes(item.id);
              return (
                <Chip
                  active={isActive}
                  onClick={() =>
                    setFiltersData((prev) => ({
                      ...prev,
                      level: isActive
                        ? prev?.level?.filter((i) => i !== item.id)
                        : [...(prev?.level || []), item.id],
                    }))
                  }
                  key={item.id}
                >
                  {item.name}
                </Chip>
              );
            })}
          </Filters.Chips>
        </FormField>
        {/* <FormField label={<Typography>По типу</Typography>}>
          <Filters.Chips>
            {exercisesTypes.data?.results?.map((item) => {
              const isActive = filtersData?.exercise_type?.includes(item.id);
              return (
                <Chip
                  active={isActive}
                  onClick={() =>
                    setFiltersData((prev) => ({
                      ...prev,
                      exercise_type: isActive
                        ? prev?.exercise_type?.filter((i) => i !== item.id)
                        : [...(prev?.exercise_type || []), item.id],
                    }))
                  }
                  key={item.id}
                >
                  {item.name}
                </Chip>
              );
            })}
          </Filters.Chips>
        </FormField> */}
      </Filters>
    ),
    [
      exercisesTypes.data?.results,
      filtersData?.exercise_type,
      filtersData?.level,
      filtersData?.skill,
      levels,
      skills,
    ]
  );
  const [ordering, setOrdering] = useState('-created_at');
  const orderingOptions = useMemo(
    () => [
      // See generated/schema
      { label: 'Новые', value: '-created_at' },
      { label: 'Старые', value: 'created_at' },
      { label: 'Продолжительные', value: '-video_duration' },
      { label: 'Короткие', value: 'video_duration' },
    ],
    []
  );
  const [search, setSearch] = useState('');
  const params = useMemo(
    () => ({
      search,
      ordering,
      ...filtersData,
    }),
    [filtersData, ordering, search]
  );
  const debouncedParams = useDebouncedValue(params);
  const exercises = useExercises(debouncedParams);
  const addToTraining = useAddToTraining();
  const [selected, setSelected] = useState<Array<schema['Exercise']['id']>>([]);
  const [addExercise, setAddExercise] = useState<schema['Exercise']['id']>();
  const isAllSelected = useMemo(
    () =>
      new Set([
        ...selected,
        ...(exercises.data?.results?.map((item) => item.id) || []),
      ]).size === selected.length,
    [exercises.data?.results, selected]
  );
  const { isMobile } = useResponsive();
  const onCreateTraining = useCallback(() => {
    history.push({
      pathname: '/materials/trainings/create',
      search: qs.stringify({
        exercises: selected,
      }),
    });
  }, [history, selected]);
  const { notifications } = useStore();
  return (
    <ExercisesFragment.Root>
      <ListView
        searchProps={{
          placeholder: 'Поиск по названию',
          value: search,
          onChange: (event) => setSearch(event.target.value),
        }}
        filters={filters}
        hasFilters={!!filtersData}
      >
        <List>
          <ListItem
            control={
              <Checkbox
                checked={isAllSelected}
                onChange={() =>
                  setSelected(
                    isAllSelected
                      ? []
                      : exercises.data?.results?.map((item) => item.id) || []
                  )
                }
              />
            }
            action={
              <OrderBy
                options={orderingOptions}
                value={ordering}
                onChange={setOrdering}
              />
            }
          >
            {!isMobile && (
              <ListAction onClick={onCreateTraining} leading={<PlusIcon />}>
                Создать тренировку
              </ListAction>
            )}
          </ListItem>
          {isMobile && <FabAdd onClick={onCreateTraining} />}
          {exercises.data?.results?.map((item) => {
            const checked = selected.includes(item.id);
            return (
              <ExerciseListItem
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() =>
                      setSelected((prev) =>
                        checked
                          ? prev.filter((id) => id !== item.id)
                          : [...prev, item.id]
                      )
                    }
                  />
                }
                forwardedAs="label"
                button
                key={item.id}
                exercise={item}
                action={
                  <ListItemActions
                    actions={[
                      {
                        icon: <PlusLargeIcon />,
                        title: 'Добавить в тренировку',
                        onClick: () => setAddExercise(item.id),
                      },
                    ]}
                  />
                }
              />
            );
          })}
        </List>
      </ListView>

      <TrainingsModal
        isOpen={!!addExercise}
        onRequestClose={() => setAddExercise(undefined)}
        onSubmit={async (training) => {
          setAddExercise(undefined);
          await addToTraining(training.id, {
            exercise: addExercise!,
          }).then((data) => {
            notifications.add({
              content: (
                <>
                  Упражнение добавлено в {data.exercise_type_message_name}{' '}
                  тренировки:
                  <br />
                  <Button
                    as={Link}
                    to={`/materials/trainings/${data.training.id}`}
                    variant="text"
                    color="primary"
                  >
                    {data.training.name}
                  </Button>
                </>
              ),
            });
          });
        }}
      />
    </ExercisesFragment.Root>
  );
}

ExercisesFragment.Root = styled(Container)`
  padding-bottom: 64px;
`;

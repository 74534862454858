import Avatar from 'components/Avatar';
import IconButton from 'components/IconButton';
import { useMemo } from 'react';
import styled from 'styled-components/macro';
import placeholderSrc from './placeholder.svg';
import { ReactComponent as PlusIcon } from './plus.svg';

type AvatarUploadProps = Omit<
  GetStyledProps<typeof AvatarUpload['Root']>,
  'onChange'
> & {
  value?: string | File;
  onChange?: (file: File) => any;
};

export default function AvatarUpload({
  value,
  onChange,
  ...restProps
}: AvatarUploadProps) {
  const src = useMemo(() => {
    if (value instanceof Blob) {
      return URL.createObjectURL(value);
    }
    return value || placeholderSrc;
  }, [value]);
  return (
    <AvatarUpload.Root {...restProps}>
      <AvatarUpload.Avatar src={src} />
      <AvatarUpload.Button>
        <PlusIcon />
        <input
          hidden
          type="file"
          onChange={(event) => {
            const file = event.target.files?.[0];
            file && onChange?.(file);
          }}
        />
      </AvatarUpload.Button>
    </AvatarUpload.Root>
  );
}

AvatarUpload.Root = styled.div`
  width: 96px;
  height: 96px;
  position: relative;
`;
AvatarUpload.Avatar = styled(Avatar)`
  width: 100%;
  height: 100%;
`;
AvatarUpload.Button = styled(IconButton).attrs({ as: 'label' })`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
`;

import Typography from 'components/Typography';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components/macro';

const TabLink = styled(Typography).attrs({
  variant: 'medium',
  as: NavLink,
  activeClassName: 'active',
})<NavLinkProps>`
  padding: 13px 24px 9px;
  border-radius: 8px 8px 0 0;
  display: inline-block;

  &.active {
    background: ${(props) => props.theme.colors.backgroundPrimary};
  }

  &:not(.active):hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export default TabLink;

import ListItem from 'components/ListItem';
import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import styled from 'styled-components/macro';
import { ReactComponent as CounterIcon } from './counter.svg';

type ProgressListItemProps = {
  progress?: schema['ProgressSkill'];
  skill: schema['Skill'];
};

export default function ProgressListItem({
  skill,
  progress,
}: ProgressListItemProps) {
  return (
    <ProgressListItem.Root
      $percent={progress ? (progress.done_count / progress.all_count) * 100 : 0}
      action={
        <ProgressCounter
          done={progress ? progress.done_count : 0}
          all={progress ? progress.all_count : 0}
        />
      }
    >
      <Typography>{skill.name}</Typography>
    </ProgressListItem.Root>
  );
}

ProgressListItem.Name = styled(Typography)``;

ProgressListItem.Root = styled(ListItem)<{ $percent: number }>`
  position: relative;

  & > * {
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: ${(props) => props.theme.colors.backgroundAccent};
    width: ${(props) => `${props.$percent}%`};
  }
`;

ProgressListItem.Progress = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: ${(props) => props.theme.colors.backgroundAccent};
`;

type ProgressCounterProps = {
  done: number;
  all: number;
};

export function ProgressCounter({ done, all }: ProgressCounterProps) {
  return (
    <ProgressCounter.Root>
      <CounterIcon />
      <Typography>
        <span>{done}</span>
        <span> / {all}</span>
      </Typography>
    </ProgressCounter.Root>
  );
}

ProgressCounter.Root = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 8px;
  }

  span {
    &:nth-child(1) {
      color: ${(props) => props.theme.colors.textPrimaryDark};
    }

    &:nth-child(2) {
      color: ${(props) => props.theme.colors.gray3};
    }
  }
`;

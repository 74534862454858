import IconButton from 'components/IconButton';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as FiltersIcon } from './filters.svg';

const FiltersButton = styled(IconButton).attrs({ children: <FiltersIcon /> })<{
  active?: boolean;
}>`
  position: relative;

  ${(props) =>
    props.active &&
    css`
      &::after {
        content: '';
        width: 12px;
        height: 12px;
        display: block;
        position: absolute;
        border-radius: 50%;
        right: 1px;
        top: 5px;
        background: ${props.theme.colors.accent};
      }
    `}
`;

export default FiltersButton;

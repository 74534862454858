import styled from 'styled-components/macro';

const IconButton = styled.button.attrs(({ color, ...restProps }) => ({
  type: 'button',
  color: restProps.theme.colors.get(color),
  ...restProps,
}))`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};

  svg {
    color: inherit;
  }
`;

export default IconButton;

import Container from 'components/Container';
import Layout from 'components/Layout';
import { ComponentProps, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

type TabsLayoutProps = ComponentProps<typeof TabsLayout['Root']> & {
  header?: ReactNode;
  icon?: ReactNode;
  tabs?: ReactNode;
};

export default function TabsLayout({
  header,
  icon,
  tabs,
  children,
  ...restProps
}: TabsLayoutProps) {
  return (
    <TabsLayout.Root background="accent" {...restProps}>
      <TabsLayout.Header>
        <TabsLayout.HeaderMain>
          {header && (
            <TabsLayout.HeaderContent>{header}</TabsLayout.HeaderContent>
          )}
          {tabs && <TabsLayout.Tabs>{tabs}</TabsLayout.Tabs>}
        </TabsLayout.HeaderMain>
        {icon && <TabsLayout.HeaderIcon>{icon}</TabsLayout.HeaderIcon>}
      </TabsLayout.Header>
      <TabsLayout.Content>{children}</TabsLayout.Content>
    </TabsLayout.Root>
  );
}

TabsLayout.Content = styled.div`
  flex: 1;
  background: ${(props) => props.theme.colors.backgroundPrimary};
  padding-top: 60px;
  padding-bottom: 32px;

  @media ${mq.notDesktop} {
    padding-top: 40px;
  }
`;
TabsLayout.Header = styled(Container)`
  display: flex;
  justify-content: space-between;
`;
TabsLayout.HeaderMain = styled.div`
  display: flex;
  flex-direction: column;
`;
TabsLayout.Tabs = styled.div``;
TabsLayout.HeaderContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
TabsLayout.HeaderIcon = styled.div`
  svg {
    height: 100%;
    max-width: 100%;
  }
`;
TabsLayout.Root = styled(Layout)`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${Layout.Content} {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  @media ${mq.notDesktop} {
    ${TabsLayout.HeaderIcon} {
      display: none;
    }

    ${TabsLayout.Header} {
      padding: 0;
    }

    ${TabsLayout.HeaderContent} {
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 40px;
    }

    ${TabsLayout.HeaderMain} {
      width: 100%;
    }

    ${TabsLayout.Tabs} {
      display: flex;
      & > * {
        flex: 1;
        text-align: center;
      }
    }
  }
`;

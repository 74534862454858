import 'moment-duration-format';
import 'moment/locale/ru';

import { SWRConfig } from 'swr';
import { ThemeProvider } from 'styled-components/macro';
import { swrConfig } from 'hooks/data';
import Notifications from 'components/Notifications';
import TrainingVideoModal from 'components/TrainingVideoModal';
import { StoreProvider } from 'store';
import GlobalStyle from './styles/GlobalStyle';

import Router from './router';
import theme from './styles/theme';

export default function App() {
  return (
    <SWRConfig value={swrConfig}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <StoreProvider>
          <Router>
            <Notifications />
            <TrainingVideoModal />
          </Router>
        </StoreProvider>
      </ThemeProvider>
    </SWRConfig>
  );
}

import Container from 'components/Container';
import EditTrainerCard from 'components/EditTrainerCard';
import Layout from 'components/Layout';
import Typography from 'components/Typography';
import UpdatePasswordCard from 'components/UpdatePasswordCard';
import {
  ApiError,
  useUpdateTrainer,
  useUploadPhoto,
  useUser,
} from 'hooks/data';
import { useStore } from 'store';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import useDataLayer from 'hooks/useDataLayer';
import { useRouteMatch } from 'react-router-dom';

export default function TrainerProfileEditPage() {
  const match = useRouteMatch<{ url: string }>();
  const title = 'Редактирование профиля';
  useDataLayer({ title, alias: match.url });
  const user = useUser();
  const uploadPhoto = useUploadPhoto();
  const updateTrainer = useUpdateTrainer();
  const { notifications } = useStore();
  return (
    <Layout background="accent">
      <TrainerProfileEditPage.Content>
        <TrainerProfileEditPage.Title>{title}</TrainerProfileEditPage.Title>
        <TrainerProfileEditPage.Profile
          onSubmit={async ({ photo, ...values }, formik) => {
            if (photo instanceof Blob) {
              await uploadPhoto(photo);
            }

            await updateTrainer(values)
              .then(() => {
                user.revalidate();
                notifications.add({
                  content: 'Профиль успешно обновлен',
                });
              })
              .catch((error: ApiError) => formik.setErrors(error.info));
          }}
          initialValues={user.data as any}
        />
        <UpdatePasswordCard />
      </TrainerProfileEditPage.Content>
    </Layout>
  );
}

TrainerProfileEditPage.Title = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 110px;
`;

TrainerProfileEditPage.Content = styled(Container)`
  padding-top: 90px;
  padding-bottom: 48px;

  @media ${mq.notDesktop} {
    padding-top: 40px;

    ${TrainerProfileEditPage.Title} {
      margin-bottom: 80px;
    }
  }
`;

TrainerProfileEditPage.Profile = styled(EditTrainerCard)`
  margin-bottom: 48px;
`;

import Button from 'components/Button';
import styled from 'styled-components/macro';
import { rem } from 'styles/mixins';

const ListAction = styled(Button).attrs({
  variant: 'outlined',
  color: 'accent',
})`
  padding: 0 12px;
  font-size: ${rem(14)};
  line-height: ${rem(18)};
  min-height: 40px;
`;

export default ListAction;

import StudentScheduleCard from 'components/StudentScheduleCard';
import { useStudentWeeklySchedule } from 'hooks/data';
import useNow from 'hooks/useNow';
import styled from 'styled-components/macro';
import { toApiDate } from 'utils/date';

export default function TrainingsWeekFragment() {
  const now = useNow();
  const schedules = useStudentWeeklySchedule(toApiDate(now));
  return (
    <TrainingsWeekFragment.Root>
      {schedules.data?.results
        ?.filter((item) => !item.done)
        .map((schedule) => (
          <StudentScheduleCard schedule={schedule} key={schedule.id} />
        ))}
    </TrainingsWeekFragment.Root>
  );
}

TrainingsWeekFragment.Root = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 47px;
  }
`;

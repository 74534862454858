import Typography from 'components/Typography';
import { useMemo } from 'react';
import Button from 'components/Button';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { schema } from 'hooks/data';
import { fromApiDate, humanize } from 'utils/date';
import styled from 'styled-components/macro';
import Card from 'components/Card';
import { mq } from 'styles/theme';
import Details from 'components/Details';
import { formatTrainingDuration } from 'utils/format';

import { useStore } from 'store';
import useNow from 'hooks/useNow';
import SkillImage from 'components/SkillImage';
import useResponsive from 'hooks/useResponsive';

type StudentScheduleCardProps = {
  schedule: schema['TrainingsScheduleStudent'];
};

export default function StudentScheduleCard({
  schedule,
}: StudentScheduleCardProps) {
  const now = useNow();
  const apiDate = useMemo(() => fromApiDate(schedule.training_date), [
    schedule.training_date,
  ]);
  const date = humanize(apiDate);
  return apiDate.isAfter(now, 'd') ? (
    <DenseVariant date={date} schedule={schedule} />
  ) : (
    <FullVariant date={date} schedule={schedule} />
  );
}

type FullVariantProps = StudentScheduleCardProps & {
  date: string;
};

function FullVariant({ schedule, date }: FullVariantProps) {
  const { trainingVideo } = useStore();
  return (
    <FullVariant.Root>
      <FullVariant.Main>
        <FullVariant.Name>{schedule.training.name}</FullVariant.Name>
        <FullVariant.Details>
          <Typography>{date}</Typography>
          <Typography>
            {formatTrainingDuration(schedule.training.duration)}
          </Typography>
          <Typography>Уровень {schedule.training.level.name}</Typography>
        </FullVariant.Details>
        {schedule.training.equipment && (
          <Typography>Тебе нужны: {schedule.training.equipment}</Typography>
        )}
        <FullVariant.Start
          variant="contained"
          color="accent"
          leading={<PlayIcon />}
          onClick={() => {
            trainingVideo.setState({
              training: schedule.training.id,
              schedule: schedule.id,
            });
          }}
        >
          Начать тренировку
        </FullVariant.Start>
      </FullVariant.Main>
      <FullVariant.Image
        background
        skill={schedule.training.skill}
        level={schedule.training.level}
      />
    </FullVariant.Root>
  );
}

FullVariant.Start = styled(Button)`
  margin-top: 46px;
`;

FullVariant.Image = styled(SkillImage)`
  width: 100%;
  height: 100%;
`;

FullVariant.Details = styled(Details)`
  margin-bottom: 8px;
`;

FullVariant.Name = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 26px;
`;

FullVariant.Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

FullVariant.Root = styled(Card)`
  padding: 64px 40px 40px 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @media ${mq.notDesktop} {
    grid-template-columns: 1fr;
    padding: 24px;

    ${FullVariant.Main} {
      align-items: stretch;
    }

    ${FullVariant.Image} {
      max-height: 200px;
      grid-row: 1;
    }
  }
`;

type DenseVariantProps = StudentScheduleCardProps & {
  date: string;
};
function DenseVariant({ schedule, date }: DenseVariantProps) {
  const { isMobile } = useResponsive();
  const [day, ...restDate] = date.split(' ');
  const nameNode = (
    <Typography variant="h3">{schedule.training.name}</Typography>
  );
  const detailsNode = (
    <Details>
      <div>{formatTrainingDuration(schedule.training.duration)}</div>
      <div>Уровень {schedule.training.level.name}</div>
    </Details>
  );
  return isMobile ? (
    <DenseVariant.Root>
      {nameNode}
      <DenseVariant.Date>{date}</DenseVariant.Date>
      {detailsNode}
    </DenseVariant.Root>
  ) : (
    <DenseVariant.Root>
      <DenseVariant.Date>
        <span>{day}</span>
        {restDate.length > 0 && <span>{restDate.join(' ')}</span>}
      </DenseVariant.Date>
      <DenseVariant.Image
        background
        skill={schedule.training.skill}
        level={schedule.training.level}
      />
      <DenseVariant.Main>
        {nameNode}
        {detailsNode}
      </DenseVariant.Main>
    </DenseVariant.Root>
  );
}

DenseVariant.Date = styled(Typography)`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: 110px;

  & > * + * {
    margin-top: 18px;
  }
`;

DenseVariant.Image = styled(SkillImage)`
  width: 145px;
  max-height: 100%;
`;

DenseVariant.Main = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 16px;
  }
`;

DenseVariant.Root = styled(Card)`
  display: flex;
  align-items: center;
  padding: 0 24px;
  opacity: 0.5;

  & > * + * {
    margin-left: 24px;
  }

  @media ${mq.mobile} {
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;

    ${DenseVariant.Date} {
      display: block;
      min-width: auto;

      & > * + * {
        margin-top: 0;
      }
    }

    & > * + * {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;

import 'focus-visible';
import { createGlobalStyle, css } from 'styled-components/macro';
import normalize from 'styled-normalize';
import { modalGlobalCss } from 'components/Modal';
import { tooltipGlobalCss } from 'components/Tooltip';
import { ibm, resetButton } from './mixins';
import { mq } from './theme';

export default createGlobalStyle`
    ${normalize};

    html {
        font-size: 100%;
        box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    [data-js-focus-visible] :focus:not([data-focus-visible-added]) {
        outline: none;
    }

    body {
        ${ibm(400)}
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden;
        ${(props) => css`
          color: ${props.theme.colors.textPrimary};
          background: ${props.theme.colors.backgroundPrimary};
        `}
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    button {
        ${resetButton()}
    }

    // Scrollbar
    ::-webkit-scrollbar {
        width: 8px;
        height: 3px;

        @media ${mq.notDesktop} {
            width: 3px;
        }
    }
    ::-webkit-scrollbar-track {
        background: ${(props) =>
          props.theme.colors.backgroundAccentAlternative};
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.primary};
        border-radius: 10px;
    }

    // fix iOS (Safari) issue
    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
        -webkit-appearance: none;
    }

    [hidden] {
        visibility: hidden;
    }

    ${modalGlobalCss}
    ${tooltipGlobalCss}
`;

import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Card from 'components/Card';
import List from 'components/List';
import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as EmailIcon } from './email.svg';
import { ReactComponent as PhoneIcon } from './phone.svg';

type TrianerProfileInfoCardProps = GetStyledProps<
  typeof TrainerProfileInfoCard['Root']
> & {
  info: schema['UserInfo'];
};

export default function TrainerProfileInfoCard({
  info,
  ...restProps
}: TrianerProfileInfoCardProps) {
  const { isNotDesktop } = useResponsive();
  const avatar = <TrainerProfileInfoCard.Avatar src={info.photo} />;
  return (
    <TrainerProfileInfoCard.Root {...restProps}>
      {!isNotDesktop && avatar}
      <TrainerProfileInfoCard.Main>
        <TrainerProfileInfoCard.Name variant={isNotDesktop ? 'h3' : 'h1'}>
          {isNotDesktop && avatar}
          {info.first_name} {info.last_name}
        </TrainerProfileInfoCard.Name>
        {info.company && (
          <TrainerProfileInfoCard.Company>
            Тренер в {info.company}
          </TrainerProfileInfoCard.Company>
        )}
        <TrainerProfileInfoCard.Contacts>
          {info.email && (
            <TrainerProfileInfoCard.ContactsItem>
              <EmailIcon />
              {info.email}
            </TrainerProfileInfoCard.ContactsItem>
          )}
          {info.phone && (
            <TrainerProfileInfoCard.ContactsItem>
              <PhoneIcon />
              {info.phone}
            </TrainerProfileInfoCard.ContactsItem>
          )}
        </TrainerProfileInfoCard.Contacts>
      </TrainerProfileInfoCard.Main>
      <Button
        as={Link}
        to="/profile/edit"
        color="accent"
        variant="outlined"
        leading={<EditIcon />}
      >
        Изменить
      </Button>
    </TrainerProfileInfoCard.Root>
  );
}

TrainerProfileInfoCard.Avatar = styled(Avatar)`
  width: 96px;
  height: 96px;
  margin-right: 56px;
`;

TrainerProfileInfoCard.Main = styled.div`
  flex: 1;
`;

TrainerProfileInfoCard.Name = styled(Typography)`
  margin-bottom: 15px;
`;

TrainerProfileInfoCard.Company = styled(Typography)`
  margin-bottom: 26px;
`;
TrainerProfileInfoCard.Contacts = styled(List)``;
TrainerProfileInfoCard.ContactsItem = styled(Typography).attrs({ as: 'li' })`
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-bottom: 22px;

  svg {
    margin-right: 12px;
  }
`;

TrainerProfileInfoCard.Root = styled(Card)`
  padding: 32px 64px 32px 32px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media ${mq.notDesktop} {
    padding: 24px;
    flex-direction: column;
    align-items: stretch;

    ${TrainerProfileInfoCard.Name} {
      display: flex;
      align-items: center;
    }

    ${TrainerProfileInfoCard.Avatar} {
      flex-shrink: 0;
      margin-right: 17px;
      width: 94px;
      height: 94px;
    }
  }
`;

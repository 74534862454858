import { rgba } from 'polished';
import { ComponentProps, useMemo } from 'react';
import ReactSelect from 'react-select';
import styled, { css } from 'styled-components/macro';
import { ibm, rem } from 'styles/mixins';
import { ReactComponent as ArrowIcon } from './arrow.svg';

type SelectProps = Omit<ComponentProps<typeof ReactSelect>, 'onChange'> & {
  options?: any[];
  value?: any;
  error?: boolean;
  onChange?: (value: any) => any;
};

const components: ComponentProps<typeof ReactSelect>['components'] = {
  IndicatorSeparator: null,
  DropdownIndicator: () => <ArrowIcon />,
};

export default function Select({ value, onChange, ...restProps }: SelectProps) {
  const computedValue = useMemo(
    () => restProps.options?.find((option: any) => option.value === value),
    [restProps.options, value]
  );
  return (
    <Select.Root
      components={components}
      isSearchable={false}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      onChange={(option: any) => onChange?.(option && option.value)}
      value={computedValue}
      {...restProps}
    />
  );
}

Select.Root = styled(ReactSelect).attrs({
  classNamePrefix: 'react-select',
})<{ error?: boolean }>`
  ${(props) =>
    props.error &&
    css`
      .react-select__control {
        border-color: ${props.theme.colors.error};
      }
    `}

  .react-select {
    &__control {
      display: flex;
      padding: 0 24px;
      height: 56px;
      border: 1px solid ${(props) => props.theme.colors.primary};
      border-radius: 10px;
      ${ibm(400)};
      font-size: ${rem(18)};
      line-height: ${rem(26)};
      color: ${(props) => props.theme.colors.textPrimary};
      box-shadow: none;

      &:hover {
        border-color: ${(props) => props.theme.colors.primary};
      }
    }

    &__indicators {
      padding-left: 4px;
    }

    &__value-container {
      padding: 0;
      line-height: ${rem(26)};
      white-space: nowrap;
    }

    &__single-value {
      margin: 0;
      color: inherit;
    }

    &__menu {
      box-shadow: none;
      border-radius: 0;
      min-width: 100%;
      width: auto;
      z-index: 10;
    }

    &__menu-list {
      padding: 0;
      border-radius: 10px;
    }

    &__option {
      background: ${(props) => props.theme.colors.backgroundAccent};
      ${ibm(400)}
      font-size: ${rem(18)};
      line-height: ${rem(32)};
      padding: 8px 24px;
      white-space: nowrap;
      color: ${(props) => props.theme.colors.textPrimary};

      &:hover {
        background: ${(props) => rgba(props.theme.colors.textAccent, 0.3)};
      }
    }
  }
`;

import Container from 'components/Container';
import Layout from 'components/Layout';
import useDataLayer from 'hooks/useDataLayer';
import { useRouteMatch } from 'react-router-dom';

import TrainerProfileInfoCard from 'components/TrainerProfileInfoCard';

import UpdatePasswordCard from 'components/UpdatePasswordCard';
import { useUser } from 'hooks/data';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

export default function TrainerProfilePage() {
  const match = useRouteMatch<{ url: string }>();
  useDataLayer({ title: 'Профиль тренера', alias: match.url });
  const user = useUser();
  return (
    <Layout background="accent">
      <TrainerProfilePage.Content>
        <TrainerProfileInfoCard info={user.data!} />
        <UpdatePasswordCard />
      </TrainerProfilePage.Content>
    </Layout>
  );
}

TrainerProfilePage.Content = styled(Container)`
  padding-top: 120px;
  padding-bottom: 64px;
  display: grid;
  gap: 52px;

  @media ${mq.notDesktop} {
    padding-top: 40px;
  }
`;

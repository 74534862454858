import IconButton from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import { ReactNode, useMemo, useState } from 'react';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import ActionsModal from 'components/ActionsModal';
import useResponsive from 'hooks/useResponsive';
import ActionsMenu from 'components/ActionsMenu';
import styled, { css } from 'styled-components/macro';
import { mq } from 'styles/theme';

type ListItemActionsProps = GetStyledProps<typeof ListItemActions['Root']> & {
  menu?: boolean;
  actions: Array<{
    title: string;
    icon: ReactNode;
    disabled?: boolean;
    onClick?: () => any;
  }>;
};

export default function ListItemActions({
  actions,
  menu,
  ...restProps
}: ListItemActionsProps) {
  const [isOpen, setOpen] = useState(false);
  const menuActions = useMemo(() => {
    return actions.map((action) => ({
      ...action,
      onClick: () => {
        action.onClick?.();
        setOpen(false);
      },
    }));
  }, [actions]);
  const { isNotDesktop } = useResponsive();
  return (
    <ListItemActions.Root
      onClick={(event: any) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      {...restProps}
    >
      {menu ? (
        <ListItemActions.More>
          <ListItemActions.MoreButton onClick={() => setOpen(true)}>
            <MoreIcon />
          </ListItemActions.MoreButton>
          {isNotDesktop ? (
            <ActionsModal
              isOpen={isOpen}
              onRequestClose={() => setOpen(false)}
              actions={menuActions}
            />
          ) : (
            isOpen && (
              <ListItemActions.Menu
                onClose={() => setOpen(false)}
                actions={menuActions}
              />
            )
          )}
        </ListItemActions.More>
      ) : (
        actions.map((action, i) => (
          <ListItemActions.ActionWrapper disabled={action.disabled}>
            <Tooltip key={i} title={action.title}>
              <ListItemActions.Action onClick={action.onClick}>
                {action.icon}
              </ListItemActions.Action>
            </Tooltip>
          </ListItemActions.ActionWrapper>
        ))
      )}
    </ListItemActions.Root>
  );
}

ListItemActions.More = styled.div`
  position: relative;
`;

ListItemActions.MoreButton = styled(IconButton)`
  padding: 8px;
`;

ListItemActions.Menu = styled(ActionsMenu)`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
`;

ListItemActions.ActionWrapper = styled.div<{ disabled?: boolean }>`
  ${(props) =>
    props.disabled &&
    css`
      display: none;
    `}
`;

ListItemActions.Action = styled(IconButton)`
  opacity: 0;
`;

ListItemActions.Root = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};

  & > * + * {
    margin-left: 24px;
  }

  *:hover > & ${ListItemActions.Action} {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  @media ${mq.notDesktop} {
    ${ListItemActions.Action} {
      opacity: 1;
    }
  }
`;

import CardModal from 'components/CardModal';
import FormField from 'components/FormField';
import Label from 'components/Label';
import List from 'components/List';
import Select from 'components/Select';
import { ComponentProps, useState } from 'react';
import TrainingListItem from 'components/TrainingListItem';
import styled from 'styled-components/macro';
import { schema, useLevels, useSkills, useTrainings } from 'hooks/data';
import { mq } from 'styles/theme';
import useResponsive from 'hooks/useResponsive';
import TrainingCard from 'components/TrainingCard';
import withNullOption from 'utils/withNullOption';

type TrainingsModalProps = ComponentProps<typeof CardModal> & {
  onSubmit?: (training: schema['TrainingList']) => any;
};

export default function TrainingsModal({
  onSubmit,
  ...restProps
}: TrainingsModalProps) {
  const { isNotDesktop } = useResponsive();
  const skills = useSkills();
  const levels = useLevels();
  const [filters, setFilters] = useState({
    skill: null,
    level: null,
  });
  const trainings = useTrainings(filters);
  return (
    <TrainingsModal.Root title="Выберите тренировку" {...restProps}>
      <TrainingsModal.Filters>
        <FormField label={<Label>Навык</Label>}>
          <Select
            options={withNullOption(
              'Все',
              skills?.map((skill) => ({
                label: skill.name,
                value: skill.id,
              }))
            )}
            placeholder="Выберите навык"
            value={filters.skill}
            onChange={(skill: any) =>
              setFilters({
                ...filters,
                skill,
              })
            }
          />
        </FormField>
        <FormField label={<Label>Уровень</Label>}>
          <Select
            options={withNullOption(
              'Все',
              levels?.map((level) => ({
                label: level.name,
                value: level.id,
              }))
            )}
            placeholder="Выберите уровень"
            value={filters.level}
            onChange={(level: any) =>
              setFilters({
                ...filters,
                level,
              })
            }
          />
        </FormField>
      </TrainingsModal.Filters>
      <TrainingsModal.List>
        {trainings.data?.results?.map((training) =>
          isNotDesktop ? (
            <TrainingCard
              training={training}
              key={training.id}
              onSelect={() => onSubmit?.(training)}
            />
          ) : (
            <TrainingListItem
              onClick={() => onSubmit?.(training)}
              button
              forwardedAs="button"
              key={training.id}
              training={training}
            />
          )
        )}
      </TrainingsModal.List>
    </TrainingsModal.Root>
  );
}

TrainingsModal.Filters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 40px;
`;

TrainingsModal.List = styled(List)`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-right: 24px;

  & > * + * {
    margin-top: 24px;
  }

  & > * {
    flex-shrink: 0;
  }
`;

TrainingsModal.Root = styled(CardModal)`
  .content {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }

  @media ${mq.notDesktop} {
    .content {
      background: ${(props) => props.theme.colors.backgroundAccent};
      top: 0;
      border-radius: 0;
    }

    ${TrainingsModal.List} {
      padding-right: 8px;
    }
  }
`;

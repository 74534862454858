import ConfirmationModal from 'components/ConfirmationModal';
import { ComponentProps } from 'react';
import { useHistory } from 'react-router';

type LogoutModalProps = ComponentProps<typeof ConfirmationModal>;

export default function LogoutModal(props: LogoutModalProps) {
  const history = useHistory();
  return (
    <ConfirmationModal
      onConfirm={() => history.push('/logout')}
      text="Вы точно хотите выйти из учетной записи?"
      confirmText="Да, я хочу выйти"
      cancelText="Нет"
      {...props}
    />
  );
}

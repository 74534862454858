import List from 'components/List';
import ListItem from 'components/ListItem';
import Radio from 'components/Radio';
import Typography from 'components/Typography';
import { useRef, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import useOnClickOutside from 'use-onclickoutside';
import { ReactComponent as OrderIcon } from './order.svg';

type OrderByProps = Omit<GetStyledProps<typeof OrderBy['Root']>, 'onChange'> & {
  onChange?: (value: string) => any;
  value: string;
  options: Array<{ label: string; value: string }>;
};

export default function OrderBy({
  onChange,
  value,
  options,
  ...restProps
}: OrderByProps) {
  const listRef = useRef<HTMLUListElement>(null);
  const [isOpen, setOpen] = useState(false);
  const selected = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  );
  useOnClickOutside(listRef, () => setOpen(false));
  return (
    <OrderBy.Root {...restProps}>
      <OrderBy.Trigger onClick={() => setOpen(true)}>
        Показать сначала: {selected?.label.toLowerCase()}
      </OrderBy.Trigger>
      <OrderIcon />
      {isOpen && (
        <OrderBy.List ref={listRef}>
          {options.map((option) => (
            <ListItem
              key={option.value}
              as="label"
              control={
                <Radio
                  checked={value === option.value}
                  onChange={() => {
                    onChange?.(option.value);
                    setOpen(false);
                  }}
                />
              }
            >
              {option.label}
            </ListItem>
          ))}
        </OrderBy.List>
      )}
    </OrderBy.Root>
  );
}

OrderBy.Trigger = styled(Typography).attrs({ as: 'button', type: 'button' })`
  opacity: 0.6;
  border-bottom: 1px dashed ${(props) => props.theme.colors.textPrimaryDark};
`;
OrderBy.List = styled(List)`
  position: absolute;
  bottom: -10.5px;
  right: 0;
  background: ${(props) => props.theme.colors.backgroundAccent};
  border-radius: 10px;
  transform: translateY(100%);
  border: 1px solid ${(props) => props.theme.colors.backgroundAccentAlternative};
  z-index: 1;
`;
OrderBy.Root = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  & > * + * {
    margin-left: 6px;
  }
`;

import Button from 'components/Button';
import CardModal from 'components/CardModal';
import Checkbox from 'components/Checkbox';
import ExerciseListItem from 'components/ExerciseListItem';
import FormField from 'components/FormField';
import Label from 'components/Label';
import List from 'components/List';
import Select from 'components/Select';
import { useState } from 'react';
import plural from 'plural-ru';
import {
  schema,
  useExercises,
  useExercisesTypes,
  useSkills,
  useLevels,
} from 'hooks/data';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import withNullOption from 'utils/withNullOption';

type ExercisesModalProps = GetStyledProps<typeof ExercisesModal['Root']> & {
  onSubmit?: (exercises: Array<schema['Exercise']>) => any;
};

export default function ExercisesModal({
  onSubmit,
  ...restProps
}: ExercisesModalProps) {
  const exercisesTypes = useExercisesTypes();
  const levels = useLevels();
  const skills = useSkills();
  const [filters, setFilters] = useState({
    skill: null,
    level: null,
    exercise_type: null,
  });
  const exercises = useExercises(filters);
  const [selected, setSelected] = useState<Array<schema['Exercise']>>([]);

  return (
    <ExercisesModal.Root title="Выберите упражнение" {...restProps}>
      <ExercisesModal.Filters>
        <FormField label={<Label>Навык</Label>}>
          <Select
            options={withNullOption(
              'Все',
              skills?.map((skill) => ({
                label: skill.name,
                value: skill.id,
              }))
            )}
            placeholder="Выберите навык"
            value={filters.skill}
            onChange={(skill) =>
              setFilters({
                ...filters,
                skill,
              })
            }
          />
        </FormField>
        <FormField label={<Label>Уровень</Label>}>
          <Select
            options={withNullOption(
              'Все',
              levels?.map((level) => ({
                label: level.name,
                value: level.id,
              }))
            )}
            placeholder="Выберите уровень"
            value={filters.level}
            onChange={(level) =>
              setFilters({
                ...filters,
                level,
              })
            }
          />
        </FormField>
        {/* <FormField label={<Label>Тип</Label>}>
          <Select
            options={withNullOption(
              'Все',
              exercisesTypes.data?.results?.map((type) => ({
                label: type.name,
                value: type.id,
              }))
            )}
            placeholder="Выберите тип"
            value={filters.exercise_type}
            onChange={(exercise_type) =>
              setFilters({
                ...filters,
                exercise_type,
              })
            }
          />
        </FormField> */}
      </ExercisesModal.Filters>
      <List>
        {exercises.data?.results?.map((exercise) => {
          const checked = !!selected.find((item) => item.id === exercise.id);
          return (
            <ExerciseListItem
              button
              forwardedAs="label"
              control={
                <Checkbox
                  checked={checked}
                  onChange={() =>
                    setSelected((prev) =>
                      checked
                        ? prev.filter((item) => item.id !== exercise.id)
                        : [...prev, exercise]
                    )
                  }
                />
              }
              key={exercise.id}
              exercise={exercise}
            />
          );
        })}
      </List>
      {selected.length > 0 && (
        <ExercisesModal.Submit
          onClick={() => {
            onSubmit?.(selected);
            setSelected([]);
          }}
          color="accent"
          variant="contained"
        >
          Добавить {selected.length}{' '}
          {plural(selected.length, 'упражнение', 'упражнения', 'упражнений')}
        </ExercisesModal.Submit>
      )}
    </ExercisesModal.Root>
  );
}

ExercisesModal.Submit = styled(Button)`
  align-self: center;
`;

ExercisesModal.Filters = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 40px;
`;

ExercisesModal.Root = styled(CardModal)`
  .content {
    min-width: 752px;
    display: flex;
    flex-direction: column;
    overflow: visible;
  }

  ${List} {
    margin-bottom: 40px;
    flex: 1;
    overflow-y: auto;
  }

  @media ${mq.notDesktop} {
    .content {
      min-width: auto;
    }

    ${ExercisesModal.Filters} {
      grid-template-columns: 1fr;
    }
  }
`;

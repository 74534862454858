import Button from 'components/Button';
import CalendarPicker from 'components/CalendarPicker';
import CardModal from 'components/CardModal';
import MonthPicker from 'components/MonthPicker';
import { schema, useGroupSchedules } from 'hooks/data';
import useNow from 'hooks/useNow';
import capitalize from 'lodash/capitalize';
import { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { rem } from 'styles/mixins';
import { getMonthOptions, toMonthOption } from 'utils/date';
import moment from 'moment';
import { mq } from 'styles/theme';

type CalendarPickerModalProps = GetStyledProps<
  typeof CalendarPickerModal['Root']
> & {
  group?: schema['TrainerGroupList']['id'];
  onSubmit?: (value: string) => any;
};

export default function CalendarPickerModal({
  onSubmit,
  group,
  ...restProps
}: CalendarPickerModalProps) {
  const now = useNow();
  const [month, setMonth] = useState(toMonthOption(now).toISOString());
  const [value, setValue] = useState<string>();
  const monthOptions = useMemo(
    () =>
      getMonthOptions(now).map((option) => ({
        value: option.toISOString(),
        label: capitalize(option.format('MMMM YYYY')),
      })),
    [now]
  );
  const monthMoment = useMemo(() => moment(month), [month]);
  const params = useMemo(
    () => ({
      year: monthMoment.format('YYYY'),
      month: monthMoment.format('M'),
    }),
    [monthMoment]
  );
  const schedules = useGroupSchedules(group, params);
  return (
    <CalendarPickerModal.Root title="Выберите свободную дату" {...restProps}>
      <MonthPicker value={month} onChange={setMonth} options={monthOptions} />
      <CalendarPicker
        schedules={schedules.data}
        value={value}
        onChange={setValue}
        month={month}
      />
      <CalendarPickerModal.Submit
        disabled={!value}
        onClick={() => {
          onSubmit?.(value!);
          setValue(undefined);
        }}
      >
        Готово
      </CalendarPickerModal.Submit>
    </CalendarPickerModal.Root>
  );
}

CalendarPickerModal.Submit = styled(Button).attrs({
  variant: 'contained',
  color: 'accent',
})`
  align-self: center;
  min-width: 296px;
`;

CalendarPickerModal.Root = styled(CardModal)`
  .content {
    display: flex;
    flex-direction: column;
    padding: 40px 60px;
  }

  ${MonthPicker.Root} {
    align-self: flex-start;
    margin: 19px 0;

    .react-select__value-container {
      font-size: ${rem(18)};
      line-height: ${rem(23)};
    }
  }

  ${CalendarPicker.Root} {
    margin-bottom: 30px;
  }

  @media ${mq.notDesktop} {
    .content {
      padding: 16px;
    }

    ${CalendarPickerModal.Submit} {
      width: 100%;
    }
  }
`;

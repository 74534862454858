import Typography from 'components/Typography';
import styled from 'styled-components/macro';
import { ibm, rem } from 'styles/mixins';

type StickyTooltipProps = GetStyledProps<typeof StickyTooltip['Root']> & {
  title: string;
};

export default function StickyTooltip({ title }: StickyTooltipProps) {
  return (
    <StickyTooltip.Root>
      <StickyTooltip.Content>
        <StickyTooltip.Arrow />
        <Typography
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        ></Typography>
      </StickyTooltip.Content>
    </StickyTooltip.Root>
  );
}

StickyTooltip.Arrow = styled.div`
  position: absolute;
  border-top: 7px solid rgba(226, 240, 246, 0.9);
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  bottom: -7px;
  left: 50%;
`;

StickyTooltip.Content = styled.div`
  background-color: rgba(226, 240, 246, 0.9);
  padding: 6px 16px;
  border-radius: 5px;
  position: relative;

  & > ${Typography} {
    color: ${(props) => props.theme.colors.textPrimary};
    ${ibm(500)}
    font-size: ${rem(14)};
    line-height: ${rem(18)};
    text-align: center;
  }
`;

StickyTooltip.Root = styled.div`
  position: fixed;
  z-index: 200;
  bottom: 20px;
  left: 0;
  width: 100%;
  padding: 0 10px;
`;

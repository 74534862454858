import Logo from 'components/Logo';
import styled from 'styled-components/macro';

export default function Loader() {
  return (
    <Loader.Root>
      <Logo />
    </Loader.Root>
  );
}

Loader.Root = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

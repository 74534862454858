import ButtonToggle from 'components/ButtonToggle';
import MonthPicker from 'components/MonthPicker';
import { ComponentProps, useMemo, useState } from 'react';
import Calendar from 'components/Calendar';
import { getMonthOptions, toApiDate, toMonthOption } from 'utils/date';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import CalendarList from 'components/CalendarList';
import { useRouteMatch } from 'react-router';
import ScheduleTrainingModal from 'components/ScheduleTrainingModal';
import CalendarPickerModal from 'components/CalendarPickerModal';
import useResponsive from 'hooks/useResponsive';
import {
  schema,
  useCreateSchedule,
  useDeleteSchedule,
  useGroupSchedules,
  useUpdateSchedule,
} from 'hooks/data';
import styled from 'styled-components/macro';
import Container from 'components/Container';
import useNow from 'hooks/useNow';
import { ReactComponent as CalendarActiveIcon } from './calendar_active.svg';
import { ReactComponent as CalendarIcon } from './calendar.svg';
import { ReactComponent as ListActiveIcon } from './list_active.svg';
import { ReactComponent as ListIcon } from './list.svg';

enum Variant {
  CALENDAR = 'CALENDAR',
  LIST = 'LIST',
}

export default function ScheduleFragment() {
  const match = useRouteMatch<{ id: string }>();
  const id = Number(match.params.id);
  const { isNotDesktop } = useResponsive();
  const now = useNow();
  const [month, setMonth] = useState(toMonthOption(now).toISOString());
  const monthMoment = useMemo(() => moment(month), [month]);
  const params = useMemo(
    () => ({
      year: monthMoment.format('YYYY'),
      month: monthMoment.format('M'),
    }),
    [monthMoment]
  );
  const schedules = useGroupSchedules(id, params);
  const deleteSchedule = useDeleteSchedule();
  const createSchedule = useCreateSchedule();
  const updateSchedule = useUpdateSchedule();
  const [variant, setVariant] = useState(Variant.CALENDAR);

  const options = useMemo(
    () =>
      getMonthOptions(now).map((option) => ({
        value: option.toISOString(),
        label: capitalize(option.format('MMMM YYYY')),
      })),
    [now]
  );
  const schedulesByDate = useMemo(() => {
    const ret: ComponentProps<typeof CalendarList>['schedulesByDate'] = {};
    schedules.data?.results?.forEach((schedule) => {
      if (ret[schedule.training_date]) {
        ret[schedule.training_date]!.push(schedule);
      } else {
        ret[schedule.training_date] = [schedule];
      }
    });
    return ret;
  }, [schedules.data?.results]);
  const [updateTrainingState, setUpdateTrainingState] = useState<
    schema['TrainingsScheduleItemDetail']
  >();
  const [addTrainingState, setAddTrainingState] = useState<moment.Moment>();
  const [moveTrainingState, setMoveTrainingState] = useState<
    schema['TrainingsScheduleItemDetail']
  >();
  const calendarView = useMemo(() => {
    const calendarProps: ComponentProps<typeof CalendarList> = {
      month,
      onMove: setMoveTrainingState,
      schedulesByDate: schedulesByDate,
      onAddTraining: setAddTrainingState,
      onUpdateTraining: setUpdateTrainingState,
      onDelete: (schedule) => {
        deleteSchedule(schedule.id).then(() => {
          schedules.revalidate();
        });
      },
    };

    if (variant === Variant.LIST || isNotDesktop) {
      return <CalendarList {...calendarProps} />;
    } else {
      return <Calendar {...calendarProps} />;
    }
  }, [
    deleteSchedule,
    isNotDesktop,
    month,
    schedules,
    schedulesByDate,
    variant,
  ]);
  return (
    <Container>
      <ScheduleFragment.Head>
        <MonthPicker value={month} onChange={setMonth} options={options} />
        {!isNotDesktop && (
          <ButtonToggle
            value={variant}
            onChange={setVariant}
            items={[
              {
                renderTitle: ({ active }) =>
                  active ? <CalendarActiveIcon /> : <CalendarIcon />,
                value: Variant.CALENDAR,
              },
              {
                renderTitle: ({ active }) =>
                  active ? <ListActiveIcon /> : <ListIcon />,
                value: Variant.LIST,
              },
            ]}
          />
        )}
      </ScheduleFragment.Head>
      {calendarView}
      <ScheduleTrainingModal
        date={moment(updateTrainingState?.training_date)}
        isOpen={!!updateTrainingState}
        onRequestClose={() => setUpdateTrainingState(undefined)}
        onSubmit={(training) => {
          updateSchedule(updateTrainingState!.id, {
            training: training.id,
          }).then(() => {
            schedules.revalidate();
            setUpdateTrainingState(undefined);
          });
        }}
      />
      <ScheduleTrainingModal
        date={addTrainingState}
        isOpen={!!addTrainingState}
        onRequestClose={() => setAddTrainingState(undefined)}
        onSubmit={(training) => {
          createSchedule({
            training_date: toApiDate(addTrainingState!),
            trainer_group: id,
            training: training.id,
          }).then(() => {
            schedules.revalidate();
            setAddTrainingState(undefined);
          });
        }}
      />
      <CalendarPickerModal
        isOpen={!!moveTrainingState}
        group={id}
        onRequestClose={() => setMoveTrainingState(undefined)}
        onSubmit={(date) => {
          updateSchedule(moveTrainingState!.id, {
            training_date: toApiDate(moment(date)),
          }).then(() => {
            schedules.revalidate();
            setMoveTrainingState(undefined);
          });
        }}
      />
    </Container>
  );
}

ScheduleFragment.Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;

  ${ButtonToggle.Root} > * {
    width: 40px;
    min-height: 40px;
    padding: 0;
  }
`;

import { ReactNode } from 'react';
import styled from 'styled-components/macro';

type RadioProps = GetStyledProps<typeof Radio['Input']> & {
  label?: ReactNode;
};

export default function Radio({ label, className, ...restProps }: RadioProps) {
  return (
    <Radio.Root className={className}>
      <Radio.Input type="radio" {...restProps} />
      <Radio.Switcher />
      {label && <Radio.Label>{label}</Radio.Label>}
    </Radio.Root>
  );
}

Radio.Root = styled.label`
  position: relative;
  pointer-events: none;
`;
Radio.Switcher = styled.i``;

Radio.Input = styled.input`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: auto;
  cursor: pointer;

  & + ${Radio.Switcher} {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: inline-block;
  }

  // Box
  & + ${Radio.Switcher}:before {
    content: '';
    position: relative;
    display: inline-block;
    vertical-align: text-top;
    width: 24px;
    height: 24px;
    background: ${(props) => props.theme.colors.backgroundPrimary};
    border: 1px solid
      ${(props) => props.theme.colors.backgroundAccentAlternative};
    border-radius: 50%;
  }

  &:checked + ${Radio.Switcher}:after {
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    position: absolute;
    background: ${(props) => props.theme.colors.primary};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
Radio.Label = styled.span`
  margin-left: 9px;
`;

import Button from 'components/Button';
import CardModal from 'components/CardModal';
import GroupListItem from 'components/GroupListItem';
import List from 'components/List';
import Surface from 'components/Surface';

import { schema, useGroups, useMoveStudentsToGroup } from 'hooks/data';
import { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import plural from 'plural-ru';
import Radio from 'components/Radio';
import { mq } from 'styles/theme';
import { Link } from 'react-router-dom';
import { useStore } from 'store';

type MoveToGroupModalProps = Omit<
  GetStyledProps<typeof MoveToGroupModal['Root']>,
  'isOpen'
> & {
  state?: Array<schema['StudentList']['id']>;
  excludedGroup?: schema['TrainerGroupDetail']['id'];
  onMoved?: () => any;
};

export default function MoveToGroupModal({
  title = 'Выберите группу',
  className,
  onMoved,
  state,
  excludedGroup,
  ...restProps
}: MoveToGroupModalProps) {
  const [value, setValue] = useState<schema['TrainerGroupList']>();
  const [loading, setLoading] = useState(false);
  const groups = useGroups();
  const { notifications } = useStore();
  const computedGroups = useMemo(
    () =>
      excludedGroup
        ? groups.data?.results?.filter((group) => group.id !== excludedGroup)
        : groups.data?.results,
    [excludedGroup, groups.data?.results]
  );
  const moveStudentsToGroup = useMoveStudentsToGroup();
  return (
    <MoveToGroupModal.Root
      isOpen={!!state}
      shouldCloseOnOverlayClick={!loading}
      title={title}
      {...restProps}
    >
      <Surface variant="info">
        Прогресс учеников после перехода в новую группу будет сброшен.
      </Surface>
      <List>
        {computedGroups?.map((group) => (
          <GroupListItem
            control={
              <Radio
                checked={value?.id === group.id}
                onChange={() => setValue(group)}
              />
            }
            forwardedAs="label"
            button
            group={group}
            key={group.id}
          />
        ))}
      </List>
      <MoveToGroupModal.Submit
        disabled={!value || loading}
        onClick={() => {
          setLoading(true);
          return moveStudentsToGroup(value!.id, {
            student_ids: state!,
          })
            .then(() => {
              onMoved?.();
              notifications.add({
                content: (
                  <>
                    {plural(
                      state!.length,
                      'Ученик перемещен',
                      'Ученики перемещены'
                    )}{' '}
                    в группу:{' '}
                    <Button
                      as={Link}
                      to={`/students/groups/${value!.id}`}
                      color="primary"
                      variant="text"
                    >
                      {value!.name}
                    </Button>
                  </>
                ),
              });
              groups.revalidate();
              setValue(undefined);
              (restProps.onRequestClose as any)?.();
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        Перенести {plural(state?.length || 0, 'ученика', 'учеников')} в эту
        группу
      </MoveToGroupModal.Submit>
    </MoveToGroupModal.Root>
  );
}

MoveToGroupModal.Submit = styled(Button).attrs({
  variant: 'contained',
  color: 'accent',
})`
  align-self: center;
`;

MoveToGroupModal.Root = styled(CardModal)`
  .content {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }

  ${List} {
    overflow-y: auto;
    margin-bottom: 30px;
  }

  ${Surface} {
    margin: 30px 0;
  }

  @media ${mq.notDesktop} {
    ${List} {
      margin-bottom: 16px;
    }

    ${Surface} {
      margin: 0 0 24px;
    }

    ${MoveToGroupModal.Submit} {
      width: 100%;
    }
  }
`;

import Container from 'components/Container';
import MoveToGroupModal from 'components/MoveToGroupModal';
import StudentsListView from 'components/StudentsListView';
import { useStudents } from 'hooks/data';
import useStudentsEditor from 'hooks/useStudentsEditor';
import { useHistory } from 'react-router-dom';
import * as qs from 'utils/qs';

export default function ListFragment() {
  const history = useHistory();
  const studentsEditor = useStudentsEditor();
  const students = useStudents(studentsEditor.debouncedParams);
  return (
    <Container>
      <StudentsListView
        list={students.data}
        params={studentsEditor.params}
        onParams={studentsEditor.setParams}
        selected={studentsEditor.selected}
        onSelected={studentsEditor.setSelected}
        onCreateGroup={() => {
          history.push({
            pathname: '/students/groups/create',
            search: qs.stringify({
              student_ids: studentsEditor.selected,
            }),
          });
        }}
        itemProps={() => ({
          link: true,
        })}
        onMove={studentsEditor.setMoveState}
      />

      <MoveToGroupModal
        onMoved={() => students.revalidate()}
        state={studentsEditor.moveState}
        onRequestClose={() => studentsEditor.setMoveState(undefined)}
      />
    </Container>
  );
}

import Card from 'components/Card';
import Details from 'components/Details';
import ListItem from 'components/ListItem';
import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import moment from 'moment';
import { ComponentProps, useMemo, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { breakpoints, mq } from 'styles/theme';
import mean from 'lodash/mean';
import { getPreview } from 'utils/jwplayer';

type ExerciseListItemProps = ComponentProps<typeof ListItem> & {
  exercise: schema['Exercise'];
};

export default function ExerciseListItem({
  exercise,
  ...restProps
}: ExerciseListItemProps) {
  const elem = useRef<HTMLInputElement>(null);
  const [top, setTop] = useState(0);

  useEffect(() => {
    if (!elem.current) {
      return;
    }

    elem.current.addEventListener('mouseover', () => {
      if (!elem.current) {
        return;
      }

      setTop(elem.current.getBoundingClientRect().top);
    });
  }, [elem, top]);

  const duration = useMemo(
    () => moment.duration(exercise.video_duration, 's'),
    [exercise.video_duration]
  );
  const durationContent = (
    <Typography>
      {duration.format('mm:ss', {
        trim: false,
      })}
    </Typography>
  );
  const { isNotDesktop } = useResponsive();
  const image = exercise.video_code && getPreview(exercise.video_code);
  return (
    <ExerciseListItem.Root {...restProps}>
      {image && (
        <ExerciseListItem.ImageContainer ref={elem}>
          <ExerciseListItem.Image src={image} alt={exercise.name} />
          <ExerciseListItem.ImagePreview
            style={{ top: `calc(${top}px + 70px)` }}
          >
            <img src={image} alt={exercise.name} />
          </ExerciseListItem.ImagePreview>
        </ExerciseListItem.ImageContainer>
      )}
      {!isNotDesktop && durationContent}
      <div>
        {isNotDesktop && durationContent}
        <Typography>{exercise.name}</Typography>
        <ExerciseListItem.Details>
          <div>{exercise.skill.name}</div>
          <div>{exercise.level.name} уровень</div>
          <div>{exercise.exercise_type.name}</div>
        </ExerciseListItem.Details>
      </div>
    </ExerciseListItem.Root>
  );
}

ExerciseListItem.Details = styled(Details).attrs({ variant: 'details' })``;
ExerciseListItem.ImagePreview = styled(Card)`
  position: fixed;
  width: 280px;
  max-height: 280px;
  padding: 16px 4px;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  visibility: hidden;
  transform: translate(calc(-100% - 23px), calc(-50% - 37px));
  filter: drop-shadow(6px 0px 30px rgba(0, 0, 0, 0.1));
  z-index: 10;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }

  &:after {
    content: '';
    width: 40px;
    height: 40px;
    display: block;
    background: ${(props) => props.theme.colors.backgroundPrimary};
    position: absolute;
    right: 0;
    top: 50%;
    transform: rotate(45deg) translate(0, -50%);
  }
`;
ExerciseListItem.Image = styled.img`
  width: 72px;
  height: 72px;
  object-fit: contain;
  border: 1px solid ${(props) => props.theme.colors.backgroundAccentAlternative};
  border-radius: 10px;
`;
ExerciseListItem.ImageContainer = styled.div`
  position: relative;

  @media (min-width: ${mean([
      breakpoints.desktopXl,
      breakpoints.desktopXxl,
    ])}px) {
    &:hover {
      ${ExerciseListItem.ImagePreview} {
        visibility: visible;
      }
    }
  }
`;
ExerciseListItem.Root = styled(ListItem)<{ button?: boolean }>`
  padding: 12px;

  ${ListItem.Content} {
    overflow: visible;
  }

  ${(props) =>
    props.button &&
    css`
      transition: background 150ms;
      &:hover {
        background: ${props.theme.colors.backgroundAccent};
      }
    `}

  ${ListItem.Content} {
    & > * + * {
      margin-left: 24px;
    }
  }

  @media ${mq.notDesktop} {
    ${ExerciseListItem.Image} {
      width: 64px;
      height: 64px;
    }
  }
`;

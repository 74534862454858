import * as qs from 'utils/qs';
import Button from 'components/Button';
import FormField from 'components/FormField';
import Input from 'components/Input';
import Label from 'components/Label';
import Layout from 'components/Layout';
import Select from 'components/Select';
import StudentsListView from 'components/StudentsListView';
import Typography from 'components/Typography';
import { Field, Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { ApiError, useCreateGroup, useLevels, useStudents } from 'hooks/data';
import styled from 'styled-components/macro';
import Container from 'components/Container';
import { asideMargin, asideWidth } from 'components/ListView';
import useStudentsEditor from 'hooks/useStudentsEditor';
import { mq } from 'styles/theme';
import useDataLayer from 'hooks/useDataLayer';
import { useRouteMatch } from 'react-router-dom';

export default function GroupsCreatePage() {
  const match = useRouteMatch<{ url: string }>();
  const title = 'Создание группы';
  useDataLayer({ title, alias: match.url });
  const history = useHistory();
  const levels = useLevels();
  const query = useMemo(() => qs.parse(history.location.search), [
    history.location.search,
  ]);
  const initialValues = useMemo(() => {
    let student_ids: number[] = [];
    if (query.student_ids) {
      student_ids = Array.isArray(query.student_ids)
        ? query.student_ids.map(Number)
        : [Number(query.student_ids)];
    }
    return {
      name: '',
      level: levels?.[0]?.id,
      student_ids,
    };
  }, [levels, query.student_ids]);
  const studentsEditor = useStudentsEditor();
  const students = useStudents(studentsEditor.debouncedParams);
  const createGroup = useCreateGroup();
  return (
    <Layout>
      <GroupsCreatePage.Content>
        <GroupsCreatePage.Title>{title}</GroupsCreatePage.Title>
        <Formik
          onSubmit={(values, formik) =>
            createGroup(values as any)
              .then((result) => history.push(`/students/groups/${result.id}`))
              .catch((error: ApiError) => formik.setErrors(error.info))
          }
          initialValues={initialValues}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <GroupsCreatePage.Fields>
                <FormField
                  label={<Label htmlFor="name">Название группы</Label>}
                  error={formik.errors.name}
                >
                  <Field
                    id="name"
                    name="name"
                    error={formik.errors.name}
                    as={Input}
                  />
                </FormField>
                <FormField
                  label={<Label htmlFor="level">Уровень группы</Label>}
                  error={formik.errors.level}
                >
                  <Field
                    id="level"
                    name="level"
                    as={Select}
                    onChange={(level: any) =>
                      formik.setFieldValue('level', level)
                    }
                    options={levels?.map((level) => ({
                      label: level.name,
                      value: level.id,
                    }))}
                  />
                </FormField>
              </GroupsCreatePage.Fields>
              <GroupsCreatePage.StudentsTitle>
                Выберите учеников
              </GroupsCreatePage.StudentsTitle>
              <GroupsCreatePage.Students
                list={students.data}
                params={studentsEditor.params}
                onParams={studentsEditor.setParams}
                selected={formik.values.student_ids}
                onSelected={(selected) =>
                  formik.setFieldValue('student_ids', selected)
                }
              />
              <GroupsCreatePage.Submit disabled={formik.isSubmitting}>
                Создать группу
              </GroupsCreatePage.Submit>
            </Form>
          )}
        </Formik>
      </GroupsCreatePage.Content>
    </Layout>
  );
}

GroupsCreatePage.Title = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 31px;
`;
GroupsCreatePage.StudentsTitle = styled(Typography).attrs({ variant: 'h2' })`
  margin-bottom: 41px;
`;
GroupsCreatePage.Fields = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 0.75fr;
  max-width: calc(100% - ${asideWidth}px - ${asideMargin}px);
  margin-bottom: 57px;
`;

GroupsCreatePage.Students = styled(StudentsListView)`
  margin-bottom: 55px;
`;

GroupsCreatePage.Submit = styled(Button).attrs({
  type: 'submit',
  variant: 'contained',
  color: 'accent',
})``;

GroupsCreatePage.Content = styled(Container)`
  padding-top: 90px;
  padding-bottom: 32px;

  @media ${mq.notDesktop} {
    padding-top: 40px;

    ${GroupsCreatePage.Fields} {
      max-width: 100%;
      grid-template-columns: 1fr;
    }

    ${GroupsCreatePage.Submit} {
      width: 100%;
    }
  }
`;

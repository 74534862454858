import Button from 'components/Button';
import FormField from 'components/FormField';
import Input from 'components/Input';
import Label from 'components/Label';
import Select from 'components/Select';
import Textarea from 'components/Textarea';
import { Field, Form, Formik, FormikConfig } from 'formik';
import { ComponentProps, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import ExercisesModal from 'components/ExercisesModal';
import List from 'components/List';
import ExerciseListItem from 'components/ExerciseListItem';
import ListItemActions from 'components/ListItemActions';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as ArrowTopIcon } from 'assets/icons/arrow_top.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import ExerciseType from 'components/ExerciseType';
import useResponsive from 'hooks/useResponsive';
import { schema, useExercisesTypes, useLevels, useSkills } from 'hooks/data';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import { move } from 'utils/array';

export type TrainingFormValues = schema['TrainingCreateUpdateRequest'];

type TrainingFormProps = Omit<ComponentProps<typeof Form>, 'onSubmit'> & {
  initialValues?: Partial<TrainingFormValues>;
  initialExercisesByType?: Partial<Record<string, Array<schema['Exercise']>>>;
  onSubmit: FormikConfig<TrainingFormValues>['onSubmit'];
};

export default function TrainingForm({
  initialValues = {},
  initialExercisesByType = {},
  onSubmit,
  ...restProps
}: TrainingFormProps) {
  const { isNotDesktop } = useResponsive();
  const skills = useSkills();
  const levels = useLevels();
  const exercisesTypes = useExercisesTypes();
  const [type, setType] = useState<schema['ExerciseType']['id']>();
  const [exercisesByType, setExercisesByType] = useState(
    initialExercisesByType
  );
  return (
    <Formik
      initialValues={initialValues as TrainingFormValues}
      onSubmit={(values, formik) =>
        onSubmit?.(
          {
            ...values,
            exercise_types: Object.keys(exercisesByType).map((type) => ({
              id: Number(type),
              exercise_ids: exercisesByType[type]!.map(
                (exercise) => exercise.id
              ),
            })),
          },
          formik
        )
      }
    >
      {(formik) => (
        <TrainingForm.Root {...restProps}>
          <TrainingForm.Fields>
            <FormField
              error={formik.errors.name}
              label={<Label htmlFor="name">Название</Label>}
            >
              <Field
                id="name"
                name="name"
                error={formik.errors.name}
                as={Input}
              />
            </FormField>
            <FormField
              error={formik.errors.skill}
              label={<Label htmlFor="skill">Навык</Label>}
            >
              <Field
                id="skill"
                name="skill"
                placeholder="Выберите навык"
                error={formik.errors.skill}
                onChange={(value: any) => formik.setFieldValue('skill', value)}
                options={skills?.map((skill) => ({
                  label: skill.name,
                  value: skill.id,
                }))}
                as={Select}
              />
            </FormField>
            <FormField
              error={formik.errors.level}
              label={<Label htmlFor="level">Уровень группы</Label>}
            >
              <Field
                id="level"
                name="level"
                placeholder="Выберите уровень"
                error={formik.errors.level}
                onChange={(value: any) => formik.setFieldValue('level', value)}
                options={levels?.map((level) => ({
                  label: level.name,
                  value: level.id,
                }))}
                as={Select}
              />
            </FormField>
            <FormField
              error={formik.errors.equipment}
              label={<Label htmlFor="equipment">Оборудование</Label>}
            >
              <Field
                id="equipment"
                name="equipment"
                error={formik.errors.equipment}
                as={Input}
              />
            </FormField>
            <FormField
              error={formik.errors.description}
              label={<Label htmlFor="description">Описание тренировки</Label>}
            >
              <Field
                id="description"
                name="description"
                error={formik.errors.description}
                as={Textarea}
              />
            </FormField>
          </TrainingForm.Fields>
          <TrainingForm.Exercises>
            {exercisesTypes.data?.results?.map((type) => {
              return (
                <ExerciseType exerciseType={type} key={type.id}>
                  {exercisesByType[type.id]?.length! > 0 && (
                    <List>
                      {exercisesByType[type.id]!.map(
                        (exercise, index, array) => (
                          <ExerciseListItem
                            button
                            key={index}
                            exercise={exercise}
                            action={
                              <ListItemActions
                                menu={isNotDesktop}
                                actions={[
                                  {
                                    title: 'Переместить упражнение вверх',
                                    disabled: array.length <= 1 || index === 0,
                                    icon: <ArrowTopIcon />,
                                    onClick: () =>
                                      setExercisesByType((prev) => ({
                                        ...prev,
                                        [type.id]: move(
                                          prev[type.id] || [],
                                          index,
                                          index - 1
                                        ),
                                      })),
                                  },
                                  {
                                    title: 'Переместить упражнение вниз',
                                    disabled:
                                      array.length <= 1 ||
                                      index === array.length - 1,
                                    icon: <ArrowIcon />,
                                    onClick: () =>
                                      setExercisesByType((prev) => ({
                                        ...prev,
                                        [type.id]: move(
                                          prev[type.id] || [],
                                          index,
                                          index + 1
                                        ),
                                      })),
                                  },
                                  {
                                    title: 'Дублировать упражнение',
                                    icon: <CopyIcon />,
                                    onClick: () =>
                                      setExercisesByType((prev) => ({
                                        ...prev,
                                        [type.id]: [
                                          ...(prev[type.id] || []),
                                          exercise,
                                        ],
                                      })),
                                  },
                                  {
                                    title: 'Удалить упражнение',
                                    icon: <DeleteIcon />,
                                    onClick: () =>
                                      setExercisesByType((prev) => ({
                                        ...prev,
                                        [type.id]: prev[type.id]?.filter(
                                          (_, i) => i !== index
                                        ),
                                      })),
                                  },
                                ]}
                              />
                            }
                          />
                        )
                      )}
                    </List>
                  )}
                  <TrainingForm.AddExercise
                    variant="outlined"
                    color="accent"
                    leading={<PlusIcon />}
                    onClick={() => setType(type.id)}
                  >
                    Добавить упражнения
                  </TrainingForm.AddExercise>
                </ExerciseType>
              );
            })}
          </TrainingForm.Exercises>
          <TrainingForm.Submit disabled={formik.isSubmitting}>
            Сохранить тренировку
          </TrainingForm.Submit>
          <ExercisesModal
            isOpen={!!type}
            onRequestClose={() => setType(undefined)}
            onSubmit={(exercises) => {
              setExercisesByType((prev) => ({
                ...prev,
                [type!]: [...(prev[type!] || []), ...exercises],
              }));
              setType(undefined);
            }}
          />
        </TrainingForm.Root>
      )}
    </Formik>
  );
}

TrainingForm.Fields = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 103px;

  & > * {
    :nth-child(1) {
      grid-column: 1/3;
    }

    :nth-child(2) {
      grid-column-start: 1;
    }

    :nth-child(5) {
      grid-column: 1/4;
    }
  }
`;

TrainingForm.Exercises = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;

  & > * + * {
    margin-top: 95px;
  }
`;

TrainingForm.AddExercise = styled(Button)`
  margin-top: 33px;
`;

TrainingForm.Submit = styled(Button).attrs({
  type: 'submit',
  variant: 'contained',
  color: 'accent',
})`
  position: fixed;
  bottom: 16px;
`;

TrainingForm.Root = styled(Form)`
  ${TrainingForm.AddExercise},
  ${TrainingForm.Submit} {
    width: 360px;
  }

  @media ${mq.notDesktop} {
    ${TrainingForm.Fields} {
      grid-template-columns: 1fr;

      & > * {
        :nth-child(1),
        :nth-child(2),
        :nth-child(5) {
          grid-column: auto;
        }
      }
    }

    ${TrainingForm.AddExercise},
    ${TrainingForm.Submit} {
      width: 100%;
    }

    ${TrainingForm.Submit} {
      bottom: ${(props) => props.theme.bottomNavigation.height + 16}px;
    }
  }
`;

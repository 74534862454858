import ProgressListItem, { ProgressCounter } from 'components/ProgressListItem';
import StudentListItem from 'components/StudentListItem';
import { schema } from 'hooks/data';
import { ComponentProps } from 'react';

type ProgressStudentListItemProps = ComponentProps<typeof StudentListItem> & {
  progress?: schema['StudentListItemProgress'];
};

export default function ProgressStudentListItem({
  progress,
  action,
  ...restProps
}: ProgressStudentListItemProps) {
  return (
    <ProgressListItem.Root
      $percent={progress ? (progress.done / progress.all) * 100 : 0}
      action={
        <ProgressCounter done={progress?.done || 0} all={progress?.all || 0} />
      }
      as={StudentListItem}
      {...restProps}
    />
  );
}

import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/macro';

export default function useResponsive() {
  const theme = useTheme();
  return {
    isMobile: useMediaQuery({ query: theme.mq.mobile }),
    isTablet: useMediaQuery({ query: theme.mq.tablet }),
    isNotDesktop: useMediaQuery({ query: theme.mq.notDesktop }),
  };
}

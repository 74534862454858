import Button from 'components/Button';
import CardModal from 'components/CardModal';
import GroupListItem from 'components/GroupListItem';
import List from 'components/List';
import Radio from 'components/Radio';
import { schema, useGroups } from 'hooks/data';
import { useState } from 'react';
import styled from 'styled-components/macro';

type SelectGroupModalProps = GetStyledProps<typeof SelectGroupModal['Root']> & {
  onSubmit?: (id: schema['TrainerGroupList']['id']) => any;
};

export default function SelectGroupModal({
  onSubmit,
  ...restProps
}: SelectGroupModalProps) {
  const groups = useGroups();
  const [value, setValue] = useState<schema['TrainerGroupList']['id']>();
  return (
    <SelectGroupModal.Root title="Выберите группу" {...restProps}>
      <List>
        {groups.data?.results?.map((group) => (
          <GroupListItem
            control={
              <Radio
                checked={value === group.id}
                onChange={() => setValue(group.id)}
              />
            }
            forwardedAs="label"
            button
            key={group.id}
            group={group}
          />
        ))}
      </List>
      <Button
        onClick={() => {
          onSubmit?.(value!);
          setValue(undefined);
        }}
        disabled={!value}
        variant="contained"
        color="accent"
      >
        Далее
      </Button>
    </SelectGroupModal.Root>
  );
}

SelectGroupModal.Root = styled(CardModal)`
  .content {
    display: flex;
    flex-direction: column;
  }

  ${List} {
    margin-bottom: 32px;
    flex: 1;
    overflow-y: auto;
  }
`;

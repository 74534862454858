import Button from 'components/Button';
import Card from 'components/Card';
import Details from 'components/Details';
import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import styled from 'styled-components/macro';
import { formatTrainingDuration } from 'utils/format';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { useStore } from 'store';
import SkillImage from 'components/SkillImage';
import { mq } from 'styles/theme';

type StudentTrainingCardProps = {
  training: schema['TrainingList'];
};

export default function StudentTrainingCard({
  training,
}: StudentTrainingCardProps) {
  const { trainingVideo } = useStore();
  return (
    <StudentTrainingCard.Root>
      <StudentTrainingCard.Content>
        <StudentTrainingCard.Name>{training.name}</StudentTrainingCard.Name>
        <StudentTrainingCard.Details>
          <div>{formatTrainingDuration(training.duration)}</div>
          <div>Уровень {training.level.name}</div>
        </StudentTrainingCard.Details>
        {training.equipment && (
          <StudentTrainingCard.Equipment>
            Тебе нужны: {training.equipment}
          </StudentTrainingCard.Equipment>
        )}
        <StudentTrainingCard.Start
          onClick={() => {
            trainingVideo.setState({
              training: training.id,
            });
          }}
        >
          Начать тренировку
        </StudentTrainingCard.Start>
      </StudentTrainingCard.Content>
      <StudentTrainingCard.Image
        background
        level={training.level}
        skill={training.skill}
      />
    </StudentTrainingCard.Root>
  );
}

StudentTrainingCard.Name = styled(Typography).attrs({ variant: 'h3' })``;

StudentTrainingCard.StartMobile = styled(Button).attrs({
  variant: 'contained',
  leading: <PlayIcon />,
  color: 'accent',
})``;

StudentTrainingCard.Start = styled(Button).attrs({
  variant: 'contained',
  color: 'accent',
  leading: <PlayIcon />,
})``;

StudentTrainingCard.Image = styled(SkillImage)`
  width: 100%;
  height: 200px;
`;

StudentTrainingCard.Details = styled(Details)`
  opacity: 0.7;
`;

StudentTrainingCard.Equipment = styled(Typography)`
  opacity: 0.7;
`;

StudentTrainingCard.Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 8px;
  z-index: 1;

  & > * + * {
    margin-top: 8px;
  }
`;

StudentTrainingCard.Root = styled(Card)`
  box-shadow: 0px 0px 15px rgba(155, 218, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media ${mq.desktop} {
    ${StudentTrainingCard.Start} {
      background: ${(props) => props.theme.colors.textLight};
      color: #ff1523;
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
      visibility: hidden;
      white-space: nowrap;
    }

    &:hover {
      background: ${(props) => props.theme.colors.accent};

      ${StudentTrainingCard.Image} {
        opacity: 0.25;
      }

      ${StudentTrainingCard.Name},
      ${StudentTrainingCard.Details},
      ${StudentTrainingCard.Equipment} {
        color: ${(props) => props.theme.colors.textLight};
        opacity: 1;
      }

      ${StudentTrainingCard.Start} {
        visibility: visible;
      }
    }
  }

  @media ${mq.notDesktop} {
    flex-flow: column-reverse;

    ${StudentTrainingCard.Start} {
      margin-top: 27px;
    }

    ${StudentTrainingCard.Content} {
      padding-bottom: 32px;
    }
  }
`;

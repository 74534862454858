import Typography from 'components/Typography';
import { schema, useScheduleMaxOnDay } from 'hooks/data';
import moment from 'moment';
import { useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import { daysInWeek, getCalendarItems, toApiDate } from 'utils/date';

type CalendarPickerProps = Omit<
  GetStyledProps<typeof CalendarPicker['Root']>,
  'onChange'
> & {
  schedules?: schema['PaginatedTrainingsScheduleItemDetailList'];
  onChange?: (value: string) => any;
  value?: string;
  month: string;
};

export default function CalendarPicker({
  className,
  onChange,
  value,
  month,
  schedules,
  ...restProps
}: CalendarPickerProps) {
  const maxOnDay = useScheduleMaxOnDay();
  const monthMoment = useMemo(() => moment(month), [month]);
  const items = useMemo(() => getCalendarItems(monthMoment), [monthMoment]);
  return (
    <CalendarPicker.Root {...restProps}>
      <CalendarPicker.Head>
        {moment.weekdaysShort(true).map((day) => (
          <Typography key={day}>{day}</Typography>
        ))}
      </CalendarPicker.Head>
      <CalendarPicker.Items>
        {items.map((item) => {
          const itemValue = item.toISOString();
          const onDay =
            schedules?.results?.filter(
              (schedule) => toApiDate(item) === schedule.training_date
            ) || [];

          return (
            <CalendarPicker.Item
              hidden={item.month() !== monthMoment.month()}
              disabled={onDay.length >= maxOnDay.data?.schedule_max_on_day!}
              selected={value === itemValue}
              key={itemValue}
              onClick={() => onChange?.(itemValue)}
            >
              {item.date()}
              <CalendarPicker.Markers>
                {onDay.map((_, key) => (
                  <div key={key} />
                ))}
              </CalendarPicker.Markers>
            </CalendarPicker.Item>
          );
        })}
      </CalendarPicker.Items>
    </CalendarPicker.Root>
  );
}

CalendarPicker.Item = styled.button<{
  selected?: boolean;
  disabled?: boolean;
}>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;

  &:disabled {
    pointer-events: none;
    color: #e6e6e6;
  }

  ${(props) =>
    props.selected
      ? css`
          background: ${props.theme.colors.primary};
          color: ${props.theme.colors.textLight};
        `
      : css`
          &:hover {
            background: ${props.theme.colors.backgroundAccent};
          }
        `}
`;

CalendarPicker.Items = styled.div``;

CalendarPicker.Markers = styled.div`
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;

  & > * {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.primaryLight};
  }

  & > * + * {
    margin-left: 3px;
  }
`;

CalendarPicker.Head = styled.div`
  text-transform: uppercase;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textLight};

  & > div {
    color: inherit;
  }
`;

CalendarPicker.Root = styled.div`
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.backgroundAccentAlternative};
  overflow: hidden;

  ${CalendarPicker.Head},
  ${CalendarPicker.Items} {
    display: grid;
    grid-template-columns: repeat(${daysInWeek}, 1fr);
    align-items: center;
    justify-items: center;
    gap: 14px;
    padding: 14px;
  }
`;

import styled, { css } from 'styled-components/macro';
import { ibm, rem } from 'styles/mixins';
import { mq } from 'styles/theme';

const Typography = styled.div<{
  variant?: 'h1' | 'h2' | 'h3' | 'body' | 'details' | 'medium';
}>`
  margin: 0;
  color: ${(props) => props.theme.colors.textPrimary};

  ${(props) => {
    switch (props.variant || 'body') {
      case 'h1':
        return css`
          ${ibm(700)}
          font-size: ${rem(48)};
          line-height: ${rem(56)};

          @media ${mq.notDesktop} {
            font-size: ${rem(32)};
            line-height: ${rem(40)};
          }
        `;
      case 'h2':
        return css`
          ${ibm(600)}
          font-size: ${rem(32)};
          line-height: ${rem(38)};
        `;
      case 'h3':
        return css`
          ${ibm(600)}
          font-size: ${rem(24)};
          line-height: ${rem(32)};
        `;
      case 'medium':
        return css`
          ${ibm(500)}
          font-size: ${rem(18)};
          line-height: ${rem(26)};
        `;
      case 'body':
        return css`
          ${ibm(400)}
          font-size: ${rem(18)};
          line-height: ${rem(26)};
        `;
      case 'details':
        return css`
          ${ibm(400)}
          font-size: ${rem(14)};
          line-height: ${rem(18)};
        `;
    }
  }}
`;

export default Typography;

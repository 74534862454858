import Button from 'components/Button';
import Card from 'components/Card';
import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import styled from 'styled-components/macro';

type ArticleCardProps = GetStyledProps<typeof ArticleCard['Root']> & {
  article: schema['ArticleList'];
};

export default function ArticleCard({
  article,
  ...restProps
}: ArticleCardProps) {
  return (
    <ArticleCard.Root {...restProps}>
      <ArticleCard.Thumbnail src={article.thumbnail} alt={article.name} />
      <ArticleCard.Name>{article.name}</ArticleCard.Name>
      <ArticleCard.Read>Читать</ArticleCard.Read>
    </ArticleCard.Root>
  );
}

ArticleCard.Thumbnail = styled.img`
  margin-bottom: 20px;
  height: 80px;
  max-width: 100%;
`;
ArticleCard.Name = styled(Typography).attrs({ variant: 'h3' })`
  margin-bottom: 16px;
  flex: 1;
`;
ArticleCard.Read = styled(Button).attrs({ variant: 'text', color: 'accent' })``;
ArticleCard.Root = styled(Card)`
  background: ${(props) => props.theme.colors.backgroundAccent};
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    background: ${(props) => props.theme.colors.accent};

    ${ArticleCard.Name},
    ${ArticleCard.Read} {
      color: ${(props) => props.theme.colors.textLight};
    }
  }
`;

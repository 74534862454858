import Select from 'components/Select';
import { ComponentProps, useMemo } from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components/macro';
import { ibm, rem } from 'styles/mixins';
import { ReactComponent as ArrowIcon } from './arrow.svg';

type MonthPickerProps = GetStyledProps<typeof MonthPicker['Root']> & {
  onChange?: (value: any) => any;
};

const components: ComponentProps<typeof ReactSelect>['components'] = {
  IndicatorSeparator: null,
  DropdownIndicator: () => <ArrowIcon />,
};

export default function MonthPicker({
  onChange,
  value,
  ...restProps
}: MonthPickerProps) {
  const computedValue = useMemo(
    () => restProps.options?.find((option: any) => option.value === value),
    [restProps.options, value]
  );
  return (
    <MonthPicker.Root
      classNamePrefix="react-select"
      isSearchable={false}
      components={components}
      onChange={(option: any) => onChange?.(option && option.value)}
      value={computedValue}
      {...restProps}
    />
  );
}

MonthPicker.Root = styled(Select.Root)<ComponentProps<typeof ReactSelect>>`
  .react-select {
    &__placeholder,
    &__single-value {
      position: static;
      transform: none;
      max-width: inherit;
    }

    &__value-container {
      padding: 0 8px 0 0;
      ${ibm(600)}
      color: ${(props) => props.theme.colors.textPrimary};
      font-size: ${rem(32)};
      line-height: ${rem(38)};
    }

    &__control {
      border: none;
      box-shadow: none;
      padding: 0;
    }
  }
`;

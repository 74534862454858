import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import { getWhatsAppUrl } from 'utils/social';
import { ReactComponent as VkIcon } from './vk.svg';
import { ReactComponent as WhatsAppIcon } from './whatsapp.svg';

type StudentTrainerProps = GetStyledProps<typeof StudentTrainer['Root']> & {
  trainer: schema['TrainerData'];
};

export default function StudentTrainer({
  trainer,
  ...restProps
}: StudentTrainerProps) {
  return (
    <StudentTrainer.Root {...restProps}>
      <StudentTrainer.Trainer>
        <StudentTrainer.TrainerPhoto src={trainer.photo} />
        <StudentTrainer.TrainerData>
          <Typography>Мой тренер</Typography>
          <Typography variant="h3">
            {trainer.first_name} {trainer.last_name}
          </Typography>
        </StudentTrainer.TrainerData>
      </StudentTrainer.Trainer>
      <StudentTrainer.Actions>
        {trainer.phone && (
          <StudentTrainer.WhatsApp
            ignoreHover
            forwardedAs="a"
            href={getWhatsAppUrl(trainer.phone)}
            variant="outlined"
            leading={<WhatsAppIcon />}
          >
            Написать в WhatsApp
          </StudentTrainer.WhatsApp>
        )}
        {trainer.vk_link && (
          <StudentTrainer.Vk
            ignoreHover
            forwardedAs="a"
            href={trainer.vk_link}
            variant="outlined"
            leading={<VkIcon />}
          >
            Написать во Вконтакте
          </StudentTrainer.Vk>
        )}
      </StudentTrainer.Actions>
    </StudentTrainer.Root>
  );
}

StudentTrainer.Trainer = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 24px;
  }
`;
StudentTrainer.WhatsApp = styled(Button)`
  border-color: #099e6f;
  color: #099e6f;
  font-weight: 500;
  &:hover {
    color: #fff;
    background: #099e6f;
  }
`;
StudentTrainer.Vk = styled(Button)`
  border-color: #5181b8;
  color: #5181b8;
  font-weight: 500;
  &:hover {
    color: #fff;
    background: #5181b8;
  }
`;
StudentTrainer.TrainerPhoto = styled(Avatar)`
  width: 80px;
  height: 80px;
`;
StudentTrainer.TrainerData = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 12px;
  }
`;
StudentTrainer.Actions = styled.div`
  white-space: nowrap;

  & > * + * {
    margin-left: 30px;
  }
`;

StudentTrainer.Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${mq.notDesktop} {
    flex-direction: column;
    align-items: stretch;

    ${StudentTrainer.Trainer} {
      margin-bottom: 31px;
    }

    ${StudentTrainer.Actions} {
      display: flex;
      flex-direction: column;

      & > * + * {
        margin-left: 0;
        margin-top: 32px;
      }
    }
  }
`;

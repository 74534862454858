import qs from 'query-string';

export const parse = (query: string) =>
  qs.parse(query, {
    arrayFormat: 'comma',
  });

export const stringify = (params: Record<string, any>) =>
  qs.stringify(params, {
    arrayFormat: 'comma',
  });

import GroupAvatar from 'components/GroupAvatar';
import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import styled from 'styled-components/macro';
import { rem } from 'styles/mixins';
import { ReactComponent as StudentsIcon } from './students.svg';

type GroupInfoProps = GetStyledProps<typeof GroupInfo['Root']> & {
  group: schema['TrainerGroupDetail'];
  students: number;
};

export default function GroupInfo({
  group,
  students,
  ...restProps
}: GroupInfoProps) {
  return (
    <GroupInfo.Root {...restProps}>
      <GroupInfo.Avatar name={group.name} level={group.level} />
      <div>
        <GroupInfo.Name>{group.name}</GroupInfo.Name>
        <GroupInfo.Details>
          <Typography>Уровень: {group.level.name}</Typography>
          <Typography>
            <StudentsIcon />
            {students}
          </Typography>
        </GroupInfo.Details>
      </div>
    </GroupInfo.Root>
  );
}

GroupInfo.Avatar = styled(GroupAvatar)`
  width: 72px;
  height: 72px;
  font-size: ${rem(37)};
  line-height: ${rem(45)};
  margin-right: 20px;
`;

GroupInfo.Name = styled(Typography).attrs({ variant: 'h1' })`
  margin-top: 10px;
  margin-bottom: 20px;
`;

GroupInfo.Details = styled.div`
  display: flex;

  & > * + * {
    margin-left: 16px;
  }

  & > * {
    svg {
      margin-right: 6px;
    }
  }
`;
GroupInfo.Root = styled.div`
  display: flex;
`;

import ArticleCard from 'components/ArticleCard';
import ArticlesList from 'components/ArticlesList';
import Breadcrumbs from 'components/Breadcrumbs';
import { UserRole } from 'utils/user';

import Container from 'components/Container';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import Typography from 'components/Typography';
import { useArticleCategory, useCategoryArticles, useUser } from 'hooks/data';
import ErrorPage from 'pages/_error';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import useDataLayer from 'hooks/useDataLayer';

export default function ArticlesCategoryPage() {
  const user = useUser();
  const match = useRouteMatch<{
    category: string;
    path: string;
    url: string;
  }>();
  useDataLayer({ title: match.path, alias: match.url });
  const id = Number(match.params.category);
  const category = useArticleCategory(id);
  const articles = useCategoryArticles(id);

  if (category.error) {
    return <ErrorPage statusCode={category.error.status} />;
  }

  if (category.data) {
    return (
      <Layout>
        <ArticlesCategoryPage.Content>
          <ArticlesCategoryPage.Breadcrumbs
            items={[
              ...(user.data?.role === UserRole.STUDENT
                ? [
                    {
                      to: '/',
                      children: 'Мои занятия',
                    },
                  ]
                : [
                    {
                      to: '/materials/articles',
                      children: 'Статьи',
                    },
                  ]),
              {
                to: match.url,
                children: category.data.name,
              },
            ]}
          />
          <ArticlesCategoryPage.Title>
            {category.data.name}
          </ArticlesCategoryPage.Title>
          <ArticlesList>
            {articles.data?.results?.map((item) => (
              <ArticleCard
                as={Link}
                to={`/articles/${category.data!.id}/${item.id}`}
                article={item}
                key={item.id}
              />
            ))}
          </ArticlesList>
        </ArticlesCategoryPage.Content>
      </Layout>
    );
  }

  return <Loader />;
}

ArticlesCategoryPage.Breadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 28px;
`;
ArticlesCategoryPage.Title = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 43px;
`;
ArticlesCategoryPage.Content = styled(Container)`
  padding-top: 60px;
  padding-bottom: 60px;

  @media ${mq.notDesktop} {
    padding-top: 0;
  }
`;

import Card from 'components/Card';
import Details from 'components/Details';
import ListItem from 'components/ListItem';
import SkillImage from 'components/SkillImage';
import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import { iconByFeedback, titleByFeedback } from 'pages/feedback/[id]';
import { useMemo } from 'react';
import styled from 'styled-components/macro';
import { fromApiDate } from 'utils/date';

type FeedbackItemProps = {
  feedback: schema['TrainingsFeedback'];
};

export default function FeedbackItem({ feedback }: FeedbackItemProps) {
  const { isNotDesktop } = useResponsive();
  const date = useMemo(
    () => fromApiDate(feedback.adding_date).format('DD MMM ddd'),
    [feedback.adding_date]
  );
  const detailsNode = (
    <FeedbackItem.Details>
      <div>{feedback.schedule_item.training.duration} мин.</div>
      <div>{feedback.schedule_item.training.skill.name}</div>
      <div>{feedback.schedule_item.training.level.name} уровень</div>
    </FeedbackItem.Details>
  );
  const feedbackNode = feedback.feedback && (
    <FeedbackItem.Feedback>
      {iconByFeedback[feedback.feedback]}
      {!isNotDesktop && <span>{titleByFeedback[feedback.feedback]}</span>}
    </FeedbackItem.Feedback>
  );
  const mainNode = (
    <FeedbackItem.Main>
      <Typography>{feedback.schedule_item.training.name}</Typography>
      {detailsNode}
    </FeedbackItem.Main>
  );
  return isNotDesktop ? (
    <FeedbackItem.Card>
      {feedbackNode}
      <div>
        <Typography variant="medium">{date}</Typography>
        {mainNode}
      </div>
    </FeedbackItem.Card>
  ) : (
    <FeedbackItem.ListItem action={feedbackNode}>
      <Typography>{date}</Typography>
      <FeedbackItem.Image
        skill={feedback.schedule_item.training.skill}
        level={feedback.schedule_item.training.level}
      />
      {mainNode}
    </FeedbackItem.ListItem>
  );
}

FeedbackItem.Image = styled(SkillImage)`
  width: 64px;
  height: 64px;
`;

FeedbackItem.Feedback = styled(Typography)`
  display: flex;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;
  }
`;

FeedbackItem.Main = styled.div`
  & > * + * {
    margin-top: 6px;
  }
`;

FeedbackItem.Card = styled(Card)`
  padding: 22px 16px;
  display: flex;

  & > * + * {
    margin-left: 26px;
  }

  ${FeedbackItem.Main} {
    margin-top: 6px;
  }
`;

FeedbackItem.Details = styled(Details).attrs({ variant: 'details' })`
  color: ${(props) => props.theme.colors.textPrimaryDark};
`;

FeedbackItem.ListItem = styled(ListItem)`
  padding: 16px;

  ${ListItem.Content} {
    & > * + * {
      margin-left: 23px;
    }
  }

  ${FeedbackItem.Feedback} {
    width: 200px;
    svg {
      margin-right: 11px;
    }
  }

  ${FeedbackItem.Details} {
    opacity: 0.5;
  }
`;

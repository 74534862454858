import Button from 'components/Button';
import Container from 'components/Container';
import Layout from 'components/Layout';
import Typography from 'components/Typography';
import useResponsive from 'hooks/useResponsive';
import useDataLayer from 'hooks/useDataLayer';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import TrainingsAllFragment from './all';
import TrainingsStarFragment from './star';
import TrainingsWeekFragment from './week';

export default function TrainingsPage() {
  const match = useRouteMatch<{ url: string }>();
  const title = 'Тренировки';
  useDataLayer({ title, alias: match.url });
  const { isNotDesktop } = useResponsive();
  return (
    <Layout background="accent">
      <TrainingsPage.Content>
        <TrainingsPage.Head>
          {isNotDesktop && (
            <TrainingsPage.TabsTitle variant="h1">
              {title}
            </TrainingsPage.TabsTitle>
          )}
          <TrainingsPage.Tabs>
            <TrainingsPage.Tab to="/trainings/week">
              {isNotDesktop ? 'На неделе' : 'Тренировки недели'}
            </TrainingsPage.Tab>
            <TrainingsPage.Tab to="/trainings/star">
              Со звездой
            </TrainingsPage.Tab>
            <TrainingsPage.Tab to="/trainings/all">Все</TrainingsPage.Tab>
          </TrainingsPage.Tabs>
        </TrainingsPage.Head>
        <TrainingsPage.TabContent>
          <Switch>
            <Route
              exact
              path="/trainings/week"
              component={TrainingsWeekFragment}
            />
            <Route
              exact
              path="/trainings/star"
              component={TrainingsStarFragment}
            />
            <Route
              exact
              path="/trainings/all"
              component={TrainingsAllFragment}
            />
            <Redirect to="/trainings/week" />
          </Switch>
        </TrainingsPage.TabContent>
      </TrainingsPage.Content>
    </Layout>
  );
}

TrainingsPage.TabContent = styled.div`
  padding-top: 56px;
`;

TrainingsPage.Content = styled(Container)`
  padding-top: 104px;
  padding-bottom: 30px;

  @media ${mq.notDesktop} {
    padding-top: 0;
  }
`;

TrainingsPage.Head = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${mq.mobile} {
    flex-direction: column;

    & > * + * {
      margin-top: 32px;
    }
  }
`;

TrainingsPage.TabsTitle = styled(Typography)``;

TrainingsPage.Tabs = styled.div`
  & > * + * {
    margin-left: 8px;
  }
`;

TrainingsPage.Tab = styled(Button).attrs({
  forwardedAs: NavLink,
  variant: 'text',
  activeClassName: 'active',
})`
  font-weight: 400;
  color: ${(props) => props.theme.colors.textPrimaryDark};
  min-height: 56px;
  padding: 0 16px;
  border-radius: 10px;

  &.active {
    font-weight: 500;
    color: ${(props) => props.theme.colors.textLight};
    background: ${(props) => props.theme.colors.primary};
  }

  &:not(.active):hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

import Card from 'components/Card';
import Container from 'components/Container';
import Layout from 'components/Layout';
import StudentForm from 'components/StudentForm';
import Typography from 'components/Typography';
import { ApiError, useRegisterStudent } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import { ComponentProps } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import useDataLayer from 'hooks/useDataLayer';
import { useRouteMatch } from 'react-router-dom';

export default function StudentsAddPage() {
  const match = useRouteMatch<{ url: string }>();
  const title = 'Добавление ученика';
  useDataLayer({ title, alias: match.url });
  const history = useHistory();
  const { isNotDesktop } = useResponsive();
  const registerStudent = useRegisterStudent();
  return (
    <Layout background={isNotDesktop ? 'primary' : 'accent'}>
      <StudentsAddPage.Content>
        <StudentsAddPage.Title>{title}</StudentsAddPage.Title>
        <StudentsAddPage.Form
          onSubmit={(values, formik) =>
            registerStudent(values)
              .then((res) => history.push(`/students/${res.id}`))
              .catch((error: ApiError) => formik.setErrors(error.info))
          }
        />
      </StudentsAddPage.Content>
    </Layout>
  );
}

StudentsAddPage.Title = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 48px;
`;
StudentsAddPage.Form = styled((props: ComponentProps<typeof StudentForm>) => (
  <Card as={StudentForm} {...props} />
))`
  padding: 64px;
`;
StudentsAddPage.Content = styled(Container)`
  padding-top: 90px;
  padding-bottom: 64px;

  @media ${mq.notDesktop} {
    ${StudentsAddPage.Form} {
      padding: 0;
    }
  }
`;

import Layout from 'components/Layout';
import Loader from 'components/Loader';
import { ApiError, useStudent, useUpdateStudent } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import ErrorPage from 'pages/_error';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useDataLayer from 'hooks/useDataLayer';
import StudentsAddPage from '../add';

export default function StudentsEditPage() {
  const history = useHistory();
  const match = useRouteMatch<{ id: string; url: string }>();
  const title = 'Редактирование профиля ученика';
  useDataLayer({ title, alias: match.url });
  const { isNotDesktop } = useResponsive();
  const id = Number(match.params.id);
  const student = useStudent(id);
  const updateStudent = useUpdateStudent();

  if (student.error) {
    return <ErrorPage statusCode={student.error.status} />;
  }

  if (student.data) {
    return (
      <Layout background={isNotDesktop ? 'primary' : 'accent'}>
        <StudentsAddPage.Content>
          <StudentsAddPage.Title>{title}</StudentsAddPage.Title>
          <StudentsAddPage.Form
            isEdit
            initialValues={{
              first_name: student.data.first_name,
              last_name: student.data.last_name,
              gender: student.data.gender,
              birth_day: student.data.birth_day,
              phone: student.data.phone,
              username: student.data.username!,
              vk_link: student.data.vk_link,
            }}
            onSubmit={(values, formik) =>
              updateStudent(id, values)
                .then(() => {
                  student.revalidate();
                  history.push(`/students/${id}`);
                })
                .catch((error: ApiError) => formik.setErrors(error.info))
            }
          />
        </StudentsAddPage.Content>
      </Layout>
    );
  }

  return <Loader />;
}

import BodyBackground from 'components/BodyBackground';
import Card from 'components/Card';
import Container from 'components/Container';
import Header, { MobileHeader } from 'components/Header';
import TabLink from 'components/TabLink';
import Typography from 'components/Typography';
import {
  ApiError,
  schema,
  useLoginStudent,
  useLoginTrainer,
  useRestorePassword,
} from 'hooks/data';
import useDataLayer from 'hooks/useDataLayer';
import useResponsive from 'hooks/useResponsive';
import { Redirect, Route, Switch } from 'react-router';
import styled from 'styled-components/macro';
import { Field, Form, Formik, FormikConfig } from 'formik';
import Surface from 'components/Surface';
import { UserRole } from 'utils/user';
import FormField from 'components/FormField';
import Button from 'components/Button';
import Label from 'components/Label';
import Input, { PasswordInput } from 'components/Input';
import InstallMessage from 'components/InstallMessage';
import { ibm } from 'styles/mixins';
import { Link, useRouteMatch } from 'react-router-dom';
import { useState } from 'react';
import { mq } from 'styles/theme';
import { ReactComponent as Mascot } from './mascot.svg';

export default function LoginPage() {
  const match = useRouteMatch<{ url: string }>();
  const title = 'Вход';
  useDataLayer({ title, alias: match.url });
  const { isNotDesktop } = useResponsive();
  return (
    <>
      <BodyBackground color="accent" />
      <LoginPage.Root>
        {isNotDesktop ? <MobileHeader /> : <Header />}
        <LoginPage.Content as="main">
          <LoginPage.Main>
            <LoginPage.Title>{title}</LoginPage.Title>
            <LoginPage.Tabs>
              <TabLink exact to="/login/student">
                Я ученик
              </TabLink>
              <TabLink to="/login/trainer">Я тренер</TabLink>
            </LoginPage.Tabs>
            <LoginPage.Card>
              <LoginPage.CardContent>
                <Switch>
                  <Route exact path="/login/student" component={LoginStudent} />
                  <Route exact path="/login/trainer" component={LoginTrainer} />
                  <Route
                    exact
                    path="/login/trainer/reset"
                    component={LoginTrainerReset}
                  />
                  <Redirect to="/login/student" />
                </Switch>
              </LoginPage.CardContent>
            </LoginPage.Card>
            <LoginPage.InstallMessage>
              <InstallMessage buttonProps={{ variant: 'outlined' }} />
            </LoginPage.InstallMessage>
          </LoginPage.Main>
          {!isNotDesktop && <Mascot />}
        </LoginPage.Content>
      </LoginPage.Root>
    </>
  );
}

LoginPage.Main = styled.div`
  flex-basis: 448px;
`;
LoginPage.Content = styled(Container)`
  display: flex;
  padding-top: 114px;
`;

LoginPage.Title = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 34px;
`;

LoginPage.Card = styled(Card)`
  padding: 44px 48px;
  border-top-left-radius: 0;
`;

LoginPage.CardContent = styled.div``;

LoginPage.Tabs = styled.div`
  display: flex;
`;

LoginPage.InstallMessage = styled(Container)`
  margin-top: 40px;
  margin-bottom: 20px;
`;

LoginPage.Root = styled.div`
  @media ${mq.notDesktop} {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    ${LoginPage.Content} {
      padding: 0;
      flex: 1;
      flex-direction: column;
    }

    ${LoginPage.Main} {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    ${LoginPage.Card} {
      padding: 44px 0;
      border-radius: 0;
      flex: 1;
    }

    ${LoginPage.Tabs} {
      & > * {
        flex: 1;
        text-align: center;
      }
    }

    ${LoginPage.Title},
    ${LoginPage.CardContent} {
      padding: 0 16px;
    }
  }

  @media ${mq.tablet} {
    ${LoginPage.Tabs},
    ${LoginPage.Title},
    ${LoginPage.CardContent} {
      max-width: 354px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
`;

export function LoginStudent() {
  const loginStudent = useLoginStudent();
  return (
    <>
      <LoginStudent.Form
        onSubmit={(values, formik) =>
          loginStudent(values as any)
            .then(() => {
              window.location.href = '/';
            })
            .catch((error: ApiError) => formik.setErrors(error.info))
        }
        role={UserRole.STUDENT}
      />
      <Typography>
        Если ты не помнишь логин или пароль, напиши своему тренеру в WhatsApp.
      </Typography>
    </>
  );
}

LoginStudent.Form = styled(LoginForm)`
  margin-bottom: 25px;
`;

export function LoginTrainer() {
  const loginTrainer = useLoginTrainer();
  return (
    <>
      <LoginTrainer.Form
        role={UserRole.TRAINER}
        onSubmit={(values, formik) =>
          loginTrainer(values as any)
            .then(() => {
              window.location.href = '/';
            })
            .catch((error: ApiError) => formik.setErrors(error.info))
        }
      />
      <LoginTrainer.Actions>
        <LoginTrainer.Action forwardedAs={Link} to="/register">
          Создать аккаунт
        </LoginTrainer.Action>
        <LoginTrainer.Action forwardedAs={Link} to="/login/trainer/reset">
          Я не помню пароль
        </LoginTrainer.Action>
      </LoginTrainer.Actions>
    </>
  );
}

LoginTrainer.Form = styled(LoginForm)`
  margin-bottom: 33px;
`;

LoginTrainer.Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

LoginTrainer.Action = styled(Button).attrs({
  variant: 'text',
})`
  ${ibm(400)}

  &:hover {
    text-decoration: underline;
  }
`;

export function LoginTrainerReset() {
  const restorePassword = useRestorePassword();
  const [sent, setSent] = useState(false);
  return sent ? (
    <Surface variant="info">
      Письмо с дальнейшими инструкциями отправлено вам на почту.
    </Surface>
  ) : (
    <ResetForm
      onSubmit={(values, formik) =>
        restorePassword(values)
          .then(() => setSent(true))
          .catch((error: ApiError) => formik.setErrors(error.info))
      }
    />
  );
}

export type LoginFormValues =
  | schema['LoginTrainerInputRequest']
  | schema['LoginStudentInputRequest'];

type LoginFormProps = {
  initialValues?: Partial<LoginFormValues>;
  role: schema['RoleEnum'];
  onSubmit: FormikConfig<LoginFormValues>['onSubmit'];
};

export function LoginForm({
  initialValues = {},
  onSubmit,
  role,
  ...restProps
}: LoginFormProps) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues as LoginFormValues}
    >
      {(formik) => (
        <LoginForm.Root {...restProps}>
          {(formik.errors as any).detail && (
            <Surface variant="error">{(formik.errors as any).detail}</Surface>
          )}
          {role === UserRole.STUDENT ? (
            <FormField
              error={(formik.errors as any).username}
              label={<Label htmlFor="username">Логин</Label>}
            >
              <Field
                id="username"
                name="username"
                error={(formik.errors as any).username}
                as={Input}
              />
            </FormField>
          ) : (
            <FormField
              error={(formik.errors as any).email}
              label={<Label htmlFor="email">E-mail</Label>}
            >
              <Field
                id="email"
                name="email"
                error={(formik.errors as any).email}
                as={Input}
              />
            </FormField>
          )}
          <FormField
            error={formik.errors.password}
            label={<Label htmlFor="password">Пароль</Label>}
          >
            <Field
              id="password"
              name="password"
              error={formik.errors.password}
              as={PasswordInput}
            />
          </FormField>
          <Button
            disabled={formik.isSubmitting}
            variant="contained"
            color="accent"
            fullWidth
            type="submit"
          >
            Войти
          </Button>
        </LoginForm.Root>
      )}
    </Formik>
  );
}

LoginForm.Root = styled(Form)`
  display: grid;
  gap: 28px;
`;

export type ResetFormValues = schema['RestorePasswordRequest'];

type ResetFormProps = {
  initialValues?: Partial<ResetFormValues>;
  onSubmit: FormikConfig<ResetFormValues>['onSubmit'];
};

export function ResetForm({
  initialValues = {},
  onSubmit,
  ...restProps
}: ResetFormProps) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues as ResetFormValues}
    >
      {(formik) => (
        <ResetForm.Root {...restProps}>
          {(formik.errors as any).detail && (
            <Surface variant="error">{(formik.errors as any).detail}</Surface>
          )}
          <FormField
            error={formik.errors.email}
            label={<Label htmlFor="email">E-mail</Label>}
          >
            <Field
              id="email"
              name="email"
              error={formik.errors.email}
              as={Input}
            />
          </FormField>
          <Button
            disabled={formik.isSubmitting}
            variant="contained"
            color="accent"
            fullWidth
            type="submit"
          >
            Восстановить пароль
          </Button>
        </ResetForm.Root>
      )}
    </Formik>
  );
}

ResetForm.Root = styled(LoginForm.Root)``;

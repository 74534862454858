import Button from 'components/Button';
import CardModal from 'components/CardModal';
import Typography from 'components/Typography';
import { usePrivacyText } from 'hooks/data';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

type PrivacyModalProps = GetStyledProps<typeof PrivacyModal['Root']>;

export default function PrivacyModal(props: PrivacyModalProps) {
  const privacyText = usePrivacyText();
  return (
    <PrivacyModal.Root
      title="Соглашение на обработку персональных данных"
      {...props}
    >
      {privacyText.data?.privacy_policy_text && (
        <PrivacyModal.Text
          dangerouslySetInnerHTML={{
            __html: privacyText.data.privacy_policy_text,
          }}
        />
      )}
      <PrivacyModal.Submit onClick={props.onRequestClose}>
        Готово
      </PrivacyModal.Submit>
    </PrivacyModal.Root>
  );
}

PrivacyModal.Text = styled(Typography)`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 16px;
  padding-right: 8px;
`;
PrivacyModal.Submit = styled(Button).attrs({
  variant: 'contained',
  color: 'accent',
})`
  min-width: 360px;
  align-self: center;
`;
PrivacyModal.Root = styled(CardModal)`
  .content {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
  }

  @media ${mq.notDesktop} {
    ${PrivacyModal.Submit} {
      align-self: stretch;
      min-width: auto;
    }
  }
`;

import { useEffect } from 'react';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default function useDataLayer({
  alias,
  title,
}: {
  alias: string;
  title: string;
}) {
  useEffect(() => {
    if (!sessionStorage.getItem('hasFirstPageInHistory')) {
      sessionStorage.setItem('hasFirstPageInHistory', 'true');
      return;
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'dl_page_view',
        dl_page_path: alias,
        dl_page_title: title,
      });
    }
  }, [alias, title]);
}

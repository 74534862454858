import { ComponentProps } from 'react';
import ReactModal from 'react-modal';
import styled, { css } from 'styled-components/macro';
import clsx from 'clsx';

export const modalGlobalCss = css`
  .ReactModal__Body--open {
    overflow: hidden;
  }
`;

const Modal = styled(
  ({ className, ...restProps }: ComponentProps<typeof ReactModal>) => (
    <ReactModal
      portalClassName={clsx('portal', className)}
      className="content"
      overlayClassName="overlay"
      ariaHideApp={false}
      {...restProps}
    />
  )
)`
  .content {
    position: absolute;
    outline: none;
  }

  .overlay {
    z-index: ${(props) => props.theme.zIndex.modal};
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: ${(props) => props.theme.colors.modalOverlay};

    // Transition
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }
`;

export default Modal;

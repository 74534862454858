import Typography from 'components/Typography';
import { useEffect } from 'react';
import styled from 'styled-components/macro';
import { opacityAnimation } from 'styles/mixins';
import { rgba } from 'polished';
import { mq } from 'styles/theme';
import { useStore } from 'store';
import { NotificationData } from 'store/notifications';

export default function Notifications() {
  const { notifications } = useStore();
  return (
    <Notifications.Root>
      {notifications.state.map((notification) => (
        <Notification
          key={notification.key}
          onClose={() => notifications.remove(notification.key)}
          notification={notification}
        />
      ))}
    </Notifications.Root>
  );
}

Notifications.Root = styled.div`
  position: fixed;
  top: ${(props) => props.theme.header.height + 40}px;
  z-index: ${(props) => props.theme.zIndex.notifications};
  right: 28px;
  width: 328px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media ${mq.notDesktop} {
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    padding: 16px;
  }
`;

type NotificationProps = {
  notification: NotificationData;
  onClose?: () => any;
};

function Notification({ notification, onClose }: NotificationProps) {
  useEffect(() => {
    if (onClose) {
      const timeout = setTimeout(onClose, notification.timeout);
      return () => clearTimeout(timeout);
    }
  }, [notification.timeout, onClose]);
  return <Notification.Root>{notification.content}</Notification.Root>;
}

Notification.Root = styled(Typography)`
  ${opacityAnimation()}
  background: ${(props) => rgba(props.theme.colors.textPrimary, 0.9)};
  padding: 8px 12px;
  color: ${(props) => props.theme.colors.textLight};
  border-radius: 10px;
`;

import StudentTrainingCard from 'components/StudentTrainingCard';
import { useStudentTrainings } from 'hooks/data';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

export default function TrainingsAllFragment() {
  const trainings = useStudentTrainings();
  return (
    <TrainingsAllFragment.Root>
      {trainings.data?.results?.map(
        (training) =>
          training.is_public && (
            <StudentTrainingCard key={training.id} training={training} />
          )
      )}
    </TrainingsAllFragment.Root>
  );
}

TrainingsAllFragment.Root = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;

  @media ${mq.notDesktop} {
    grid-template-columns: 1fr;

    @media ${mq.tablet} {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

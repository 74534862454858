import { ComponentProps } from 'react';
import styled from 'styled-components/macro';
import fallbackSrc from './fallback.svg';

type AvatarProps = GetStyledProps<typeof Avatar['Root']> & {
  src?: string | null;
  alt?: string;
  imgProps?: ComponentProps<'img'>;
};

export default function Avatar({
  src,
  alt,
  imgProps,
  children,
  ...restProps
}: AvatarProps) {
  return (
    <Avatar.Root {...restProps}>
      {children || <img src={src || fallbackSrc} alt={alt} {...imgProps} />}
    </Avatar.Root>
  );
}

Avatar.Root = styled.div`
  border-radius: 50%;
  background: ${(props) => props.theme.colors.backgroundPrimary};
  width: 40px;
  height: 40px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

import BodyBackground from 'components/BodyBackground';
import Button from 'components/Button';
import Card from 'components/Card';
import Container from 'components/Container';
import FormField from 'components/FormField';
import GenderToggle from 'components/GenderToggle';
import Header, { MobileHeader } from 'components/Header';
import Input, { PasswordInput, PhoneInput } from 'components/Input';
import InstallMessage from 'components/InstallMessage';
import Label from 'components/Label';
import MascotMessage from 'components/MascotMessage';
import PrivacyModal from 'components/PrivacyModal';
import Surface from 'components/Surface';
import Typography from 'components/Typography';
import { UserGender } from 'utils/user';
import { Field, Form, Formik } from 'formik';
import { ApiError, schema, useRegisterTrainer } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import useDataLayer from 'hooks/useDataLayer';
import { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

export default function RegisterPage() {
  const match = useRouteMatch<{ url: string }>();
  const title = 'Регистрация';
  useDataLayer({ title, alias: match.url });
  const { isNotDesktop } = useResponsive();
  const registerTrainer = useRegisterTrainer();
  const [isPrivacy, setPrivacy] = useState(false);
  return (
    <>
      <BodyBackground color={isNotDesktop ? 'primary' : 'accent'} />
      <RegisterPage.Root>
        {isNotDesktop ? (
          <MobileHeader />
        ) : (
          <Header
            end={
              <Header.AuthButton forwardedAs={Link} to="/login/trainer">
                Войти
              </Header.AuthButton>
            }
          />
        )}
        <RegisterPage.Content>
          <RegisterPage.Title>{title}</RegisterPage.Title>
          <RegisterPage.Message
            background={isNotDesktop ? 'accent' : 'primary'}
          >
            <div>Добрый день! Давайте знакомиться!</div>
          </RegisterPage.Message>
          <Formik
            onSubmit={(values, formik) =>
              registerTrainer(values)
                .then(() => {
                  window.location.href = '/';
                })
                .catch((error: ApiError) => formik.setErrors(error.info))
            }
            initialValues={
              { gender: UserGender.FEMALE } as schema['RegisterTrainerRequest']
            }
          >
            {(formik) => (
              <RegisterPage.Form>
                {(formik.errors as any).detail && (
                  <Surface variant="error">
                    {(formik.errors as any).detail}
                  </Surface>
                )}
                <RegisterPage.Fields>
                  <FormField
                    error={formik.errors.first_name}
                    label={<Label htmlFor="first_name">Имя</Label>}
                  >
                    <Field
                      id="first_name"
                      name="first_name"
                      error={formik.errors.first_name}
                      as={Input}
                    />
                  </FormField>
                  <FormField
                    error={formik.errors.last_name}
                    label={<Label htmlFor="last_name">Фамилия</Label>}
                  >
                    <Field
                      id="last_name"
                      name="last_name"
                      error={formik.errors.last_name}
                      as={Input}
                    />
                  </FormField>
                  <FormField
                    error={formik.errors.gender}
                    label={<Label htmlFor="gender">Пол</Label>}
                  >
                    <Field
                      id="gender"
                      name="gender"
                      error={formik.errors.gender}
                      as={GenderToggle}
                      onChange={(gender: schema['GenderEnum']) =>
                        formik.setFieldValue('gender', gender)
                      }
                    />
                  </FormField>
                  <FormField
                    error={formik.errors.company}
                    label={<Label htmlFor="company">Организация</Label>}
                  >
                    <Field
                      id="company"
                      name="company"
                      error={formik.errors.company}
                      as={Input}
                    />
                  </FormField>
                  <FormField
                    error={formik.errors.email}
                    label={<Label htmlFor="email">E-mail</Label>}
                  >
                    <Field
                      id="email"
                      name="email"
                      error={formik.errors.email}
                      as={Input}
                    />
                  </FormField>
                  <FormField
                    error={formik.errors.phone}
                    label={<Label htmlFor="phone">Телефон (WhatsApp)</Label>}
                  >
                    <Field
                      id="phone"
                      name="phone"
                      error={formik.errors.phone}
                      as={PhoneInput}
                    />
                  </FormField>
                  <FormField
                    error={formik.errors.vk_link}
                    label={
                      <Label htmlFor="vk_link">
                        Ссылка на профиль Vkontakte
                      </Label>
                    }
                  >
                    <Field
                      id="vk_link"
                      name="vk_link"
                      error={formik.errors.vk_link}
                      as={Input}
                    />
                  </FormField>
                  <FormField
                    error={formik.errors.password}
                    label={<Label htmlFor="password">Пароль</Label>}
                  >
                    <Field
                      id="password"
                      name="password"
                      error={formik.errors.password}
                      as={PasswordInput}
                    />
                  </FormField>
                </RegisterPage.Fields>
                <RegisterPage.Privacy>
                  Нажимая на кнопку, вы соглашаетесь на{' '}
                  <Button
                    onClick={() => setPrivacy(true)}
                    variant="text"
                    color="primary"
                  >
                    обработку персональных данных
                  </Button>
                </RegisterPage.Privacy>
                <RegisterPage.Submit disabled={formik.isSubmitting}>
                  Готово
                </RegisterPage.Submit>
              </RegisterPage.Form>
            )}
          </Formik>
          <RegisterPage.InstallMessage>
            <InstallMessage
              background={isNotDesktop ? 'accent' : 'primary'}
              buttonProps={{ variant: 'outlined' }}
            />
          </RegisterPage.InstallMessage>
        </RegisterPage.Content>
        <PrivacyModal
          isOpen={isPrivacy}
          onRequestClose={() => setPrivacy(false)}
        />
      </RegisterPage.Root>
    </>
  );
}

RegisterPage.Content = styled(Container)`
  padding-top: 90px;
  padding-bottom: 24px;
`;

RegisterPage.Title = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 39px;
`;

RegisterPage.Message = styled(MascotMessage)`
  margin-bottom: 48px;
`;

RegisterPage.Form = styled(Card).attrs({ as: Form })`
  padding: 64px;
`;

RegisterPage.Fields = styled.div`
  display: grid;
  row-gap: 26px;
  column-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
`;

RegisterPage.Privacy = styled(Typography)`
  margin: 33px 0 24px;
`;

RegisterPage.Submit = styled(Button).attrs({
  type: 'submit',
  variant: 'contained',
  color: 'accent',
})`
  min-width: 188px;
`;

RegisterPage.InstallMessage = styled.div`
  margin-top: 40px;
`;

RegisterPage.Root = styled.div`
  @media ${mq.notDesktop} {
    ${RegisterPage.Content} {
      padding-top: 24px;
    }

    ${RegisterPage.Form} {
      padding: 0;
    }

    ${RegisterPage.Message} {
      margin-bottom: 33px;
    }

    ${RegisterPage.Fields} {
      grid-template-columns: 1fr;
    }

    ${RegisterPage.Submit} {
      width: 100%;
    }
  }

  @media ${mq.tablet} {
    ${RegisterPage.Title} {
      text-align: center;
    }

    ${RegisterPage.Message} {
      justify-content: center;

      ${MascotMessage.Avatar} {
        max-height: 74px;
      }

      ${MascotMessage.Message} {
        margin-left: 0;
      }
    }

    ${RegisterPage.Fields} {
      grid-template-columns: repeat(2, 1fr);
    }

    ${RegisterPage.Submit} {
      width: calc(50% - 16px);
    }
  }
`;

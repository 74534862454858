import RewardItem from 'components/RewardItem';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

const RewardsList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 80px;

  @media ${mq.tablet} {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  @media ${mq.mobile} {
    grid-template-columns: 1fr;
    gap: 16px;

    ${RewardItem.Root} svg {
      height: 80px;
      width: auto;
    }
  }
`;

export default RewardsList;

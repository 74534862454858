import Typography from 'components/Typography';
import styled from 'styled-components/macro';

const Details = styled(Typography)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    &:not(:last-child)::after {
      content: '•';
      margin: 0 6px;
    }
  }
`;

export default Details;

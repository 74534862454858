import Modal from 'components/Modal';
import styled from 'styled-components/macro';
import CloseButton from 'components/CloseButton';

type FullscreenModalProps = GetStyledProps<typeof FullscreenModal['Root']>;

export default function FullscreenModal({
  children,
  ...restProps
}: FullscreenModalProps) {
  return (
    <FullscreenModal.Root {...restProps}>
      <FullscreenModal.Content>{children}</FullscreenModal.Content>
      <FullscreenModal.Close onClick={restProps.onRequestClose} />
    </FullscreenModal.Root>
  );
}

FullscreenModal.Close = styled(CloseButton)`
  position: absolute;
  top: 32px;
  right: 32px;
`;

FullscreenModal.Content = styled.div`
  padding: 0 52px;
`;

FullscreenModal.Root = styled(Modal)`
  .content {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => props.theme.colors.backgroundPrimary};
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 80px;
    overflow-y: auto;
  }
`;

import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

const Container = styled.div`
  max-width: ${(props) => props.theme.container.lg}px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media ${mq.notDesktopLg} {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media ${mq.mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default Container;

import Avatar from 'components/Avatar';
import ListItem from 'components/ListItem';
import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

type StudentListItemProps = GetStyledProps<typeof StudentListItem['Root']> & {
  student: schema['StudentList'];
  link?: boolean;
};

export default function StudentListItem({
  student,
  link,
  as,
  ...restProps
}: StudentListItemProps) {
  return (
    <StudentListItem.Root forwardedAs={as} {...restProps}>
      <StudentListItem.Avatar src={student.photo} />
      <StudentListItem.Name
        {...(link
          ? ({
              as: Link,
              to: `/students/${student.id}`,
            } as any)
          : {})}
      >
        {student.first_name} {student.last_name}
      </StudentListItem.Name>
    </StudentListItem.Root>
  );
}

StudentListItem.Name = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
`;
StudentListItem.Avatar = styled(Avatar)`
  margin-right: 11px;
  flex-shrink: 0;
`;
StudentListItem.Root = styled(ListItem)<{ button?: boolean }>`
  ${(props) =>
    props.button &&
    css`
      transition: background 0.2s;
      &:hover {
        background: ${props.theme.colors.backgroundAccent};
        ${StudentListItem.Name} {
          color: ${props.theme.colors.primary};
          font-weight: 500;
        }
      }
    `}
`;

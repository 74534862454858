import Loader from 'components/Loader';
import useDataLayer from 'hooks/useDataLayer';
import { useLogout } from 'hooks/data';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

export default function LogoutPage() {
  const match = useRouteMatch<{ path: string; url: string }>();
  useDataLayer({ title: match.path, alias: match.url });
  const logout = useLogout();
  useEffect(() => {
    logout().then(() => {
      window.location.href = '/login';
    });
  }, [logout]);
  return <Loader />;
}

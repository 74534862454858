import StudentsListView from 'components/StudentsListView';
import { ComponentProps, useCallback, useState } from 'react';
import useDebouncedValue from './useDebouncedValue';

export default function useStudentsEditor() {
  const [params, setParams] = useState<
    ComponentProps<typeof StudentsListView>['params']
  >({});
  const [selected, setSelected] = useState<
    ComponentProps<typeof StudentsListView>['selected']
  >([]);
  const debouncedParams = useDebouncedValue(params);
  const [moveState, setMoveState] = useState<typeof selected>();
  const reset = useCallback(() => {
    setSelected([]);
    setMoveState(undefined);
  }, []);
  return {
    params,
    setParams,
    selected,
    setSelected,
    debouncedParams,
    moveState,
    setMoveState,
    reset,
  };
}

import styled, { css } from 'styled-components/macro';
import { ibm, rem } from 'styles/mixins';
import { ReactNode } from 'react';

type ButtonProps = GetStyledProps<typeof Button['Root']> & {
  leading?: ReactNode;
};

export default function Button({
  leading,
  children,
  ...restProps
}: ButtonProps) {
  return (
    <Button.Root type="button" {...restProps}>
      {leading && <Button.Leading>{leading}</Button.Leading>}
      {children}
    </Button.Root>
  );
}

export type ButtonVariant = 'contained' | 'text' | 'outlined';
export type ButtonColor = 'primary' | 'accent';

Button.Root = styled.button<{
  variant?: ButtonVariant;
  color?: ButtonColor;
  fullWidth?: boolean;
  ignoreHover?: boolean;
}>`
  font-size: ${rem(18)};
  line-height: ${rem(23)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  white-space: nowrap;

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  &:disabled {
    cursor: not-allowed;
  }

  ${({ variant = 'contained', color = 'primary', ignoreHover, theme }) => {
    switch (variant) {
      case 'contained':
        return css`
          ${ibm(700)}
          padding: 0 24px;
          border-radius: 10px;
          background: ${theme.colors.get(color)};
          color: ${theme.colors.getContrast(color)};

          ${!ignoreHover &&
          css`
            &:hover {
              opacity: 0.9;

              /* Для primary не определено -_- */
              ${color === 'accent' &&
              css`
                opacity: 1;
                background: ${theme.colors.accentDark};
              `}
            }
          `}

          &:disabled {
            background: ${theme.colors.disabled};
            opacity: 1;
          }
        `;
      case 'outlined':
        return css`
          ${ibm(700)}
          padding: 0 24px;
          border-radius: 10px;
          color: ${theme.colors.get(color)};
          border: 2px solid ${theme.colors.get(color)};

          ${!ignoreHover &&
          css`
            &:hover {
              background: ${theme.colors.get(color)};
              color: ${theme.colors.getContrast(color)};
            }
          `}

          &:disabled {
            opacity: 0.3;
          }
        `;
      case 'text':
        return css`
          ${ibm(500)}
          color: ${theme.colors.get(color)};
          min-height: auto;

          &:disabled {
            opacity: 0.3;
          }
        `;
    }
  }}
`;

Button.Leading = styled.div`
  margin-right: 13px;
  display: flex;
  align-items: center;

  svg {
    color: inherit;
  }
`;

import { ComponentProps, useMemo } from 'react';
import moment from 'moment';
import { daysInWeek, getCalendarItems, toApiDate } from 'utils/date';
import CalendarList from 'components/CalendarList';
import styled, { css, useTheme } from 'styled-components/macro';
import Typography from 'components/Typography';
import CalendarListItem from 'components/CalendarListItem';
import List from 'components/List';
import ListItem from 'components/ListItem';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import ListItemActions from 'components/ListItemActions';
import { useScheduleMaxOnDay } from 'hooks/data';

type CalendarProps = ComponentProps<typeof CalendarList>;

export default function Calendar({
  month,
  schedulesByDate,
  onDelete,
  onUpdateTraining,
  onAddTraining,
  onMove,
  ...restProps
}: CalendarProps) {
  const monthMoment = useMemo(() => moment(month), [month]);
  const items = useMemo(() => getCalendarItems(monthMoment), [monthMoment]);
  return (
    <Calendar.Root {...restProps}>
      <Calendar.Head>
        {moment.weekdaysShort(true).map((day) => (
          <div key={day}>{day}</div>
        ))}
      </Calendar.Head>
      <Calendar.Items>
        {items.map((item) => (
          <CalendarItem
            schedules={schedulesByDate[toApiDate(item)]}
            key={item.toISOString()}
            item={item}
            disabled={item.month() !== monthMoment.month()}
            onDelete={onDelete}
            onUpdateTraining={onUpdateTraining}
            onAddTraining={onAddTraining}
            onMove={onMove}
          />
        ))}
      </Calendar.Items>
    </Calendar.Root>
  );
}

Calendar.Items = styled.div`
  display: grid;
  grid-template-columns: repeat(${daysInWeek}, 1fr);
`;
Calendar.Head = styled(Typography)`
  display: grid;
  grid-template-columns: repeat(${daysInWeek}, 1fr);
  border-radius: 10px 10px 0 0;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textLight};
  & > * {
    padding: 12px 10px;
    text-transform: uppercase;
  }
`;
Calendar.Root = styled.div``;

type CalendarItemProps = ComponentProps<typeof CalendarListItem> & {
  disabled?: boolean;
};

export function CalendarItem({
  item,
  disabled,
  onDelete,
  schedules,
  onUpdateTraining,
  onMove,
  onAddTraining,
}: CalendarItemProps) {
  const theme = useTheme();
  const maxOnDay = useScheduleMaxOnDay();
  const hasTrainings = schedules?.length! > 0;
  const canAddMore = schedules?.length! < maxOnDay.data?.schedule_max_on_day!;
  return (
    <CalendarItem.Root disabled={disabled} hasTrainings={hasTrainings}>
      <CalendarItem.Day>{item.date()}</CalendarItem.Day>
      {!hasTrainings && (
        <CalendarItem.Add onClick={() => onAddTraining?.(item)}>
          <PlusIcon color={theme.colors.primary} />
          <span>Добавить тренировку</span>
        </CalendarItem.Add>
      )}
      <List>
        {schedules?.map((schedule) => (
          <ListItem
            key={schedule.id}
            divider={false}
            action={
              <ListItemActions
                menu
                actions={[
                  {
                    title: 'Изменить дату',
                    icon: null,
                    onClick: () => onMove?.(schedule),
                  },
                  {
                    title: 'Заменить тренировку на другую',
                    icon: null,
                    onClick: () => onUpdateTraining?.(schedule),
                  },
                  {
                    title: 'Убрать из расписания',
                    icon: null,
                    onClick: () => onDelete?.(schedule),
                  },
                  ...(canAddMore
                    ? [
                        {
                          title: 'Добавить еще одну тренировку',
                          icon: null,
                          onClick: () => onAddTraining?.(item),
                        },
                      ]
                    : []),
                ]}
              />
            }
          >
            {schedule.training.name}
          </ListItem>
        ))}
      </List>
    </CalendarItem.Root>
  );
}

CalendarItem.Add = styled(Typography).attrs({ as: 'button' })`
  background: ${(props) => props.theme.colors.backgroundAccent};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  visibility: hidden;

  & > * + * {
    margin-top: 13px;
  }
`;
CalendarItem.Day = styled(Typography)`
  position: absolute;
  top: 8px;
  left: 10px;
`;

CalendarItem.Root = styled.div<{ disabled?: boolean; hasTrainings: boolean }>`
  padding: 7px 10px;
  min-height: 168px;
  border: 1px solid ${(props) => props.theme.colors.backgroundAccentAlternative};
  position: relative;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
    `}

  &:hover {
    ${CalendarItem.Add} {
      visibility: inherit;
    }

    ${CalendarItem.Day} {
      z-index: 1;
    }
  }

  ${(props) =>
    props.hasTrainings &&
    css`
      display: flex;
      flex-direction: column;

      ${CalendarItem.Add} {
        right: 10px;
        top: 7px;
        bottom: auto;
        left: auto;
        background: none;
        visibility: visible;
        z-index: 1;
      }

      ${CalendarItem.Day} {
        position: static;
        margin-bottom: 15px;
      }
    `}

  ${ListItem.Root} {
    padding: 0;
    margin: 0 0 8px;
  }
`;

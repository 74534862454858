import { createGlobalStyle } from 'styled-components/macro';

export default createGlobalStyle<{ color?: 'primary' | 'accent' }>`
    body {
        background: ${(props) => {
          switch (props.color || 'primary') {
            case 'primary':
              return props.theme.colors.backgroundPrimary;
            case 'accent':
              return props.theme.colors.backgroundAccent;
          }
        }};
    }
`;

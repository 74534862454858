import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

type RewardItemProps = {
  reward: schema['Reward'];
  hiddenName?: boolean;
};

export default function RewardItem({ reward, hiddenName }: RewardItemProps) {
  return (
    <RewardItem.Root>
      <div
        dangerouslySetInnerHTML={{
          __html: reward.svg,
        }}
      />
      {!hiddenName && <Typography>{reward.achievement.name}</Typography>}
    </RewardItem.Root>
  );
}

RewardItem.Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > * + * {
    margin-top: 8px;
  }

  & > ${Typography} {
    text-align: center;
  }

  @media ${mq.mobile} {
    flex-direction: row;

    & > ${Typography} {
      text-align: left;
    }

    & > * + * {
      margin-top: 0;
      margin-left: 8px;
    }
  }
`;

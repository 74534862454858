import { schema } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import plural from 'plural-ru';
import Button from 'components/Button';
import { useMemo } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { mq } from 'styles/theme';
import Typography from 'components/Typography';
import Social from 'components/Social';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Avatar from 'components/Avatar';
import Details from 'components/Details';

type StudentInfoProps = {
  info: schema['StudentInfo'];
};

export default function StudentInfo({ info }: StudentInfoProps) {
  const { isNotDesktop } = useResponsive();
  const content = useMemo(() => {
    const name = (
      <StudentInfo.Name>
        {info.first_name} {info.last_name}
      </StudentInfo.Name>
    );
    const avatar = <StudentInfo.Avatar src={info.photo} />;
    const social = <Social vk={info.vk_link} whatsapp={info.phone} />;
    const sublineItems = (
      <>
        {info.age !== null && (
          <Typography>
            {info.age} {plural(info.age, 'год', 'года', 'лет')}
          </Typography>
        )}
        {info.level && <Typography>Уровень: {info.level.name}</Typography>}
        {info.group && <Typography>Группа: {info.group.name}</Typography>}
      </>
    );
    const editButton = (
      <Button
        as={Link}
        to={`/students/${info.id}/edit`}
        variant="outlined"
        color="accent"
        leading={<EditIcon />}
      >
        Изменить
      </Button>
    );

    if (isNotDesktop) {
      return (
        <>
          <StudentInfo.Main>
            <StudentInfo.Headline>{name}</StudentInfo.Headline>
            <StudentInfo.Subline>{sublineItems}</StudentInfo.Subline>
            {social}
            {editButton}
          </StudentInfo.Main>
          {avatar}
        </>
      );
    }
    return (
      <>
        {avatar}
        <StudentInfo.Main>
          <StudentInfo.Headline>
            {name}
            {social}
          </StudentInfo.Headline>
          <StudentInfo.Subline>{sublineItems}</StudentInfo.Subline>
        </StudentInfo.Main>
        {editButton}
      </>
    );
  }, [
    info.age,
    info.first_name,
    info.group,
    info.id,
    info.last_name,
    info.level,
    info.phone,
    info.photo,
    info.vk_link,
    isNotDesktop,
  ]);
  return <StudentInfo.Root>{content}</StudentInfo.Root>;
}

StudentInfo.Avatar = styled(Avatar)`
  width: 72px;
  height: 72px;
  margin-right: 20px;
`;

StudentInfo.Headline = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

StudentInfo.Subline = styled(Details)`
  display: flex;
  align-items: center;

  & > *::after {
    color: ${(props) => props.theme.colors.accent};
  }
`;

StudentInfo.Main = styled.div`
  flex: 1;
`;

StudentInfo.Name = styled(Typography).attrs({ variant: 'h1' })`
  margin-right: 32px;
`;

StudentInfo.Root = styled.div`
  display: flex;
  align-items: flex-start;

  @media ${mq.notDesktop} {
    ${Social.Root} {
      margin: 24px 0 48px;
    }

    ${StudentInfo.Headline} {
      margin-bottom: 12px;
    }

    @media ${mq.tablet} {
      flex-direction: row-reverse;

      ${StudentInfo.Main} {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      ${StudentInfo.Headline},
      ${StudentInfo.Subline} {
        flex-basis: 100%;
      }

      ${StudentInfo.Subline} {
        margin-bottom: 20px;
      }

      ${Social.Root} {
        margin: 0 13px 0 0;
      }
    }
  }
`;

import Layout from 'components/Layout';
import styled from 'styled-components/macro';
import StudentProfileInfo from 'components/StudentProfileInfo';
import StudentProfileProgress from 'components/StudentProfileProgress';
import useResponsive from 'hooks/useResponsive';
import StudentProfileRewards from 'components/StudentProfileRewards';
import useDataLayer from 'hooks/useDataLayer';
import { useRouteMatch } from 'react-router-dom';

export default function StudentProfilePage() {
  const match = useRouteMatch<{ url: string }>();
  useDataLayer({ title: 'Профиль ученика', alias: match.url });
  const { isMobile } = useResponsive();
  return (
    <StudentProfilePage.Root background={isMobile ? 'accent' : 'primary'}>
      <StudentProfileInfo />
      <StudentProfileProgress />
      <StudentProfileRewards />
    </StudentProfilePage.Root>
  );
}

StudentProfilePage.Root = styled(Layout)``;

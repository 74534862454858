import CardModal from 'components/CardModal';
import FiltersButton from 'components/FiltersButton';
import { SearchInput } from 'components/Input';
import useResponsive from 'hooks/useResponsive';
import { ReactNode, useState } from 'react';
import styled from 'styled-components/macro';

export const asideMargin = 128;
export const asideWidth = 270;

type ListViewProps = GetStyledProps<typeof ListView['Root']> & {
  searchProps?: GetStyledProps<typeof ListView['Search']>;
  filters?: ReactNode;
  hiddenHead?: boolean;
  hasFilters?: boolean;
};

export default function ListView({
  searchProps,
  filters,
  hasFilters,
  children,
  hiddenHead,
  ...restProps
}: ListViewProps) {
  const { isNotDesktop } = useResponsive();
  const [isFiltersModal, setFiltersModal] = useState(false);
  return (
    <ListView.Root {...restProps}>
      <ListView.Main>
        {!hiddenHead && (
          <ListView.Head>
            <ListView.Search {...searchProps} />
            {isNotDesktop && filters && (
              <FiltersButton
                active={hasFilters}
                onClick={() => setFiltersModal(true)}
              />
            )}
          </ListView.Head>
        )}
        {children}
      </ListView.Main>
      {filters &&
        (isNotDesktop ? (
          <CardModal
            isOpen={isFiltersModal}
            onRequestClose={() => setFiltersModal(false)}
          >
            {filters}
          </CardModal>
        ) : (
          <ListView.Aside>{filters}</ListView.Aside>
        ))}
    </ListView.Root>
  );
}

ListView.Root = styled.div`
  display: flex;
`;
ListView.Main = styled.div`
  flex: 1;
  max-width: 100%;
`;

ListView.Search = styled(SearchInput)`
  flex: 1;
`;

ListView.Head = styled.div`
  display: flex;
  margin-bottom: 32px;

  & > * + * {
    margin-left: 16px;
  }

  ${ListView.Search} {
    flex: 1;
  }
`;
ListView.Aside = styled.aside`
  margin-left: ${asideMargin}px;
  flex-basis: ${asideWidth}px;
`;

import { useMemo } from 'react';
import { createContainer } from 'unstated-next';

export function useIsIos() {
  const isIos = useMemo(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }, []);

  const isInStandaloneMode = useMemo(() => {
    if (('standalone' in window.navigator) as boolean) return true;
    return false;
  }, []);

  // TODO: доработать
  const isSafariBrowser = useMemo(() => {
    return window.navigator.userAgent.indexOf('Safari') !== -1;
  }, []);

  return { isIos, isInStandaloneMode, isSafariBrowser };
}

export const IsIosStore = createContainer(useIsIos);

import Card from 'components/Card';
import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import styled, { css } from 'styled-components/macro';
import { mq } from 'styles/theme';
import { ReactComponent as Checkmark } from './check.svg';

type BingoCardProps = Omit<GetStyledProps<typeof BingoCard['Root']>, 'done'> & {
  bingo: schema['BingoList'];
};

export default function BingoCard({ bingo, ...restProps }: BingoCardProps) {
  const { isNotDesktop } = useResponsive();
  const thumbnail = (
    <BingoCard.Thumbnail
      src={
        bingo.done ? bingo.thumbnail_hover || bingo.thumbnail : bingo.thumbnail
      }
      alt={bingo.name}
    />
  );
  return (
    <BingoCard.Root done={bingo.done} {...restProps}>
      {isNotDesktop ? (
        <>
          {thumbnail}
          <BingoCard.Name variant="medium">{bingo.name}</BingoCard.Name>
          <BingoCard.Check>{bingo.done && <Checkmark />}</BingoCard.Check>
        </>
      ) : (
        <>
          <BingoCard.Head>
            {thumbnail}
            <BingoCard.Check>{bingo.done && <Checkmark />}</BingoCard.Check>
          </BingoCard.Head>
          <BingoCard.Name variant="medium">{bingo.name}</BingoCard.Name>
        </>
      )}
    </BingoCard.Root>
  );
}

BingoCard.Thumbnail = styled.img`
  object-fit: contain;
  max-height: 100%;
  width: 70px;
  height: 90px;
`;
BingoCard.Head = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 18px;
`;
BingoCard.Check = styled.div`
  background: ${(props) => props.theme.colors.backgroundPrimary};
  width: 40px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

BingoCard.Name = styled(Typography)``;

BingoCard.Root = styled(Card).attrs({ as: 'button' })<{ done: boolean }>`
  background: ${(props) => props.theme.colors.backgroundAccent};
  padding: 16px 16px 32px 32px;

  ${(props) =>
    props.done
      ? css`
          background: ${props.theme.colors.green};

          ${BingoCard.Name} {
            color: ${props.theme.colors.textLight};
          }
        `
      : css`
          &:hover {
            background: #a1e5e8;
          }
        `}

  @media ${mq.notDesktop} {
    display: flex;
    align-items: center;
    padding: 16px;

    ${BingoCard.Name} {
      flex: 1;
      margin: 0 16px;
    }

    ${BingoCard.Thumbnail} {
      width: 40px;
      height: 50px;
    }
  }
`;

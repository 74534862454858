import TrainingsModal from 'components/TrainingsModal';
import moment from 'moment';
import { ComponentProps } from 'react';

type ScheduleTrainingModalProps = ComponentProps<typeof TrainingsModal> & {
  date?: moment.Moment;
};

export default function ScheduleTrainingModal({
  date,
  ...restProps
}: ScheduleTrainingModalProps) {
  return (
    <TrainingsModal
      title={`Выберите тренировку\nна ${date?.format('DD.MM.YYYY')}`}
      {...restProps}
    />
  );
}

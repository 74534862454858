import Chip from 'components/Chip';
import Container from 'components/Container';
import FabAdd from 'components/FabAdd';
import Filters from 'components/Filters';
import FormField from 'components/FormField';
import List from 'components/List';
import ListAction from 'components/ListAction';
import ListItem from 'components/ListItem';
import ListView from 'components/ListView';
import OrderBy from 'components/OrderBy';
import plural from 'plural-ru';
import {
  schema,
  useCloneTraining,
  useCreateSchedule,
  useDeleteTraining,
  useLevels,
  useSkills,
  useTraining,
  useTrainings,
} from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as PlusLargeIcon } from 'assets/icons/plus_large.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as EditLargeIcon } from 'assets/icons/edit_large.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import TrainingListItem from 'components/TrainingListItem';
import ListItemActions from 'components/ListItemActions';
import styled from 'styled-components/macro';
import ConfirmationModal from 'components/ConfirmationModal';
import SelectGroupModal from 'components/SelectGroupModal';
import CalendarPickerModal from 'components/CalendarPickerModal';
import moment from 'moment';
import { toApiDate } from 'utils/date';
import Button from 'components/Button';
import Typography from 'components/Typography';

export default function TrainingsFragment() {
  const skills = useSkills();
  const levels = useLevels();
  const [search, setSearch] = useState('');
  const [filtersData, setFiltersData] = useState<{
    level?: Array<schema['Level']['id']>;
    skill?: Array<schema['Skill']['id']>;
  }>();
  const filters = useMemo(
    () => (
      <Filters onClear={() => setFiltersData(undefined)}>
        <FormField label={<Typography>По навыкам</Typography>}>
          <Filters.Chips>
            {skills?.map((item) => {
              const isActive = filtersData?.skill?.includes(item.id);
              return (
                <Chip
                  active={isActive}
                  onClick={() =>
                    setFiltersData((prev) => ({
                      ...prev,
                      skill: isActive
                        ? prev?.skill?.filter((i) => i !== item.id)
                        : [...(prev?.skill || []), item.id],
                    }))
                  }
                  key={item.id}
                >
                  {item.name}
                </Chip>
              );
            })}
          </Filters.Chips>
        </FormField>
        <FormField label={<Typography>По уровню</Typography>}>
          <Filters.Chips>
            {levels?.map((item) => {
              const isActive = filtersData?.level?.includes(item.id);
              return (
                <Chip
                  active={isActive}
                  onClick={() =>
                    setFiltersData((prev) => ({
                      ...prev,
                      level: isActive
                        ? prev?.level?.filter((i) => i !== item.id)
                        : [...(prev?.level || []), item.id],
                    }))
                  }
                  key={item.id}
                >
                  {item.name}
                </Chip>
              );
            })}
          </Filters.Chips>
        </FormField>
      </Filters>
    ),
    [filtersData?.level, filtersData?.skill, levels, skills]
  );
  const [ordering, setOrdering] = useState('-created_at');
  const orderingOptions = useMemo(
    () => [
      // See generated-schema
      { label: 'Новые', value: '-created_at' },
      { label: 'Старые', value: 'created_at' },
      { label: 'Продолжительные', value: '-duration' },
      { label: 'Короткие', value: 'duration' },
    ],
    []
  );
  const params = useMemo(
    () => ({
      search,
      ordering,
      ...filtersData,
    }),
    [filtersData, ordering, search]
  );
  const debouncedParams = useDebouncedValue(params);
  const trainings = useTrainings(debouncedParams);
  const deleteTraining = useDeleteTraining();
  const cloneTraining = useCloneTraining();
  const { isNotDesktop, isMobile } = useResponsive();
  const history = useHistory();
  const [scheduleState, setScheduleState] = useState<{
    training: schema['TrainingList']['id'];
    group?: schema['TrainerGroupList']['id'];
  }>();
  const [deleteId, setDeleteId] = useState<schema['Training']['id']>();
  const deleteTrainingInfo = useTraining(deleteId);
  const createSchedule = useCreateSchedule();
  return (
    <TrainingsFragment.Root>
      <ListView
        searchProps={{
          placeholder: 'Поиск по названию',
          value: search,
          onChange: (event) => setSearch(event.target.value),
        }}
        filters={filters}
        hasFilters={!!filtersData}
      >
        <List>
          <TrainingsFragment.Subheader
            action={
              <OrderBy
                options={orderingOptions}
                value={ordering}
                onChange={setOrdering}
              />
            }
            divider={false}
          >
            {!isMobile && (
              <ListAction
                forwardedAs={Link}
                to="/materials/trainings/create"
                leading={<PlusIcon />}
              >
                Создать тренировку
              </ListAction>
            )}
          </TrainingsFragment.Subheader>
          {isMobile && <FabAdd as={Link} to="/materials/trainings/create" />}
          {trainings.data?.results?.map((item) => (
            <TrainingListItem
              button
              forwardedAs={Link}
              to={`/materials/trainings/${item.id}`}
              key={item.id}
              training={item}
              action={
                <ListItemActions
                  menu={isNotDesktop}
                  actions={[
                    {
                      title: 'Добавить в расписание',
                      icon: <PlusLargeIcon />,
                      onClick: () =>
                        setScheduleState({
                          training: item.id,
                        }),
                    },
                    {
                      title: 'Дублировать',
                      icon: <CopyIcon />,
                      onClick: () =>
                        cloneTraining(item.id).then(() => {
                          trainings.revalidate();
                        }),
                    },
                    ...(!item.is_public
                      ? [
                          {
                            title: 'Редактировать',
                            icon: <EditLargeIcon />,
                            onClick: () =>
                              history.push(
                                `/materials/trainings/${item.id}/edit`
                              ),
                          },
                          {
                            title: 'Удалить',
                            icon: <DeleteIcon />,
                            onClick: () => setDeleteId(item.id),
                          },
                        ]
                      : []),
                  ]}
                />
              }
            />
          ))}
        </List>
      </ListView>

      <ConfirmationModal
        isOpen={!!deleteId}
        text="Ты точно хочешь удалить тренировку?"
        description={
          deleteTrainingInfo.data?.schedule_groups.length! > 0 && (
            <>
              Эта тренировка стоит в расписании{' '}
              <Button
                color="primary"
                variant="text"
                style={{ lineHeight: 'inherit' }}
                as="span"
              >
                {deleteTrainingInfo.data!.schedule_groups.length}{' '}
                {plural(
                  deleteTrainingInfo.data!.schedule_groups.length,
                  'группы',
                  'групп'
                )}
              </Button>
            </>
          )
        }
        confirmText="Удалить"
        cancelText="Не удалять"
        onRequestClose={() => setDeleteId(undefined)}
        onConfirm={() =>
          deleteTraining(deleteId!).then(() => {
            trainings.revalidate();
            setDeleteId(undefined);
          })
        }
      />

      <SelectGroupModal
        isOpen={!!scheduleState && !scheduleState.group}
        onRequestClose={() => setScheduleState(undefined)}
        onSubmit={(group) =>
          setScheduleState((prev) => ({
            ...prev!,
            group,
          }))
        }
      />

      <CalendarPickerModal
        isOpen={!!scheduleState && !!scheduleState.group}
        group={scheduleState?.group}
        onRequestClose={() => setScheduleState(undefined)}
        onSubmit={(date) => {
          createSchedule({
            training: scheduleState!.training,
            training_date: toApiDate(moment(date)),
            trainer_group: scheduleState!.group!,
          }).then(() => {
            setScheduleState(undefined);
          });
        }}
      />
    </TrainingsFragment.Root>
  );
}

TrainingsFragment.Root = styled(Container)`
  ${TrainingListItem.Root} {
    margin-bottom: 24px;
  }
  padding-bottom: 64px;
`;

TrainingsFragment.Subheader = styled(ListItem)`
  margin: 0 0 16px;
`;

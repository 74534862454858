import Typography from 'components/Typography';
import times from 'lodash/times';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';

type StepsProps = GetStyledProps<typeof Steps['Root']> & {
  current: number;
  count: number;
  label?: ReactNode;
};

export default function Steps({
  current,
  count,
  label,
  ...restProps
}: StepsProps) {
  return (
    <Steps.Root {...restProps}>
      {label && <Steps.Label>{label}</Steps.Label>}
      <Steps.Items>
        {times(count).map((_, i) => (
          <Steps.Item active={i < current} key={i} />
        ))}
      </Steps.Items>
    </Steps.Root>
  );
}

Steps.Items = styled.div`
  display: flex;
  align-items: center;
`;
Steps.Item = styled.div<{ active?: boolean }>`
  flex: 1;
  background: ${(props) => props.theme.colors.primaryLight};
  opacity: 0.2;
  height: 16px;
  border-radius: 3px;

  & + & {
    margin-left: 8px;
  }

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  ${(props) =>
    props.active &&
    css`
      opacity: 1;
    `}
`;

Steps.Label = styled(Typography).attrs({ variant: 'medium' })`
  margin-bottom: 13px;
`;
Steps.Root = styled.div`
  display: flex;
  flex-direction: column;
`;

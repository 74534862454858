import { useMemo, useEffect, useState } from 'react';
import Button from 'components/Button';
import MascotMessage from 'components/MascotMessage';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import shareSafariIcon from 'assets/icons/sharesafari.png';
import StickyTooltip from 'components/StickyTooltip';
import Typography from 'components/Typography';
import styled from 'styled-components/macro';
import { useStore } from 'store';
import useResponsive from 'hooks/useResponsive';
import { mq } from 'styles/theme';
import { ReactComponent as Icon } from './icon.svg';

type InstallMessageProps = GetStyledProps<typeof InstallMessage['Root']> & {
  background?: GetStyledProps<typeof InstallMessage['Content']>['background'];
  buttonProps?: GetStyledProps<typeof Button>;
  fullWidth?: boolean;
};

const timeShowTooltip = 15000;

export default function InstallMessage({
  background = 'primary',
  buttonProps,
  fullWidth,
}: InstallMessageProps) {
  const { install, ios } = useStore();
  const { isNotDesktop } = useResponsive();
  const [showInstallMessage, setShowInstallMessage] = useState<boolean>(false);
  const [hasIosTooltip, setHasIosTooltip] = useState<boolean>(false);
  const [showIosTooltip, setShowIosTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (install) {
      setShowInstallMessage(true);
      setHasIosTooltip(false);
    } else if (ios.isIos && ios.isInStandaloneMode) {
      setShowInstallMessage(true);
      setHasIosTooltip(true);
    } else {
      setShowInstallMessage(false);
      setHasIosTooltip(false);
    }
  }, [install, ios.isInStandaloneMode, ios.isIos]);

  useEffect(() => {
    if (showIosTooltip) {
      setTimeout(() => setShowIosTooltip(false), timeShowTooltip);
    }
  }, [showIosTooltip]);

  if (!showInstallMessage) return null;

  return (
    <InstallMessage.Root>
      <InstallMessage.Messages
        isNotDesktop={isNotDesktop}
        fullWidth={fullWidth}
      >
        <InstallMessage.Content background={background}>
          <Icon />
          <Typography>
            Добавьте иконку на экран для быстрого запуска сайта.
          </Typography>
          <Button
            onClick={hasIosTooltip ? () => setShowIosTooltip(true) : install}
            variant={'contained'}
            color="accent"
            leading={<PlusIcon />}
            {...buttonProps}
          >
            Добавить иконку
          </Button>
        </InstallMessage.Content>
      </InstallMessage.Messages>
      {showIosTooltip && (
        <StickyTooltip
          title={`Установите приложение на Iphone: нажмите
        <img src=${shareSafariIcon} alt="share" />, затем нажмите На экран
        «Домой»`}
        />
      )}
    </InstallMessage.Root>
  );
}

InstallMessage.Content = styled.div<{
  background?: 'primary' | 'accent';
}>`
  ${(props) => {
    switch (props.background || 'primary') {
      case 'primary':
        return `background: ${props.theme.colors.backgroundPrimary};`;
      case 'accent':
        return `background: ${props.theme.colors.backgroundAccent};
        padding: 16px;
        border-radius: 15px 15px 15px 1px;`;
    }
  }}
  & > ${Typography} {
    margin: 8px 0 14px;
  }
`;

InstallMessage.Messages = styled(MascotMessage).attrs((props) => ({
  hasNotAvatar: !props.isNotDesktop,
  fullWidth: props.fullWidth,
}))`
  @media ${mq.tablet} {
    ${InstallMessage.Content} {
      display: flex;
      align-items: center;

      & > ${Typography} {
        flex: 1;
        margin: 0 24px 0 15px;
      }
    }
  }

  @media ${mq.mobile} {
    ${MascotMessage.Message} {
      padding: 16px 8px;
    }
  }
`;

InstallMessage.Root = styled.div`
  display: block;
`;

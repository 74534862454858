import { css, keyframes } from 'styled-components/macro';

export function ibm(weight: number) {
  return css`
    font-family: IBM Plex Sans, sans-serif;
    font-weight: ${weight};
  `;
}

export function rem(size: number, base = 16) {
  return `${size / base}rem`;
}

export function resetButton() {
  return `
    background: none;
    cursor: pointer;
    border: none;
    text-align: inherit;
    padding: 0;
  `;
}

const opacityPulseFrames = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
`;

export function opacityPulse(duration = '2s', iteration = 'infinite') {
  return css`
    animation: ${opacityPulseFrames} ${duration} ease-in-out;
    animation-iteration-count: ${iteration};
  `;
}

const opacityAnimationFrames = keyframes`
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
`;

export function opacityAnimation(duration = '0.4s') {
  return css`
    animation: ${opacityAnimationFrames} ${duration} ease-in-out;
  `;
}

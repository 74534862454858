import Typography from 'components/Typography';
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components/macro';

const Label = styled((props: ComponentPropsWithoutRef<'label'>) => (
  <Typography as="label" {...props} />
))`
  opacity: 0.8;
`;

export default Label;

import Card from 'components/Card';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

type TrainerActionCardProps = GetStyledProps<
  typeof TrainerActionCard['Root']
> & {
  icon?: React.ReactNode;
};

export default function TrainerActionCard({
  children,
  icon,
  ...restProps
}: TrainerActionCardProps) {
  return (
    <TrainerActionCard.Root {...restProps}>
      <TrainerActionCard.Icon>{icon}</TrainerActionCard.Icon>
      {children}
    </TrainerActionCard.Root>
  );
}

TrainerActionCard.Icon = styled.div`
  position: absolute;
  left: 32px;
  top: 0;
  transform: translateY(-50%);
`;

TrainerActionCard.Root = styled(Card)`
  padding: 48px 30px 32px 32px;
  position: relative;

  @media ${mq.notDesktop} {
    padding: 48px 32px 35px;

    ${TrainerActionCard.Icon} {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

import Typography from 'components/Typography';
import { Children } from 'react';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import { ReactComponent as MascotAvatar } from './mascot_avatar.svg';

type MascotMessageProps = GetStyledProps<typeof MascotMessage['Root']> & {
  background?: GetStyledProps<typeof MascotMessage['Message']>['background'];
  avatarProps?: GetStyledProps<typeof MascotMessage['Avatar']>;
  hasNotAvatar?: boolean;
  fullWidth?: boolean;
};

export default function MascotMessage({
  background,
  children,
  avatarProps,
  hasNotAvatar,
  fullWidth,
  ...restProps
}: MascotMessageProps) {
  const messages = Children.toArray(children);
  return (
    <MascotMessage.Root {...restProps}>
      {!hasNotAvatar && <MascotMessage.Avatar {...avatarProps} />}
      <MascotMessage.Messages fullWidth={fullWidth}>
        {messages.map((message, key) => (
          <MascotMessage.Message key={key} background={background}>
            {message}
          </MascotMessage.Message>
        ))}
      </MascotMessage.Messages>
    </MascotMessage.Root>
  );
}

MascotMessage.Avatar = styled(MascotAvatar)`
  flex-shrink: 0;
`;

MascotMessage.Message = styled(Typography)<{
  background?: 'primary' | 'accent';
}>`
  padding: 16px;
  border-radius: 15px 15px 15px 1px;
  background: ${(props) => {
    switch (props.background || 'primary') {
      case 'primary':
        return props.theme.colors.backgroundPrimary;
      case 'accent':
        return props.theme.colors.backgroundAccent;
    }
  }};
`;

MascotMessage.Root = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 20px;
  }

  @media ${mq.notDesktop} {
    & > * + * {
      margin-left: 13px;
    }

    ${MascotMessage.Avatar} {
      max-width: 60px;
    }
  }
`;

MascotMessage.Messages = styled.div<{
  fullWidth?: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${(props) => {
    if (props.fullWidth) return `width: 100%;`;
  }}

  & > * + * {
    margin-top: 28px;
  }
`;

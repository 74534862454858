import Button from 'components/Button';
import styled, { css } from 'styled-components/macro';
import { ibm } from 'styles/mixins';

const Chip = styled(Button).attrs({ variant: 'outlined' })<{
  active?: boolean;
}>`
  ${ibm(400)}
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textPrimary};
  padding: 0 14px;
  min-height: 40px;

  ${(props) =>
    props.active &&
    css`
      background: ${props.theme.colors.primary};
      color: ${props.theme.colors.textLight};
    `}
`;

export default Chip;

import IconButton from 'components/IconButton';
import styled from 'styled-components/macro';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const CloseButton = styled((props: GetStyledProps<typeof IconButton>) => (
  <IconButton {...props}>
    <CloseIcon />
  </IconButton>
)).attrs({
  color: 'primary',
})`
  &:hover {
    svg {
      circle {
        fill: currentColor;
      }

      rect {
        fill: ${(props) => props.theme.colors.getContrast(props.color)};
      }
    }
  }
`;

export default CloseButton;

import Button from 'components/Button';
import ListItem from 'components/ListItem';
import Typography from 'components/Typography';
import moment from 'moment';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as PlusLargeIcon } from 'assets/icons/plus_large.svg';
import { useMemo } from 'react';
import TrainingListItem from 'components/TrainingListItem';
import ListItemActions from 'components/ListItemActions';
import useResponsive from 'hooks/useResponsive';
import IconButton from 'components/IconButton';
import { schema, useScheduleMaxOnDay } from 'hooks/data';
import styled, { css } from 'styled-components/macro';
import { mq } from 'styles/theme';

type CalendarListItemProps = {
  item: moment.Moment;
  schedules?: Array<schema['TrainingsScheduleItemDetail']>;
  onDelete?: (schedule: schema['TrainingsScheduleItemDetail']) => any;
  onMove?: (schedule: schema['TrainingsScheduleItemDetail']) => any;
  onUpdateTraining?: (schedule: schema['TrainingsScheduleItemDetail']) => any;
  onAddTraining?: (item: moment.Moment) => any;
};

export default function CalendarListItem({
  item,
  onDelete,
  schedules,
  onUpdateTraining,
  onMove,
  onAddTraining,
}: CalendarListItemProps) {
  const maxOnDay = useScheduleMaxOnDay();
  const { isNotDesktop } = useResponsive();
  const isWeekend = useMemo(() => [6, 7].includes(item.isoWeekday()), [item]);
  const hasTrainings = schedules?.length! > 0;
  const canAddMore = schedules?.length! < maxOnDay.data?.schedule_max_on_day!;
  return (
    <CalendarListItem.Root
      weekend={isWeekend}
      control={
        <Typography>
          {item.format(isNotDesktop ? 'D, ddd' : 'D, dddd')}
        </Typography>
      }
    >
      <CalendarListItem.Main>
        {schedules?.map((schedule) => (
          <TrainingListItem
            forwardedAs="div"
            key={schedule.id}
            training={schedule.training}
            action={
              <ListItemActions
                menu
                actions={[
                  {
                    title: 'Изменить дату',
                    icon: null,
                    onClick: () => onMove?.(schedule),
                  },
                  {
                    title: 'Заменить тренировку на другую',
                    icon: null,
                    onClick: () => onUpdateTraining?.(schedule),
                  },
                  {
                    title: 'Убрать из расписания',
                    icon: null,
                    onClick: () => onDelete?.(schedule),
                  },
                  ...(canAddMore
                    ? [
                        {
                          title: 'Добавить еще одну тренировку',
                          icon: null,
                          onClick: () => onAddTraining?.(item),
                        },
                      ]
                    : []),
                ]}
              />
            }
          />
        ))}
        {!hasTrainings &&
          (isNotDesktop ? (
            <CalendarListItem.AddTraining
              as={IconButton}
              color="primary"
              onClick={() => onAddTraining?.(item)}
            >
              <PlusLargeIcon />
            </CalendarListItem.AddTraining>
          ) : (
            <CalendarListItem.AddTraining
              color="accent"
              variant="contained"
              onClick={() => onAddTraining?.(item)}
              leading={<PlusIcon />}
            >
              Добавить тренировку
            </CalendarListItem.AddTraining>
          ))}
      </CalendarListItem.Main>
    </CalendarListItem.Root>
  );
}

CalendarListItem.Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

CalendarListItem.AddTraining = styled(Button)`
  align-self: flex-end;
  visibility: hidden;
`;

CalendarListItem.Root = styled(ListItem)<{ weekend?: boolean }>`
  padding: 20px 40px 20px 20px;
  display: grid;
  grid-template-columns: 200px 1fr;

  ${(props) =>
    props.weekend &&
    css`
      background: ${props.theme.colors.violet};
    `}

  ${ListItem.Content} {
    overflow: visible;
  }

  ${TrainingListItem.Root} {
    background: inherit;
    padding: 0;
  }

  &:hover {
    ${CalendarListItem.AddTraining} {
      visibility: visible;
    }
  }

  @media ${mq.notDesktop} {
    grid-template-columns: 100px 1fr;
    padding: 16px 8px;

    ${TrainingListItem.Root} ${ListItem.Content} {
      flex-direction: column;
      align-items: flex-start;
    }

    ${CalendarListItem.AddTraining} {
      visibility: visible;
    }
  }
`;

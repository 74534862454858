import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useState } from 'react';
import Typography from 'components/Typography';
import List from 'components/List';
import Button from 'components/Button';
import { mq } from 'styles/theme';
import { Link, useRouteMatch } from 'react-router-dom';
import { useGroupStudents } from 'hooks/data';
import styled from 'styled-components/macro';
import Container from 'components/Container';
import ProgressPeriodToggle, {
  ProgressPeriod,
} from 'components/ProgressPeriodToggle';
import ProgressStudentListItem from 'components/ProgressStudentListItem';

export default function StudentsFragment() {
  const [period, setPeriod] = useState(ProgressPeriod.WEEK);
  const match = useRouteMatch<{ id: string }>();
  const id = Number(match.params.id);
  const students = useGroupStudents(id);
  return (
    <StudentsFragment.Root>
      <StudentsFragment.Head>
        <Typography variant="h2">Прогресс группы</Typography>
        <ProgressPeriodToggle value={period} onChange={setPeriod} />
      </StudentsFragment.Head>
      <StudentsFragment.List>
        {students.data?.results?.map((item) => (
          <ProgressStudentListItem
            forwardedAs={Link}
            to={`/students/${item.id}`}
            button
            key={item.id}
            student={item}
            progress={item.progress?.[period]}
          />
        ))}
      </StudentsFragment.List>
      <Button
        as={Link}
        to={`/students/groups/${id}/edit`}
        variant="outlined"
        color="accent"
        leading={<EditIcon />}
      >
        Редактировать группу
      </Button>
    </StudentsFragment.Root>
  );
}

StudentsFragment.Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

StudentsFragment.List = styled(List)`
  margin-bottom: 48px;
`;

StudentsFragment.Root = styled(Container)`
  @media ${mq.mobile} {
    & > ${Button.Root} {
      width: 100%;
    }
    ${StudentsFragment.Head} {
      flex-direction: column;
      align-items: stretch;
      & > * + * {
        margin-top: 25px;
      }
    }
  }
`;

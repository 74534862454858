import { useState } from 'react';
import { createContainer } from 'unstated-next';

export function useTrainingVideo() {
  const [state, setState] = useState<{
    training: number;
    schedule?: number;
  }>();
  return {
    state,
    setState,
  };
}

export const TrainingVideoStore = createContainer(useTrainingVideo);

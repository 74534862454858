import CardModal from 'components/CardModal';
import Typography from 'components/Typography';
import styled from 'styled-components/macro';

type ActionsModalProps = GetStyledProps<typeof ActionsModal['Root']> & {
  actions: Array<{ title: string; onClick?: () => any }>;
};

export default function ActionsModal({
  actions,
  ...restProps
}: ActionsModalProps) {
  return (
    <ActionsModal.Root {...restProps}>
      {actions.map((action, key) => (
        <ActionsModal.Action key={key} onClick={action.onClick}>
          {action.title}
        </ActionsModal.Action>
      ))}
    </ActionsModal.Root>
  );
}

ActionsModal.Action = styled(Typography).attrs({
  as: 'button',
  variant: 'medium',
})`
  padding: 8px 0 23px;
  & + & {
    border-top: 1px solid
      ${(props) => props.theme.colors.backgroundAccentAlternative};
  }
`;
ActionsModal.Root = styled(CardModal)`
  .content {
    display: flex;
    flex-direction: column;
  }
`;

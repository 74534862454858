export const breakpoints = {
  mobile: 0,
  tablet: 768,
  desktop: 1024,
  desktopLg: 1280,
  desktopXl: 1500,
  desktopXxl: 1920,
};

function createMq() {
  const gap = 0.01;
  const desktop = `(min-width: ${breakpoints.desktop}px)`;
  const notDesktop = `(max-width: ${breakpoints.desktop - gap}px)`;
  const tablet = `(min-width: ${breakpoints.tablet}px) and ${notDesktop}`;
  const mobile = `(max-width: ${breakpoints.tablet - gap}px)`;
  const desktopLg = `(min-width: ${breakpoints.desktopLg}px)`;
  const notDesktopLg = `(max-width: ${breakpoints.desktopLg - gap}px)`;
  const desktopXl = `(min-width: ${breakpoints.desktopXl}px)`;
  const notDesktopXl = `(max-width: ${breakpoints.desktopXl - gap}px)`;
  const desktopXxl = `(min-width: ${breakpoints.desktopXxl}px)`;
  const notDesktopXxl = `(max-width: ${breakpoints.desktopXxl - gap}px)`;
  return {
    gap,
    desktop,
    notDesktop,
    tablet,
    mobile,
    desktopLg,
    notDesktopLg,
    desktopXl,
    notDesktopXl,
    desktopXxl,
    notDesktopXxl,
  };
}

export const mq = createMq();

const theme = {
  colors: {
    backgroundPrimary: '#FFFFFF',
    backgroundAccent: '#E2F0F6',
    backgroundAccentAlternative: '#bad9ea',
    textPrimary: '#212121',
    textPrimaryDark: '#000000',
    textLight: '#FFFFFF',
    textAccent: '#29d3ce',
    primary: '#00969F',
    primaryLight: '#09CAC7',
    accent: '#FF5520',
    accentDark: '#FD3C00',
    surfaceError: '#ffe3e5',
    modalOverlay: 'rgba(0, 0, 0, 0.5)',
    error: '#ff002c',
    violet: 'rgba(212, 210, 255, 0.4)',
    gray3: '#828282',
    green: '#2CC16B',
    disabled: '#d9d9d9',
    get(value?: string): string {
      switch (value) {
        case 'primary':
          return this.primary;
        case 'accent':
          return this.accent;
        default:
          return 'inherit';
      }
    },
    getContrast(value?: string): string {
      switch (value) {
        case 'primary':
        case 'accent':
          return this.textLight;
        default:
          return 'inherit';
      }
    },
  },
  header: {
    height: 72,
  },
  bottomNavigation: {
    height: 80,
    dividerColor: '#c7e3f3',
  },
  container: {
    lg: 1120,
  },
  breakpoints,
  mq,
  zIndex: {
    header: 100,
    fab: 200,
    modal: 1000,
    notifications: 1100,
  },
};

export type Theme = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}

export default theme;

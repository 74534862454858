import ButtonToggle from 'components/ButtonToggle';
import { UserGender } from 'utils/user';
import { ComponentProps, useMemo } from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as FemaleIcon } from './female.svg';
import { ReactComponent as FemaleActiveIcon } from './female_active.svg';
import { ReactComponent as MaleIcon } from './male.svg';
import { ReactComponent as MaleActiveIcon } from './male_active.svg';

type GenderToggleProps = ComponentProps<typeof ButtonToggle>;

export default function GenderToggle(props: GenderToggleProps) {
  const items = useMemo<GenderToggleProps['items']>(
    () => [
      {
        value: UserGender.FEMALE,
        renderTitle: ({ active }) => (
          <>
            <GenderToggle.Icon as={active ? FemaleActiveIcon : FemaleIcon} />
            Женский
          </>
        ),
      },
      {
        value: UserGender.MALE,
        renderTitle: ({ active }) => (
          <>
            <GenderToggle.Icon as={active ? MaleActiveIcon : MaleIcon} />
            Мужской
          </>
        ),
      },
    ],
    []
  );
  return <ButtonToggle items={items} {...props} />;
}

GenderToggle.Icon = styled.svg`
  margin-right: 11px;
`;

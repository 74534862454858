import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import { ComponentProps } from 'react';
import styled from 'styled-components/macro';

type ExerciseTypeProps = ComponentProps<'div'> & {
  exerciseType: schema['ExerciseType'];
};

export default function ExerciseType({
  exerciseType,
  children,
  ...restProps
}: ExerciseTypeProps) {
  return (
    <div {...restProps}>
      <ExerciseType.Header>{exerciseType.name}</ExerciseType.Header>
      {children}
    </div>
  );
}

ExerciseType.Header = styled(Typography).attrs({ variant: 'h3' })`
  margin-bottom: 17px;
`;

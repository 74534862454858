import Details from 'components/Details';
import ListItem from 'components/ListItem';
import SkillImage from 'components/SkillImage';
import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import styled, { css } from 'styled-components/macro';
import { mq } from 'styles/theme';

type TrainingListItemProps = GetStyledProps<typeof TrainingListItem['Root']> & {
  training: schema['TrainingList'];
};

export default function TrainingListItem({
  training,
  ...restProps
}: TrainingListItemProps) {
  return (
    <TrainingListItem.Root {...restProps}>
      <TrainingListItem.Image skill={training.skill} level={training.level} />
      <TrainingListItem.Main>
        <TrainingListItem.Name>{training.name}</TrainingListItem.Name>
        <TrainingListItem.Details>
          <div>{training.duration} мин.</div>
          <div>{training.skill.name}</div>
          <div>Уровень {training.level.name}</div>
        </TrainingListItem.Details>
      </TrainingListItem.Main>
    </TrainingListItem.Root>
  );
}

TrainingListItem.Image = styled(SkillImage)`
  margin-right: 14px;
  width: 100px;
`;
TrainingListItem.Name = styled(Typography)``;
TrainingListItem.Details = styled(Details).attrs({ variant: 'details' })``;
TrainingListItem.Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 22px;
`;

TrainingListItem.Root = styled(ListItem).attrs({ divider: false })<{
  button?: boolean;
}>`
  background: rgba(226, 240, 247, 0.5);
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 10px;
  padding-right: 24px;

  ${(props) =>
    props.button &&
    css`
      &:hover {
        background: #e2f0f7;

        ${TrainingListItem.Name} {
          color: ${props.theme.colors.primary};
          font-weight: 500;
        }
      }
    `}

  @media ${mq.notDesktop} {
    ${TrainingListItem.Image} {
      margin-right: 4px;
    }
  }
`;

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Chip from 'components/Chip';
import Filters from 'components/Filters';
import FormField from 'components/FormField';
import InputRange from 'components/InputRange';
import { Link } from 'react-router-dom';
import List from 'components/List';
import ListAction from 'components/ListAction';
import ListItem from 'components/ListItem';
import ListView from 'components/ListView';
import Typography from 'components/Typography';
import { schema, useLevels, useStudentsAge } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import plural from 'plural-ru';
import { ComponentProps, ComponentType, useMemo } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as PlusLargeIcon } from 'assets/icons/plus_large.svg';
import { ReactComponent as MoveIcon } from 'assets/icons/move.svg';
import { Range } from 'react-input-range';
import styled from 'styled-components/macro';
import StudentListItem from 'components/StudentListItem';
import ListItemActions from 'components/ListItemActions';

type StudentsListViewProps = Pick<
  ComponentProps<typeof ListView>,
  'className' | 'hiddenHead'
> & {
  params?: ComponentProps<typeof StudentsFilters>['data'] & {
    search?: string;
  };
  onParams?: (params: StudentsListViewProps['params']) => any;
  selected?: Array<schema['StudentList']['id']>;
  onSelected?: (selected: StudentsListViewProps['selected']) => any;
  list?: schema['PaginatedStudentListList'];
  itemComponent?: ComponentType<ComponentProps<typeof StudentListItem>>;
  onMove?: (state: Array<schema['StudentList']['id']>) => any;
  onCreateGroup?: () => any;
  itemProps?: (
    student: schema['StudentList']
  ) => Partial<ComponentProps<typeof StudentListItem>>;
};

export default function StudentsListView({
  params,
  onParams,
  selected = [],
  onSelected,
  list,
  onMove,
  onCreateGroup,
  itemComponent: ItemComponent = StudentListItem,
  itemProps,
  ...restProps
}: StudentsListViewProps) {
  const { isNotDesktop } = useResponsive();
  const isAllSelected = useMemo(
    () =>
      new Set([
        ...selected,
        ...(list?.results?.map((student) => student.id) || []),
      ]).size === (selected || []).length,
    [list?.results, selected]
  );
  const hasFilters = useMemo(() => {
    const { search, ...rest } = params || {};
    return Object.keys(rest).length > 0;
  }, [params]);
  return (
    <ListView
      searchProps={{
        placeholder: isNotDesktop ? 'Поиск' : 'Поиск по имени или фамилии',
        value: params?.search,
        onChange: (event) =>
          onParams?.({
            ...params,
            search: event.target.value,
          }),
      }}
      hasFilters={hasFilters}
      filters={
        params && (
          <StudentsFilters
            data={params}
            onChange={(data) =>
              onParams?.({
                search: params.search,
                ...data,
              })
            }
          />
        )
      }
      {...restProps}
    >
      <List>
        <StudentsListView.Subheader
          control={
            <Checkbox
              checked={isAllSelected}
              onChange={() => {
                onSelected?.(
                  isAllSelected
                    ? []
                    : list?.results?.map((student) => student.id) || []
                );
              }}
            />
          }
        >
          {selected?.length! > 0 && (
            <>
              {onMove && (
                <ListAction
                  leading={<MoveIcon />}
                  onClick={() => onMove(selected!)}
                >
                  {isNotDesktop ? 'Перенести' : 'Перенести в группу'}
                </ListAction>
              )}
              {onCreateGroup && (
                <ListAction leading={<PlusIcon />} onClick={onCreateGroup}>
                  {isNotDesktop ? 'Создать' : 'Создать новую группу'}
                </ListAction>
              )}
            </>
          )}
          {list && (
            <StudentsListView.SubheaderCount>
              {list?.count}{' '}
              {plural(list?.count!, 'человек', 'человека', 'человек')}
            </StudentsListView.SubheaderCount>
          )}
        </StudentsListView.Subheader>
        {list?.results?.map((student) => (
          <ItemComponent
            key={student.id}
            student={student}
            forwardedAs="label"
            button
            control={
              <Checkbox
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onChange={(event) => {
                  onSelected?.(
                    event.target.checked
                      ? [...selected, student.id]
                      : selected.filter((id) => id !== student.id)
                  );
                }}
                checked={selected.includes(student.id)}
              />
            }
            action={
              <ListItemActions
                actions={[
                  ...(onMove && !isNotDesktop
                    ? [
                        {
                          title: 'Добавить в группу',
                          onClick: () => onMove([student.id]),
                          icon: <PlusLargeIcon />,
                        },
                      ]
                    : []),
                ]}
              />
            }
            {...itemProps?.(student)}
          />
        ))}
      </List>
      {/* TODO: сделать логику работы кнопки */}
      {/* <StudentsListView.Contaiener>
        <Button
          color="accent"
          variant="outlined"
          as={Link}
          to={'/students/add'}
          leading={<PlusIcon />}
        >
          Добавить ученика
        </Button>
      </StudentsListView.Contaiener> */}
    </ListView>
  );
}

StudentsListView.Subheader = styled(ListItem)`
  ${ListItem.Content} {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 16px;
    }
  }
`;

StudentsListView.SubheaderCount = styled(Typography)`
  flex: 1;
  text-align: right;
`;

StudentsListView.Contaiener = styled.div`
  margin-top: 46px;
`;

type StudentsFiltersProps = {
  data?: {
    age__gte?: number;
    age__lte?: number;
    level?: Array<schema['Level']['id']>;
    without_group?: number;
  };
  onChange?: (data: StudentsFiltersProps['data']) => any;
};

function StudentsFilters({ data, onChange }: StudentsFiltersProps) {
  const studentsAge = useStudentsAge();
  const levels = useLevels();
  const ageRange = useMemo(
    () => ({
      min: data?.age__gte || 0,
      max: data?.age__lte || studentsAge.data?.max_age || 0,
    }),
    [data?.age__gte, data?.age__lte, studentsAge.data?.max_age]
  );
  return (
    <Filters onClear={() => onChange?.(undefined)}>
      <FormField
        label={
          <Typography>
            По возрасту: {ageRange.min}–{ageRange.max}{' '}
            {plural(ageRange.max, 'год', 'лет')}
          </Typography>
        }
      >
        <InputRange
          value={ageRange}
          minValue={0}
          maxValue={studentsAge.data?.max_age || 0}
          onChange={(value: any) => {
            onChange?.({
              ...data,
              age__gte: (value as Range).min || 0,
              age__lte: (value as Range).max || 0,
            });
          }}
        />
      </FormField>
      <FormField label={<Typography>По уровню</Typography>}>
        <Filters.Chips>
          {levels?.map((level) => {
            const isActive = data?.level?.includes(level.id);
            return (
              <Chip
                active={isActive}
                onClick={() =>
                  onChange?.({
                    ...data,
                    level: isActive
                      ? data?.level?.filter((l) => l !== level.id)
                      : [...(data?.level || []), level.id],
                  })
                }
                key={level.id}
              >
                {level.name}
              </Chip>
            );
          })}
        </Filters.Chips>
      </FormField>
      <FormField>
        <Checkbox
          checked={!!data?.without_group}
          onChange={(e) =>
            onChange?.({
              ...data,
              without_group: e.target.checked ? 1 : undefined,
            })
          }
          label="Только ребята без группы"
        />
      </FormField>
    </Filters>
  );
}

import Button from 'components/Button';
import Container from 'components/Container';
import GroupListItem from 'components/GroupListItem';
import List from 'components/List';
import Typography from 'components/Typography';
import { useGroups } from 'hooks/data';
import { Link } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

export default function GroupsFragment() {
  const groups = useGroups();
  if (!groups.data) return null;
  return (
    <Container>
      {groups.data.results?.length! > 0 ? (
        <List>
          {groups.data?.results?.map((group) => (
            <GroupListItem
              forwardedAs={Link}
              to={`/students/groups/${group.id}`}
              button
              group={group}
              key={group.id}
            />
          ))}
        </List>
      ) : (
        <Typography>У вас пока нет групп.</Typography>
      )}
      <GroupsFragment.Create
        forwardedAs={Link}
        to="/students/groups/create"
        variant="outlined"
        color="accent"
        leading={<PlusIcon />}
      >
        Создать новую группу
      </GroupsFragment.Create>
    </Container>
  );
}

GroupsFragment.Create = styled(Button)`
  margin-top: 46px;

  @media ${mq.notDesktop} {
    width: 100%;
  }
`;

import Typography from 'components/Typography';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

type CheckboxProps = GetStyledProps<typeof Checkbox['Input']> & {
  label?: ReactNode;
};

export default function Checkbox({
  label,
  className,
  ...restProps
}: CheckboxProps) {
  return (
    <Checkbox.Root className={className}>
      <Checkbox.Input type="checkbox" {...restProps} />
      <Checkbox.Switcher />
      {label && <Checkbox.Label>{label}</Checkbox.Label>}
    </Checkbox.Root>
  );
}

Checkbox.Switcher = styled.i``;

Checkbox.Input = styled.input`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: auto;
  cursor: pointer;

  & + ${Checkbox.Switcher} {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box
  & + ${Checkbox.Switcher}:before {
    content: '';
    position: relative;
    display: inline-block;
    vertical-align: text-top;
    width: 24px;
    height: 24px;
    background: ${(props) => props.theme.colors.backgroundPrimary};
    border: 1px solid
      ${(props) => props.theme.colors.backgroundAccentAlternative};
    border-radius: 5px;
  }

  &:checked + ${Checkbox.Switcher}:before {
    background: ${(props) => props.theme.colors.primary};
  }

  &:checked + ${Checkbox.Switcher}:after {
    content: '';
    width: 8px;
    height: 12px;
    display: block;
    border: solid ${(props) => props.theme.colors.backgroundPrimary};
    border-width: 0 2px 2px 0;
    position: absolute;
    transform: translate(-50%, -50%) rotate(40deg);
    left: 50%;
    top: 50%;
  }
`;

Checkbox.Label = styled(Typography).attrs({ as: 'span' })`
  margin-left: 9px;
`;

Checkbox.Root = styled.label`
  position: relative;
  pointer-events: none;
`;

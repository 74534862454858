import IconButton from 'components/IconButton';
import styled from 'styled-components/macro';
import { ReactComponent as PlusIcon } from './plus.svg';

const FabAdd = styled(IconButton).attrs({
  children: <PlusIcon />,
})`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.fab};
  right: 15px;
  bottom: 114px;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.accent};
`;

export default FabAdd;

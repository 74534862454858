import styled from 'styled-components/macro';
import { getWhatsAppUrl } from 'utils/social';
import { ReactComponent as WhatsApp } from './whatsapp.svg';
import { ReactComponent as Vk } from './vk.svg';

type SocialProps = GetStyledProps<typeof Social['Root']> & {
  vk?: string | null;
  whatsapp?: string | null;
};

export default function Social({ vk, whatsapp, ...restProps }: SocialProps) {
  return (
    <Social.Root {...restProps}>
      {whatsapp && (
        <a href={getWhatsAppUrl(whatsapp)}>
          <WhatsApp />
        </a>
      )}
      {vk && (
        <a href={vk}>
          <Vk />
        </a>
      )}
    </Social.Root>
  );
}

Social.Root = styled.div`
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 16px;
  }
`;

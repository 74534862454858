import Button from 'components/Button';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';
import { ibm } from 'styles/mixins';

type ButtonToggleProps = Omit<
  GetStyledProps<typeof ButtonToggle['Root']>,
  'onChange'
> & {
  items?: Array<{
    renderTitle?: (props: { active: boolean }) => ReactNode;
    value?: any;
    props?: GetStyledProps<typeof ButtonToggle['Item']>;
  }>;
  value?: any;
  onChange?: (value: any) => any;
};

export default function ButtonToggle({
  items,
  value,
  onChange,
  ...restProps
}: ButtonToggleProps) {
  return (
    <ButtonToggle.Root {...restProps}>
      {items?.map((item, key) => {
        const active = item.value === value;
        return (
          <ButtonToggle.Item
            active={active}
            key={key}
            onClick={() => onChange?.(item.value)}
            {...item.props}
          >
            {item.renderTitle?.({ active })}
          </ButtonToggle.Item>
        );
      })}
    </ButtonToggle.Root>
  );
}

ButtonToggle.Root = styled.div`
  display: flex;
  max-width: 100%;
`;

ButtonToggle.Item = styled(Button).attrs<{ active: boolean }>((props) => ({
  color: 'primary',
  variant: props.active ? 'contained' : 'outlined',
  ignoreHover: true,
  ...props,
}))<{ active: boolean }>`
  flex: 1;
  border-width: 1px;
  padding: 0;

  ${(props) =>
    !props.active &&
    css`
      ${ibm(400)}
      color: ${props.theme.colors.textPrimary};

      &:hover {
        background: #d2f3f7;
      }
    `}

  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & + & {
    border-left-width: 0;
  }
`;

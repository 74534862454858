import Button from 'components/Button';
import Card from 'components/Card';
import Details from 'components/Details';
import SkillImage from 'components/SkillImage';
import Spacer from 'components/Spacer';
import Typography from 'components/Typography';
import { schema } from 'hooks/data';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import { formatTrainingDuration } from 'utils/format';

type TrainingCardProps = {
  training: schema['TrainingList'];
  onSelect?: () => any;
};

export default function TrainingCard({
  training,
  onSelect,
}: TrainingCardProps) {
  return (
    <TrainingCard.Root>
      <TrainingCard.Image
        background
        skill={training.skill}
        level={training.level}
      />
      <TrainingCard.Main>
        <TrainingCard.Name variant="h3">{training.name}</TrainingCard.Name>
        <TrainingCard.Details>
          <div>{formatTrainingDuration(training.duration)}</div>
          <div>{training.skill.name}</div>
          <div>Уровень {training.level.name}</div>
        </TrainingCard.Details>
        <Spacer />
        <Button onClick={onSelect} variant="contained" color="accent">
          Выбрать
        </Button>
      </TrainingCard.Main>
    </TrainingCard.Root>
  );
}

TrainingCard.Main = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
`;
TrainingCard.Name = styled(Typography)`
  margin-bottom: 8px;
`;
TrainingCard.Details = styled(Details)`
  opacity: 0.7;
  color: ${(props) => props.theme.colors.textPrimaryDark};
  margin-bottom: 22px;
`;

TrainingCard.Image = styled(SkillImage)`
  height: 200px;
  align-self: center;
  margin-left: 16px;
`;

TrainingCard.Root = styled(Card)`
  padding: 24px;
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;

  @media ${mq.mobile} {
    padding: 8px 24px 24px;
    flex-flow: column;

    ${TrainingCard.Image} {
      margin-left: 0;
      margin-bottom: 8px;
      align-self: stretch;
    }
  }
`;

import Layout from 'components/Layout';
import { Route, Switch, useRouteMatch } from 'react-router';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { Link } from 'react-router-dom';
import List from 'components/List';
import ExerciseListItem from 'components/ExerciseListItem';
import ExerciseType from 'components/ExerciseType';
import {
  useTraining,
  useExercisesTypes,
  schema,
  useCreateSchedule,
} from 'hooks/data';
import Loader from 'components/Loader';
import ErrorPage from 'pages/_error';
import styled from 'styled-components/macro';
import Container from 'components/Container';
import Details from 'components/Details';
import { formatTrainingDuration } from 'utils/format';
import Surface from 'components/Surface';
import GroupListItem from 'components/GroupListItem';
import useResponsive from 'hooks/useResponsive';
import TabLink from 'components/TabLink';
import TabsLayout from 'components/TabsLayout';
import { useState } from 'react';
import SelectGroupModal from 'components/SelectGroupModal';
import CalendarPickerModal from 'components/CalendarPickerModal';
import { toApiDate } from 'utils/date';
import moment from 'moment';
import { useStore } from 'store';
import useDataLayer from 'hooks/useDataLayer';

export default function TrainingPage() {
  const match = useRouteMatch<{ id: string; path: string; url: string }>();
  useDataLayer({ title: match.path, alias: match.url });
  const id = Number(match.params.id);
  const training = useTraining(id);
  const exercisesTypes = useExercisesTypes();
  const { trainingVideo } = useStore();
  const { isNotDesktop } = useResponsive();
  const [scheduleState, setScheduleState] = useState<{
    training: schema['TrainingList']['id'];
    group?: schema['TrainerGroupList']['id'];
  }>();
  const createSchedule = useCreateSchedule();

  if (training.error) {
    return <ErrorPage statusCode={training.error.status} />;
  }

  if (training.data) {
    const groupsNode = (
      <>
        <TrainingPage.GroupsSurface>
          {training.data.schedule_groups.length > 0 && (
            <>
              <Typography>Тренировка добавлена в расписание групп:</Typography>
              <List>
                {training.data.schedule_groups.map((group) => (
                  <GroupListItem group={group} key={group.id} />
                ))}
              </List>
            </>
          )}
          <Button
            onClick={() =>
              setScheduleState({
                training: id,
              })
            }
            variant="contained"
            color="accent"
          >
            Назначить группе
          </Button>
        </TrainingPage.GroupsSurface>

        <SelectGroupModal
          isOpen={!!scheduleState && !scheduleState.group}
          onRequestClose={() => setScheduleState(undefined)}
          onSubmit={(group) =>
            setScheduleState((prev) => ({
              ...prev!,
              group,
            }))
          }
        />

        <CalendarPickerModal
          isOpen={!!scheduleState && !!scheduleState.group}
          group={scheduleState?.group}
          onRequestClose={() => setScheduleState(undefined)}
          onSubmit={(date) => {
            createSchedule({
              training: scheduleState!.training,
              training_date: toApiDate(moment(date)),
              trainer_group: scheduleState!.group!,
            }).then(() => {
              training.revalidate();
              setScheduleState(undefined);
            });
          }}
        />
      </>
    );
    const exercisesNode = (
      <TrainingPage.Exercises>
        {training.data.exercise_types.map((type) => {
          const typeData = exercisesTypes.data?.results?.find(
            (item) => item.id === type.id
          );
          if (!typeData || !type.exercises.length) return null;
          return (
            <ExerciseType key={typeData.id} exerciseType={typeData}>
              <List>
                {type.exercises.map((exercise) => (
                  <ExerciseListItem key={exercise.id} exercise={exercise} />
                ))}
              </List>
            </ExerciseType>
          );
        })}
      </TrainingPage.Exercises>
    );

    const headNode = (
      <TrainingPage.Head>
        <TrainingPage.Name>{training.data.name}</TrainingPage.Name>
        <TrainingPage.Details>
          <div>{formatTrainingDuration(training.data.duration)}</div>
          <div>{training.data.skill.name}</div>
          <div>Уровень: {training.data.level.name}</div>
        </TrainingPage.Details>
        <TrainingPage.Equipment>
          Оборудование: {training.data.equipment || '—'}
        </TrainingPage.Equipment>
        <TrainingPage.Description>
          {training.data.description}
        </TrainingPage.Description>
        <TrainingPage.Actions>
          <Button
            onClick={() =>
              trainingVideo.setState({
                training: training.data!.id,
              })
            }
            variant="contained"
            leading={<PlayIcon />}
            color="accent"
          >
            Посмотреть тренировку
          </Button>
          {!training.data.is_public && (
            <Button
              as={Link}
              to={`/materials/trainings/${id}/edit`}
              variant="outlined"
              leading={<EditIcon />}
              color="accent"
            >
              Редактировать
            </Button>
          )}
        </TrainingPage.Actions>
      </TrainingPage.Head>
    );

    if (!isNotDesktop) {
      return (
        <Layout>
          <TrainingPage.Content>
            <TrainingPage.Main>
              {headNode}
              {exercisesNode}
            </TrainingPage.Main>
            {groupsNode}
          </TrainingPage.Content>
        </Layout>
      );
    } else {
      return (
        <TrainingPage.TabsLayout
          header={headNode}
          background="primary"
          tabs={
            <>
              <TabLink exact to={`/materials/trainings/${id}`}>
                Упражнения
              </TabLink>
              <TabLink exact to={`/materials/trainings/${id}/groups`}>
                Группы
              </TabLink>
            </>
          }
        >
          <Container>
            <Switch>
              <Route exact path={`/materials/trainings/${id}`}>
                {exercisesNode}
              </Route>
              <Route exact path={`/materials/trainings/${id}/groups`}>
                {groupsNode}
              </Route>
            </Switch>
          </Container>
        </TrainingPage.TabsLayout>
      );
    }
  }

  return <Loader />;
}

TrainingPage.Description = styled(Typography)`
  white-space: pre-line;
`;

TrainingPage.GroupsSurface = styled(Surface).attrs({ variant: 'info' })`
  display: flex;
  flex-direction: column;
  flex-basis: 288px;
  padding: 20px;

  & > ${List} {
    margin: 24px 0;
  }
`;

TrainingPage.Main = styled.div`
  flex: 1;
`;

TrainingPage.Name = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 31px;
`;
TrainingPage.Equipment = styled(Typography)`
  margin-bottom: 6px;
`;
TrainingPage.Details = styled(Details)`
  margin-bottom: 22px;

  & > * {
    &:not(:last-child)::after {
      margin: 0 10px;
      color: ${(props) => props.theme.colors.accent};
    }
  }
`;
TrainingPage.Actions = styled.div`
  margin: 20px 0 103px;
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 25px;
  }
`;
TrainingPage.Exercises = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 95px;
  }
`;

TrainingPage.Content = styled(Container)`
  padding-top: 90px;
  padding-bottom: 90px;
  display: flex;
  align-items: flex-start;

  & > * + * {
    margin-left: 92px;
  }
`;

TrainingPage.Head = styled.div``;

TrainingPage.TabsLayout = styled(TabsLayout)`
  ${TabsLayout.Content} {
    background: ${(props) => props.theme.colors.backgroundAccent};
  }

  ${TabLink} {
    background: ${(props) => props.theme.colors.backgroundPrimary};

    &.active {
      background: ${(props) => props.theme.colors.backgroundAccent};
    }
  }

  ${TrainingPage.Head} {
    width: 100%;
  }

  ${TrainingPage.Actions} {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 0;

    & > * + * {
      margin-left: 0;
      margin-top: 25px;
    }
  }

  ${TrainingPage.GroupsSurface} {
    padding: 0;
    background: none;
  }

  ${TrainingPage.Exercises} {
    & > * + * {
      margin-top: 48px;
    }
  }
`;

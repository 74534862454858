import Avatar from 'components/Avatar';
import BodyBackground from 'components/BodyBackground';
import BottomNavigation from 'components/BottomNavigation';
import Header, { MobileHeader } from 'components/Header';
import Spacer from 'components/Spacer';
import { UserRole } from 'utils/user';
import { useStore } from 'store';
import { useUser } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

import LogoutModal from 'components/LogoutModal';
import { ComponentProps } from 'react';
import { ReactComponent as HomeIcon } from './home.svg';
import { ReactComponent as HomeActiveIcon } from './home_active.svg';
import { ReactComponent as StudentsIcon } from './students.svg';
import { ReactComponent as StudentsActiveIcon } from './students_active.svg';
import { ReactComponent as MaterialsIcon } from './materials.svg';
import { ReactComponent as MaterialsActiveIcon } from './materials_active.svg';

type LayoutProps = GetStyledProps<typeof Layout['Root']> & {
  background?: ComponentProps<typeof BodyBackground>['color'];
};

export default function Layout({
  background,
  children,
  ...restProps
}: LayoutProps) {
  const { isNotDesktop } = useResponsive();
  const { layout } = useStore();
  const user = useUser();
  const userData = user.data!;
  return (
    <>
      <BodyBackground color={background} />
      <Layout.Root {...restProps}>
        {isNotDesktop ? (
          <>
            <MobileHeader />
            <Layout.BottomNavigation
              items={
                userData.role === UserRole.TRAINER
                  ? [
                      {
                        to: '/',
                        title: 'Главная',
                        icon: <HomeIcon />,
                        activeIcon: <HomeActiveIcon />,
                        exact: true,
                      },
                      {
                        to: '/students',
                        icon: <StudentsIcon />,
                        activeIcon: <StudentsActiveIcon />,
                        title: 'Ученики',
                      },
                      {
                        to: '/materials',
                        icon: <MaterialsIcon />,
                        activeIcon: <MaterialsActiveIcon />,
                        title: 'Материалы',
                      },
                    ]
                  : [
                      {
                        to: '/',
                        title: 'Мои занятия',
                        exact: true,
                        icon: <HomeIcon />,
                        activeIcon: <HomeActiveIcon />,
                      },
                      {
                        to: '/trainings',
                        title: 'Тренировки',
                        icon: <MaterialsIcon />,
                        activeIcon: <MaterialsActiveIcon />,
                      },
                      {
                        title: 'Я',
                        to: '/profile',
                        icon: (
                          <Layout.BottomNavigationAvatar src={userData.photo} />
                        ),
                      },
                    ]
              }
            />
          </>
        ) : (
          <Layout.Header
            end={
              <Header.AuthButton onClick={() => layout.setLogout(true)}>
                Выход
              </Header.AuthButton>
            }
          >
            {userData.role === UserRole.TRAINER && (
              <>
                <Header.NavItem exact to="/">
                  Главная
                </Header.NavItem>
                <Header.NavItem to="/students">Ученики</Header.NavItem>
                <Header.NavItem to="/materials">Материалы</Header.NavItem>
              </>
            )}
            {userData.role === UserRole.STUDENT && (
              <>
                <Header.NavItem exact to="/">
                  Мои занятия
                </Header.NavItem>
                <Header.NavItem to="/trainings">Тренировки</Header.NavItem>
              </>
            )}
            <Spacer />
            <Header.NavItem to="/profile">
              <Layout.HeaderAvatar src={user.data!.photo} />
              {user.data!.first_name} {user.data!.last_name}
            </Header.NavItem>
          </Layout.Header>
        )}
        <Layout.Content>{children}</Layout.Content>
      </Layout.Root>

      <LogoutModal
        isOpen={layout.isLogout}
        onRequestClose={() => layout.setLogout(false)}
      />
    </>
  );
}

Layout.Header = styled(Header)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: ${(props) => props.theme.zIndex.header};
`;

Layout.HeaderAvatar = styled(Avatar)`
  margin-right: 28px;
`;

Layout.BottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${(props) => props.theme.zIndex.header};
`;

Layout.BottomNavigationAvatar = styled(Avatar)`
  width: 25px;
  height: 25px;
`;

Layout.Content = styled.main`
  margin-top: ${(props) => props.theme.header.height}px;
`;

Layout.Root = styled.div`
  @media ${mq.notDesktop} {
    ${Layout.Content} {
      margin-top: 0;
      margin-bottom: ${(props) => props.theme.bottomNavigation.height}px;
    }
  }
`;

import Button from 'components/Button';
import FormField from 'components/FormField';
import GenderToggle from 'components/GenderToggle';
import Input, { DateInput, PasswordInput, PhoneInput } from 'components/Input';
import Label from 'components/Label';
import { UserGender } from 'utils/user';
import { Field, Form, Formik, FormikConfig } from 'formik';
import { schema } from 'hooks/data';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

export type StudentFormValues = schema['RegisterStudentRequest'];

type StudentFormProps = {
  initialValues?: Partial<StudentFormValues>;
  isEdit?: boolean;
  onSubmit: FormikConfig<StudentFormValues>['onSubmit'];
};

export default function StudentForm({
  initialValues = {
    gender: UserGender.FEMALE,
  },
  isEdit,
  onSubmit,
  ...restProps
}: StudentFormProps) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues as StudentFormValues}
    >
      {(formik) => (
        <StudentForm.Root {...restProps}>
          <StudentForm.Fields>
            <FormField
              label={<Label htmlFor="first_name">Имя</Label>}
              error={formik.errors.first_name}
            >
              <Field
                id="first_name"
                name="first_name"
                error={formik.errors.first_name}
                as={Input}
              />
            </FormField>
            <FormField
              label={<Label htmlFor="last_name">Фамилия</Label>}
              error={formik.errors.last_name}
            >
              <Field
                id="last_name"
                name="last_name"
                error={formik.errors.last_name}
                as={Input}
              />
            </FormField>
            <FormField
              error={formik.errors.gender}
              label={<Label htmlFor="gender">Пол</Label>}
            >
              <Field
                id="gender"
                name="gender"
                as={GenderToggle}
                onChange={(gender: schema['GenderEnum']) =>
                  formik.setFieldValue('gender', gender)
                }
              />
            </FormField>
            <FormField
              error={formik.errors.birth_day}
              label={<Label htmlFor="birth_day">Дата рождения</Label>}
            >
              <Field
                id="birth_day"
                name="birth_day"
                error={formik.errors.birth_day}
                as={DateInput}
              />
            </FormField>
            <FormField
              label={<Label htmlFor="phone">Телефон (WhatsApp)</Label>}
              error={formik.errors.phone}
            >
              <Field
                id="phone"
                name="phone"
                error={formik.errors.phone}
                as={PhoneInput}
              />
            </FormField>
            <FormField
              label={
                <Label htmlFor="vk_link">Ссылка на профиль Vkontakte</Label>
              }
              error={formik.errors.vk_link}
            >
              <Field
                id="vk_link"
                name="vk_link"
                error={formik.errors.vk_link}
                as={Input}
              />
            </FormField>
            <FormField
              label={<Label htmlFor="username">Логин</Label>}
              error={formik.errors.username}
            >
              <Field
                id="username"
                name="username"
                error={formik.errors.username}
                as={Input}
              />
            </FormField>
            <FormField
              label={<Label htmlFor="password">Пароль</Label>}
              error={formik.errors.password}
            >
              <Field
                id="password"
                name="password"
                showPlaceholder={isEdit}
                error={formik.errors.password}
                as={PasswordInput}
              />
            </FormField>
          </StudentForm.Fields>
          <StudentForm.Submit disabled={formik.isSubmitting}>
            Готово
          </StudentForm.Submit>
        </StudentForm.Root>
      )}
    </Formik>
  );
}

StudentForm.Fields = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
`;

StudentForm.Submit = styled(Button).attrs({
  type: 'submit',
  color: 'accent',
  variant: 'contained',
})`
  margin-top: 40px;
  min-width: 188px;
`;

StudentForm.Root = styled(Form)`
  @media ${mq.notDesktop} {
    ${StudentForm.Fields} {
      grid-template-columns: 1fr;
    }

    ${StudentForm.Submit} {
      width: 100%;
    }
  }
`;

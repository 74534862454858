import GroupAvatar from 'components/GroupAvatar';
import ListItem from 'components/ListItem';
import Typography from 'components/Typography';
import { schema, useLevel } from 'hooks/data';

import styled, { css } from 'styled-components/macro';
import { ReactComponent as MembersIcon } from './members.svg';

type GroupListItemProps = GetStyledProps<typeof GroupListItem['Root']> & {
  group: schema['TrainerGroupList'];
};

export default function GroupListItem({
  group,
  ...restProps
}: GroupListItemProps) {
  const level = useLevel(group.level);
  return (
    <GroupListItem.Root
      action={
        <>
          <GroupListItem.MembersIcon />
          <Typography>{group.members_count}</Typography>
        </>
      }
      {...restProps}
    >
      <GroupListItem.Avatar name={group.name} level={level} />
      <GroupListItem.Name>{group.name}</GroupListItem.Name>
    </GroupListItem.Root>
  );
}

GroupListItem.Name = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
GroupListItem.Avatar = styled(GroupAvatar)`
  margin-right: 11px;
  flex-shrink: 0;
`;
GroupListItem.MembersIcon = styled(MembersIcon)`
  margin-right: 6px;
`;

GroupListItem.Root = styled(ListItem)<{ button?: boolean }>`
  ${(props) =>
    props.button &&
    css`
      &:hover ${GroupListItem.Name} {
        color: ${props.theme.colors.primary};
        font-weight: 500;
      }
    `}
`;

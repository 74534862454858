import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

const ArticlesList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media ${mq.notDesktop} {
    grid-template-columns: 1fr;
  }
`;

export default ArticlesList;

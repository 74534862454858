import TabLink from 'components/TabLink';
import TabsLayout from 'components/TabsLayout';
import Typography from 'components/Typography';
import { Redirect, Route, Switch } from 'react-router-dom';
import useDataLayer from 'hooks/useDataLayer';
import { useRouteMatch } from 'react-router-dom';
import { ReactComponent as Mascot } from './mascot.svg';
import ListFragment from './list';
import GroupsFragment from './groups';

export default function StudentsPage() {
  const match = useRouteMatch<{ url: string }>();
  const title = 'Ученики';
  useDataLayer({ title, alias: match.url });

  return (
    <TabsLayout
      header={<Typography variant="h1">{title}</Typography>}
      icon={<Mascot />}
      tabs={
        <>
          <TabLink exact to="/students/groups">
            Группы
          </TabLink>
          <TabLink to="/students/list">Ученики</TabLink>
        </>
      }
    >
      <Switch>
        <Route exact path="/students/groups" component={GroupsFragment} />
        <Route exact path="/students/list" component={ListFragment} />
        <Redirect to="/students/groups" />
      </Switch>
    </TabsLayout>
  );
}

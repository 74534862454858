import Container from 'components/Container';
import RewardItem from 'components/RewardItem';
import RewardsList from 'components/RewardsList';
import Typography from 'components/Typography';
import { useRewards } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

export default function StudentProfileRewards() {
  const rewards = useRewards();
  const { isNotDesktop } = useResponsive();
  return (
    <StudentProfileRewards.Root>
      <StudentProfileRewards.Content>
        <StudentProfileRewards.Title variant={isNotDesktop ? 'h3' : 'h2'}>
          Мои награды
        </StudentProfileRewards.Title>
        <RewardsList>
          {rewards.data?.map((reward, key) => (
            <RewardItem key={key} reward={reward} />
          ))}
        </RewardsList>
      </StudentProfileRewards.Content>
    </StudentProfileRewards.Root>
  );
}

StudentProfileRewards.Content = styled(Container)``;

StudentProfileRewards.Title = styled(Typography)`
  margin-bottom: 61px;
`;

StudentProfileRewards.Root = styled.section`
  background: ${(props) => props.theme.colors.backgroundAccent};
  padding: 146px 0 131px;

  @media ${mq.tablet} {
    padding: 82px 0;
  }

  @media ${mq.mobile} {
    padding: 55px 0;

    ${StudentProfileRewards.Title} {
      margin-bottom: 15px;
    }
  }
`;

import { ComponentProps, ReactNode } from 'react';
import Loader from 'components/Loader';
import { UserRole } from 'utils/user';
import { schema, useIsLoggedIn, useUser } from 'hooks/data';
import { StatusCodes } from 'http-status-codes';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import ErrorPage from 'pages/_error';
import TrainerIndexPage from 'pages/index/_trainer';
import StudentIndexPage from 'pages/index/_student';
import LoginPage from 'pages/login';
import LogoutPage from 'pages/logout';
import MaterialsPage from 'pages/materials';
import TrainingsCreatePage from 'pages/materials/trainings/create';
import TrainingPage from 'pages/materials/trainings/[id]';
import TrainignsEditPage from 'pages/materials/trainings/[id]/edit';
import StudentProfilePage from 'pages/profile/_student';
import TrainerProfilePage from 'pages/profile/_trainer';
import TrainerProfileEditPage from 'pages/profile/edit/_trainer';
import RegisterPage from 'pages/register';
import ResetPasswordPage from 'pages/reset-password';
import StudentsPage from 'pages/students';
import StudentsAddPage from 'pages/students/add';
import GroupsCreatePage from 'pages/students/groups/create';
import GroupPage from 'pages/students/groups/[id]';
import GroupsEditPage from 'pages/students/groups/[id]/edit';
import StudentPage from 'pages/students/[id]';
import StudentsEditPage from 'pages/students/[id]/edit';
import ArticlesCategoryPage from 'pages/articles/[category]';
import ArticlePage from 'pages/articles/[category]/[article]';
import BingoPage from 'pages/bingo';
import TrainingsPage from 'pages/trainings';
import FeedbackPage from 'pages/feedback/[id]';

export const NotFoundRoute = () => {
  return (
    <Route>
      <ErrorPage statusCode={StatusCodes.NOT_FOUND} />
    </Route>
  );
};

type PrivateRouteProps = ComponentProps<typeof Route> & {
  role?: schema['RoleEnum'];
};

export const PrivateRoute = ({ role, ...restProps }: PrivateRouteProps) => {
  const isLoggedIn = useIsLoggedIn();
  if (isLoggedIn === null) {
    return <Loader />;
  }
  return isLoggedIn ? <Route {...restProps} /> : <Redirect to="/login" />;
};

export const GuestRoute = (props: ComponentProps<typeof Route>) => {
  const isLoggedIn = useIsLoggedIn();
  if (isLoggedIn === null) {
    return <Loader />;
  }
  return isLoggedIn ? <Redirect to="/" /> : <Route {...props} />;
};

type RouterProps = {
  children?: ReactNode;
};

export default function Router({ children }: RouterProps) {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/reset-password/:token"
          component={ResetPasswordPage}
        />
        <GuestRoute
          exact
          path={[
            '/login',
            '/login/student',
            '/login/trainer',
            '/login/trainer/reset',
          ]}
          component={LoginPage}
        />
        <GuestRoute exact path="/register" component={RegisterPage} />
        <PrivateRoute exact path="/logout" component={LogoutPage} />
        <PrivateRoute component={RoleRouter} />
      </Switch>
      {children}
    </BrowserRouter>
  );
}

function RoleRouter() {
  const user = useUser();

  if (user.data?.role === UserRole.TRAINER) {
    return (
      <Switch>
        <PrivateRoute exact path="/" component={TrainerIndexPage} />
        <PrivateRoute exact path="/profile" component={TrainerProfilePage} />
        <PrivateRoute
          exact
          path="/profile/edit"
          component={TrainerProfileEditPage}
        />
        <PrivateRoute
          exact
          path="/students/groups/create"
          component={GroupsCreatePage}
        />
        <PrivateRoute
          exact
          path="/students/groups/:id/edit"
          component={GroupsEditPage}
        />
        <PrivateRoute
          exact
          path={[
            '/students/groups/:id',
            '/students/groups/:id/students',
            '/students/groups/:id/schedule',
          ]}
          component={GroupPage}
        />
        <PrivateRoute
          exact
          path={['/students', '/students/groups', '/students/list']}
          component={StudentsPage}
        />
        <PrivateRoute exact path="/students/add" component={StudentsAddPage} />
        <PrivateRoute exact path="/students/:id" component={StudentPage} />
        <PrivateRoute
          exact
          path="/students/:id/edit"
          component={StudentsEditPage}
        />
        <PrivateRoute
          exact
          path={[
            '/materials',
            '/materials/trainings',
            '/materials/exercises',
            '/materials/articles',
          ]}
          component={MaterialsPage}
        />
        <PrivateRoute
          exact
          path="/articles/:category"
          component={ArticlesCategoryPage}
        />
        <PrivateRoute
          exact
          path="/articles/:category/:article"
          component={ArticlePage}
        />
        <PrivateRoute
          exact
          path="/materials/trainings/create"
          component={TrainingsCreatePage}
        />
        <PrivateRoute
          exact
          path="/materials/trainings/:id/edit"
          component={TrainignsEditPage}
        />
        <PrivateRoute
          exact
          path={['/materials/trainings/:id', '/materials/trainings/:id/groups']}
          component={TrainingPage}
        />
        <NotFoundRoute />
      </Switch>
    );
  }

  if (user.data?.role === UserRole.STUDENT) {
    return (
      <Switch>
        <PrivateRoute exact path="/" component={StudentIndexPage} />
        <PrivateRoute
          exact
          path="/articles/:category"
          component={ArticlesCategoryPage}
        />
        <PrivateRoute
          exact
          path="/articles/:category/:article"
          component={ArticlePage}
        />
        <PrivateRoute exact path="/profile" component={StudentProfilePage} />
        <PrivateRoute exact path="/bingo" component={BingoPage} />
        <PrivateRoute exact path="/feedback/:id" component={FeedbackPage} />
        <PrivateRoute
          exact
          path={[
            '/trainings',
            '/trainings/week',
            '/trainings/star',
            '/trainings/all',
          ]}
          component={TrainingsPage}
        />
        <NotFoundRoute />
      </Switch>
    );
  }

  return null;
}

import StudentTrainingCard from 'components/StudentTrainingCard';
import { useStudentTrainings } from 'hooks/data';
import styled from 'styled-components/macro';
import TrainingsAllFragment from './all';

export default function TrainingsStarFragment() {
  const trainings = useStudentTrainings({
    star: true,
  });
  return (
    <TrainingsStarFragment.Root>
      {trainings.data?.results?.map((training) => (
        <StudentTrainingCard key={training.id} training={training} />
      ))}
    </TrainingsStarFragment.Root>
  );
}

TrainingsStarFragment.Root = styled(TrainingsAllFragment.Root)``;

import Typography from 'components/Typography';
import styled from 'styled-components/macro';

const Surface = styled(
  (props: Omit<GetStyledProps<typeof Typography>, 'variant'>) => (
    <Typography {...props} variant={undefined} />
  )
)<{ variant: 'info' | 'error' }>`
  padding: 16px;
  border-radius: 10px;
  background: ${(props) => {
    switch (props.variant) {
      case 'info':
        return props.theme.colors.backgroundAccent;
      case 'error':
        return props.theme.colors.surfaceError;
    }
  }};
`;

export default Surface;

import { useState } from 'react';
import { createContainer } from 'unstated-next';

export function useLayout() {
  const [isLogout, setLogout] = useState(false);
  return {
    isLogout,
    setLogout,
  };
}

export const LayoutStore = createContainer(useLayout);

import Button from 'components/Button';
import Card from 'components/Card';
import Typography from 'components/Typography';
import useResponsive from 'hooks/useResponsive';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import { ReactComponent as Icons } from './icons.svg';

type StudentBingoSummaryProps = GetStyledProps<
  typeof StudentBingoSummary['Root']
> & {
  current: number;
  count: number;
};

export default function StudentBingoSummary({
  current,
  count,
  ...restProps
}: StudentBingoSummaryProps) {
  const { isNotDesktop } = useResponsive();
  return (
    <StudentBingoSummary.Root {...restProps}>
      <StudentBingoSummary.Main>
        <StudentBingoSummary.Title variant={isNotDesktop ? 'h3' : 'h2'}>
          Бинго этой недели
        </StudentBingoSummary.Title>
        <StudentBingoSummary.Progress>
          Сделано {current} из {count}
        </StudentBingoSummary.Progress>
        <Button as={Link} to="/bingo" variant="outlined" color="accent">
          Выполнить задания
        </Button>
      </StudentBingoSummary.Main>
      {!isNotDesktop && <Icons />}
    </StudentBingoSummary.Root>
  );
}

StudentBingoSummary.Progress = styled(Typography)`
  margin-bottom: 39px;
`;

StudentBingoSummary.Title = styled(Typography)`
  margin-bottom: 17px;
`;

StudentBingoSummary.Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

StudentBingoSummary.Root = styled(Card)`
  padding: 40px 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${mq.notDesktop} {
    padding: 32px;
    flex-direction: column;
    align-items: stretch;

    ${StudentBingoSummary.Main} {
      align-items: stretch;
    }

    ${StudentBingoSummary.Title} {
      margin-bottom: 7px;
    }

    ${StudentBingoSummary.Progress} {
      margin-bottom: 32px;
    }
  }
`;

// @ts-ignore
const assets: string[] = require.context('./assets', true, /\.svg$/).keys();

const generateValues = (key: string) =>
  assets.filter((asset) => asset.includes(key)).map((_, i) => i);

export const skinColors = ['#FBAA99', '#ECAD80', '#AE796E'];
export const hairColors = [
  '#212121',
  '#FFAA6C',
  '#7E463A',
  '#E05023',
  '#DA242F',
  '#5A319C',
  '#16684E',
  '#2161AA',
];

export const EMPTY_VALUE = -1;

export type OptionSettings = {
  key: 'face' | 'head' | 'hair_type' | 'hair_color' | 'clothes' | 'accessories';
  label: string;
  values: number[];
};

export type Option = {
  label: string;
  primary: OptionSettings;
  secondary?: OptionSettings;
};

export const options: Option[] = [
  {
    label: 'Лицо и цвет кожи',
    primary: {
      key: 'face',
      label: 'Лицо',
      values: generateValues('face'),
    },
    secondary: {
      key: 'head',
      label: 'Цвет кожи',
      values: generateValues('head'),
    },
  },
  {
    label: 'Причёска и цвет волос',
    primary: {
      key: 'hair_type',
      label: 'Прическа',
      values: new Array(generateValues('hair').length / hairColors.length)
        .fill(0)
        .map((_, i) => i),
    },
    secondary: {
      key: 'hair_color',
      label: 'Цвет волос',
      values: hairColors.map((_, i) => i),
    },
  },
  {
    label: 'Одежда',
    primary: {
      key: 'clothes',
      label: 'Одежда',
      values: generateValues('clothes'),
    },
  },
  {
    label: 'Аксессуары',
    primary: {
      key: 'accessories',
      label: 'Аксессуары',
      values: [EMPTY_VALUE, ...generateValues('accessories')],
    },
  },
];

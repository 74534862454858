import ArticleCard from 'components/ArticleCard';
import ArticlesList from 'components/ArticlesList';
import Breadcrumbs from 'components/Breadcrumbs';
import Container from 'components/Container';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import Typography from 'components/Typography';
import { UserRole } from 'utils/user';
import { useArticle, useCategoryArticles, useUser } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import { StatusCodes } from 'http-status-codes';
import ErrorPage from 'pages/_error';
import { useMemo } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import useDataLayer from 'hooks/useDataLayer';

export default function ArticlePage() {
  const user = useUser();
  const { isNotDesktop } = useResponsive();
  const match = useRouteMatch<{
    article: string;
    category: string;
    path: string;
    url: string;
  }>();
  useDataLayer({ title: match.path, alias: match.url });

  const categoryId = Number(match.params.category);
  const articleId = Number(match.params.article);
  const article = useArticle(articleId);
  const otherArticles = useCategoryArticles(article.data?.category.id);
  const computedOtherArticles = useMemo(
    () =>
      otherArticles.data?.results?.filter(
        (item) => item.id !== article.data?.id
      ),
    [article.data?.id, otherArticles.data?.results]
  );

  if (article.error) {
    return <ErrorPage statusCode={article.error.status} />;
  }

  if (article.data) {
    if (article.data.category.id !== categoryId) {
      return <ErrorPage statusCode={StatusCodes.NOT_FOUND} />;
    }

    return (
      <Layout>
        <ArticlePage.Content>
          <ArticlePage.Breadcrumbs
            items={[
              ...(user.data?.role === UserRole.STUDENT
                ? [
                    {
                      to: '/',
                      children: 'Мои занятия',
                    },
                  ]
                : [
                    {
                      to: '/materials/articles',
                      children: 'Статьи',
                    },
                  ]),
              {
                to: `/articles/${article.data.category.id}`,
                children: article.data.category.name,
              },
              {
                to: match.url,
                children: article.data.name,
              },
            ]}
          />
          <ArticlePage.Title>{article.data.name}</ArticlePage.Title>
          <ArticlePage.ArticleContent
            dangerouslySetInnerHTML={{
              __html: article.data.content,
            }}
          />
          {computedOtherArticles?.length! > 0 && (
            <>
              <ArticlePage.OtherTitle variant={isNotDesktop ? 'h3' : 'h2'}>
                Другие материалы про {article.data.category.name_other}
              </ArticlePage.OtherTitle>
              <ArticlesList>
                {computedOtherArticles?.map((item) => (
                  <ArticleCard
                    to={`/articles/${article.data?.category.id}/${item.id}`}
                    as={Link}
                    key={item.id}
                    article={item}
                  />
                ))}
              </ArticlesList>
            </>
          )}
        </ArticlePage.Content>
      </Layout>
    );
  }

  return <Loader />;
}

ArticlePage.Breadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 28px;
`;
ArticlePage.Title = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 55px;
`;

ArticlePage.OtherTitle = styled(Typography)`
  margin-bottom: 38px;
`;

ArticlePage.ArticleContent = styled(Typography)`
  margin-bottom: 80px;
  max-width: 736px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

ArticlePage.Content = styled(Container)`
  padding-top: 60px;
  padding-bottom: 60px;

  @media ${mq.notDesktop} {
    padding-top: 0;
  }
`;

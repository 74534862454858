import Button from 'components/Button';
import Card from 'components/Card';
import Container from 'components/Container';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import MascotMessage from 'components/MascotMessage';
import Typography from 'components/Typography';
import { schema, useCreateFeedback, useSchedule } from 'hooks/data';
import useNow from 'hooks/useNow';
import useResponsive from 'hooks/useResponsive';
import useDataLayer from 'hooks/useDataLayer';
import ErrorPage from 'pages/_error';
import { ReactNode, useState } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import { toApiDate } from 'utils/date';
import plural from 'plural-ru';

import RewardsList from 'components/RewardsList';
import RewardItem from 'components/RewardItem';
import { ReactComponent as EasyIcon } from './easy.svg';
import { ReactComponent as FineIcon } from './fine.svg';
import { ReactComponent as HardIcon } from './hard.svg';

export const titleByFeedback: Record<schema['FeedbackEnum'], string> = {
  easy: 'Легко',
  fine: 'Нормально',
  hard: 'Сложно',
};

export const iconByFeedback: Record<schema['FeedbackEnum'], ReactNode> = {
  easy: <EasyIcon />,
  fine: <FineIcon />,
  hard: <HardIcon />,
};

export default function FeedbackPage() {
  const match = useRouteMatch<{ id: string; url: string }>();
  const title = 'Как тебе тренировка?';
  useDataLayer({ title, alias: match.url });
  const id = Number(match.params.id);
  const schedule = useSchedule(id);
  const { isNotDesktop } = useResponsive();
  const createFeedback = useCreateFeedback();
  const [feedbackResult, setFeedbackResult] = useState<
    schema['CreateTrainingsFeedback']
  >();
  const now = useNow();

  if (schedule.error) {
    return <ErrorPage statusCode={schedule.error.status} />;
  }

  if (schedule.data) {
    return (
      <FeedbackPage.Root>
        <FeedbackPage.Content>
          {!feedbackResult ? (
            <>
              <FeedbackPage.Title variant="h1">{title}</FeedbackPage.Title>
              <FeedbackPage.Cards>
                {(['easy', 'fine', 'hard'] as const).map((feedback) => (
                  <FeedbackPage.Card
                    onClick={() =>
                      createFeedback({
                        schedule_item: schedule.data!.id,
                        adding_date: toApiDate(now),
                        feedback,
                      }).then(setFeedbackResult)
                    }
                    key={feedback}
                    as="button"
                  >
                    {iconByFeedback[feedback]}
                    <Typography variant={isNotDesktop ? 'medium' : 'h3'}>
                      {titleByFeedback[feedback]}
                    </Typography>
                  </FeedbackPage.Card>
                ))}
              </FeedbackPage.Cards>
            </>
          ) : (
            <>
              <MascotMessage>
                <div>
                  Хорошо, тренер обязательно
                  <br />
                  учтёт твой ответ!
                </div>
                {feedbackResult.rewards.length > 0 && (
                  <div>
                    А вот{' '}
                    {plural(
                      feedbackResult.rewards.length,
                      'твоя награда',
                      'твои награды'
                    )}
                  </div>
                )}
              </MascotMessage>
              <FeedbackPage.Rewards>
                {feedbackResult.rewards.map((reward) => (
                  <RewardItem
                    hiddenName
                    reward={reward}
                    key={reward.achievement.id}
                  />
                ))}
              </FeedbackPage.Rewards>
              <Button
                as={Link}
                to="/profile"
                variant="contained"
                color="accent"
              >
                Мои результаты
              </Button>
            </>
          )}
        </FeedbackPage.Content>
      </FeedbackPage.Root>
    );
  }

  return <Loader />;
}

FeedbackPage.Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 160px);
  gap: 32px;
`;

FeedbackPage.Card = styled(Card)`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  & > * + * {
    margin-top: 15px;
  }

  &:hover {
    ${Typography} {
      color: #fff;
    }

    svg {
      circle {
        stroke: #fff;
      }
    }

    &:nth-child(1) {
      background: #09cac7;
    }
    &:nth-child(2) {
      background: #2cc16b;
    }
    &:nth-child(3) {
      background: #ff1523;
    }
  }
`;

FeedbackPage.Rewards = styled(RewardsList)`
  grid-template-columns: 1fr;
  justify-items: center;
  margin: 40px 0;
`;

FeedbackPage.Title = styled(Typography)`
  margin-bottom: 70px;
`;

FeedbackPage.Content = styled(Container)`
  padding-bottom: 38px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

FeedbackPage.Root = styled(Layout).attrs({ background: 'accent' })`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${Layout.Content} {
    flex: 1;
    display: flex;
  }

  @media ${mq.notDesktop} {
    ${FeedbackPage.Cards} {
      grid-template-columns: 1fr;
      align-self: stretch;
      gap: 24px;
    }

    ${FeedbackPage.Card} {
      padding: 16px;
      flex-direction: row;

      svg {
        width: auto;
        max-height: 48px;
      }

      & > * + * {
        margin-top: 0;
        margin-left: 16px;
      }
    }

    ${FeedbackPage.Title} {
      margin-bottom: 30px;
    }

    ${MascotMessage.Root} {
      flex-direction: column;
      align-items: flex-start;

      & > * + * {
        margin-left: 0;
        margin-top: 16px;
      }
    }

    ${FeedbackPage.Content} {
      align-items: stretch;
    }
  }
`;

import Container from 'components/Container';
import { useArticlesOnMain } from 'hooks/data';
import styled from 'styled-components/macro';
import Typography from 'components/Typography';
import ArticlesList from 'components/ArticlesList';
import ArticleCard from 'components/ArticleCard';
import Button from 'components/Button';
import { Link } from 'react-router-dom';

export default function ArticlesFragment() {
  return (
    <>
      <ArticlesSection />
    </>
  );
}

function ArticlesSection() {
  const articles = useArticlesOnMain();
  if (!articles.data?.categories.length) {
    return null;
  }
  return (
    <ArticlesSection.Root>
      <Container>
        {articles.data?.categories.map((category) => {
          if (!category.articles.length) {
            return null;
          }

          return (
            <div key={category.category.id}>
              <ArticlesSection.Category>
                {category.category.name}
              </ArticlesSection.Category>
              <ArticlesList>
                {category.articles.map((article) => (
                  <ArticleCard
                    as={Link}
                    to={`/articles/${category.category.id}/${article.id}`}
                    key={article.id}
                    article={article}
                  />
                ))}
              </ArticlesList>
              <Button
                as={Link}
                to={`/articles/${category.category.id}`}
                variant="outlined"
                color="accent"
              >
                Смотреть все материалы
              </Button>
            </div>
          );
        })}
      </Container>
    </ArticlesSection.Root>
  );
}

ArticlesSection.Category = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 43px;
`;

ArticlesSection.Root = styled.div`
  background: ${(props) => props.theme.colors.backgroundPrimary};

  ${Container} {
    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 104px;
    }
  }

  ${ArticlesList} {
    margin-bottom: 64px;
  }
`;

import styled from 'styled-components/macro';

type ProgressBarProps = GetStyledProps<typeof ProgressBar['Root']> & {
  value: number;
};

export default function ProgressBar({ value, ...restProps }: ProgressBarProps) {
  return (
    <ProgressBar.Root {...restProps}>
      <ProgressBar.Value style={{ width: `${value}%` }} />
    </ProgressBar.Root>
  );
}

ProgressBar.Value = styled.div`
  background: #29d3ce;
  height: 100%;
  width: 0%;
`;

ProgressBar.Root = styled.div`
  background: #f7f8ff;
  height: 8px;
`;

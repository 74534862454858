import Button from 'components/Button';
import FormField from 'components/FormField';
import Input from 'components/Input';
import Label from 'components/Label';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import Select from 'components/Select';
import { Field, Form, Formik } from 'formik';
import * as qs from 'utils/qs';
import {
  ApiError,
  useUpdateGroup,
  useGroup,
  useGroupStudents,
  useLevels,
} from 'hooks/data';
import useStudentsEditor from 'hooks/useStudentsEditor';
import ErrorPage from 'pages/_error';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { mq } from 'styles/theme';
import MoveToGroupModal from 'components/MoveToGroupModal';
import ProgressStudentListItem from 'components/ProgressStudentListItem';
import useDataLayer from 'hooks/useDataLayer';
import GroupsCreatePage from '../create';

export default function GroupsEditPage() {
  const match = useRouteMatch<{ id: string; url: string }>();
  const title = 'Редактирование группы';
  useDataLayer({ title, alias: match.url });
  const history = useHistory();
  const id = Number(match.params.id);
  const group = useGroup(id);
  const students = useGroupStudents(id);
  const studentsEditor = useStudentsEditor();
  const updateGroup = useUpdateGroup();
  const levels = useLevels();

  if (group.error) {
    return <ErrorPage statusCode={group.error.status} />;
  }

  if (group.data) {
    return (
      <Layout>
        <GroupsCreatePage.Content>
          <GroupsCreatePage.Title>{title}</GroupsCreatePage.Title>
          <Formik
            onSubmit={(values, formik) =>
              updateGroup(id, values as any)
                .then(() => history.push(`/students/groups/${id}`))
                .catch((error: ApiError) => formik.setErrors(error.info))
            }
            initialValues={{
              name: group.data.name,
              level: group.data.level.id,
            }}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <GroupsCreatePage.Fields>
                  <FormField
                    label={<Label htmlFor="name">Название группы</Label>}
                    error={formik.errors.name}
                  >
                    <Field
                      id="name"
                      name="name"
                      error={formik.errors.name}
                      as={Input}
                    />
                  </FormField>
                  <FormField
                    label={<Label htmlFor="level">Уровень группы</Label>}
                    error={formik.errors.level}
                  >
                    <Field
                      id="level"
                      name="level"
                      as={Select}
                      onChange={(level: any) =>
                        formik.setFieldValue('level', level)
                      }
                      options={levels?.map((level) => ({
                        label: level.name,
                        value: level.id,
                      }))}
                    />
                  </FormField>
                </GroupsCreatePage.Fields>
                <GroupsCreatePage.StudentsTitle>
                  Список учеников
                </GroupsCreatePage.StudentsTitle>
                <GroupsCreatePage.Students
                  hiddenHead
                  list={students.data}
                  selected={studentsEditor.selected}
                  onSelected={studentsEditor.setSelected}
                  onCreateGroup={() => {
                    history.push({
                      pathname: '/students/groups/create',
                      search: qs.stringify({
                        student_ids: studentsEditor.selected,
                      }),
                    });
                  }}
                  itemComponent={ProgressStudentListItem}
                  itemProps={(student) => ({
                    action: null,
                    progress: student.progress?.month,
                  })}
                />
                <GroupsEditPage.Buttons>
                  <Button
                    as={Link}
                    to="/students/add"
                    leading={<PlusIcon />}
                    variant="outlined"
                    color="accent"
                  >
                    Добавить нового ученика
                  </Button>
                  <GroupsCreatePage.Submit disabled={formik.isSubmitting}>
                    Готово
                  </GroupsCreatePage.Submit>
                </GroupsEditPage.Buttons>
              </Form>
            )}
          </Formik>
        </GroupsCreatePage.Content>

        <MoveToGroupModal
          excludedGroup={id}
          onMoved={() => {
            studentsEditor.reset();
            students.revalidate();
          }}
          state={studentsEditor.moveState}
          onRequestClose={() => studentsEditor.setMoveState(undefined)}
        />
      </Layout>
    );
  }

  return <Loader />;
}

GroupsEditPage.Buttons = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;

  & > * + * {
    margin-top: 47px;
  }

  @media ${mq.notDesktop} {
    max-width: 100%;
  }
`;

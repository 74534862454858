import { schema } from 'hooks/data';
import styled from 'styled-components/macro';

type SkillImageProps = GetStyledProps<typeof SkillImage['Root']> & {
  skill: schema['Skill'];
  level: schema['Level'];
  background?: boolean;
};

export default function SkillImage({
  skill,
  level,
  background,
  ...restProps
}: SkillImageProps) {
  return (
    <SkillImage.Root {...restProps}>
      {background && (
        <SkillImage.Background style={{ background: level.color }} />
      )}
      <SkillImage.Icon
        style={{ color: level.achievement_border_color }}
        dangerouslySetInnerHTML={{ __html: skill.svg_content }}
      />
    </SkillImage.Root>
  );
}

SkillImage.Icon = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

SkillImage.Background = styled.div`
  left: 16px;
  top: 16px;
  bottom: 16px;
  right: 16px;
  position: absolute;
  border-radius: 10px;
`;

SkillImage.Root = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 100%;
`;

import IconButton from 'components/IconButton';
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import styled, { css } from 'styled-components/macro';
import { ibm, rem } from 'styles/mixins';
import InputMask from 'react-input-mask';

import { ReactComponent as EyeClosed } from './eye_closed.svg';
import { ReactComponent as EyeOpened } from './eye_opened.svg';
import { ReactComponent as SearchIcon } from './search.svg';
import { ReactComponent as DateIcon } from './date.svg';

type InputProps = GetStyledProps<typeof Input['Input']> & {
  end?: ReactNode;
};

export default function Input({
  className,
  end,
  value,
  ...restProps
}: InputProps) {
  return (
    <Input.Root className={className}>
      <Input.Input
        type="text"
        value={value == null ? '' : value}
        {...restProps}
      />
      {end && <Input.End>{end}</Input.End>}
    </Input.Root>
  );
}

Input.Root = styled.div`
  position: relative;
`;
Input.Input = styled.input<{ error?: boolean }>`
  padding: 0 40px 0 24px;
  height: 56px;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 10px;
  ${ibm(400)}
  font-size: ${rem(18)};
  line-height: ${rem(26)};
  outline: none;
  color: ${(props) => props.theme.colors.textPrimary};
  width: 100%;
  appearance: none;

  ${(props) =>
    props.error &&
    css`
      border-color: ${props.theme.colors.error};
    `}
`;
Input.End = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`;

type PasswordInputProps = ComponentProps<typeof Input> & {
  showPlaceholder?: boolean;
};

export function PasswordInput({
  showPlaceholder,
  value,
  ...restProps
}: PasswordInputProps) {
  const [show, setShow] = useState(false);
  const [placeholder, setPlaceholder] = useState('********');
  const type = show ? 'text' : 'password';
  const IconComponent = show ? EyeOpened : EyeClosed;
  return (
    <Input
      onFocus={() => {
        if (showPlaceholder && placeholder) {
          setPlaceholder('');
        }
      }}
      {...restProps}
      value={showPlaceholder ? value || placeholder : value}
      end={
        <IconButton onClick={() => setShow((prev) => !prev)}>
          <IconComponent />
        </IconButton>
      }
      type={type}
    />
  );
}

export function DateInput({
  onChange,
  onBlur,
  value,
  ...restProps
}: InputProps) {
  const computedValue = useMemo(() => {
    return value?.toString().replace(/(.*?)-(.*?)-(.*)/g, '$3.$2.$1');
  }, [value]);
  const computedOnChange = useCallback<NonNullable<InputProps['onChange']>>(
    (event) => {
      event.target.value = event.target.value
        .toString()
        .replace(/(.*?)\.(.*?)\.(.*)/g, '$3-$2-$1');
      onChange?.(event);
    },
    [onChange]
  );
  return (
    <InputMask
      mask="99.99.9999"
      maskPlaceholder="дд.мм.гггг"
      onChange={computedOnChange}
      onBlur={onBlur}
      value={computedValue}
      alwaysShowMask
    >
      <Input end={<DateIcon />} {...restProps} />
    </InputMask>
  );
}

export function SearchInput(props: InputProps) {
  return <Input end={<SearchIcon />} {...props} />;
}

export function PhoneInput({
  onChange,
  onBlur,
  value,
  ...restProps
}: InputProps) {
  return (
    <InputMask
      mask="99999999999"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    >
      <Input type="tel" {...restProps} />
    </InputMask>
  );
}

import axios, { AxiosRequestConfig } from 'axios';
import useSWR, { cache, SWRConfiguration } from 'swr';
import * as qs from 'utils/qs';
import * as generatedSchema from 'generated/schema';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { StatusCodes } from 'http-status-codes';

export type schema = generatedSchema.components['schemas'];

export const $axios = axios.create({
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params),
});

export class ApiError<T = any> extends Error {
  info: T & { detail?: string | string[] };
  status: number;

  constructor(info: T, status: number) {
    super('An error occurred while fetching the data.');
    this.info = info;
    this.status = status;
  }
}

export const fetcher = async <T = any>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const { data } = await $axios(url, config);
    return data;
  } catch (error) {
    throw new ApiError(error.response.data, error.response.status);
  }
};

export const swrConfig: SWRConfiguration = {
  fetcher,
  revalidateOnFocus: false,
  shouldRetryOnError: false,
};

/**
 * Версия useSWR с кэшированными non-undefined `data`.
 * Полезно при применении различных фильтров в списках.
 * @param args useSWR args...
 */
function useCachedSWR<T = any, E = any>(...args: any) {
  const query = useSWR<T, E>(...args);
  const dataRef = useRef<T>();
  useEffect(() => {
    if (query.data) {
      dataRef.current = query.data;
    }
  }, [query.data]);
  return {
    ...query,
    data: query.data || dataRef.current,
  };
}

export function useUser() {
  return useSWR<schema['UserInfo'], ApiError>(
    '/api/auth/user-info/',
    (url) =>
      fetcher(url).catch((error: ApiError) => {
        if (error.status === StatusCodes.FORBIDDEN) {
          return null;
        }
        throw error;
      }),
    {
      revalidateOnMount: !cache.has('/api/auth/user-info/'),
    }
  );
}

export function useRole() {
  return useUser().data?.role;
}

export function useIsLoggedIn() {
  const user = useUser();
  return user.data === undefined && user.error === undefined
    ? null
    : !!user.data;
}

export function useLogout() {
  return useCallback(() => fetcher(`/api/auth/logout/`), []);
}

export function useLoginTrainer() {
  return useCallback(
    (data: schema['LoginTrainerInputRequest']) =>
      fetcher<schema['UserInfo']>(`/api/auth/login/trainer/`, {
        data,
        method: 'POST',
      }),
    []
  );
}

export function useLoginStudent() {
  return useCallback(
    (data: schema['LoginStudentInputRequest']) =>
      fetcher<schema['UserInfo']>(`/api/auth/login/student/`, {
        data,
        method: 'POST',
      }),
    []
  );
}

export function useRestorePassword() {
  return useCallback(
    (data: schema['RestorePasswordRequest']) =>
      fetcher('/api/auth/restore-password/', {
        data,
        method: 'POST',
      }),
    []
  );
}

export function useSetPassword() {
  return useCallback(
    (data: schema['SetPasswordRequest']) =>
      fetcher('/api/auth/set-password/', {
        data,
        method: 'POST',
      }),
    []
  );
}

export function useRegisterTrainer() {
  return useCallback(
    (data: schema['RegisterTrainerRequest']) =>
      fetcher<schema['UserInfo']>('/api/auth/register/trainer/', {
        data,
        method: 'POST',
      }),
    []
  );
}

export function useGroup(id: schema['TrainerGroupDetail']['id']) {
  return useSWR<schema['TrainerGroupDetail'], ApiError>(`/api/groups/${id}/`);
}

export function useGroups() {
  return useSWR<schema['PaginatedTrainerGroupListList']>('/api/groups/');
}

export function useDicts() {
  return useSWR<schema['Dicts']>('/api/dicts/');
}

export function useLevels() {
  return useDicts().data?.levels;
}

export function useSkills() {
  return useDicts().data?.skills;
}

export function useLevel(id: schema['Level']['id']) {
  return useLevels()?.find((level) => level.id === id);
}

export function useExercisesTypes() {
  return useSWR<schema['PaginatedExerciseTypeList']>(
    '/api/trainings/exercises/types/'
  );
}

export function useUpdatePassword() {
  return useCallback(
    (data: schema['UpdatePasswordRequest']) =>
      fetcher('/api/auth/update-password/', {
        data,
        method: 'POST',
      }),
    []
  );
}

export function useUploadPhoto() {
  return useCallback((photo: Blob) => {
    const form = new FormData();
    form.append('photo', photo);
    return fetcher(`/api/auth/upload-photo/`, {
      data: form,
      method: 'POST',
    });
  }, []);
}

export function useUpdateTrainer() {
  return useCallback(
    (data: schema['UpdateTrainerRequest']) =>
      fetcher(`/api/auth/update-trainer/`, {
        method: 'POST',
        data,
      }),
    []
  );
}

export function useUpdateStudent() {
  return useCallback(
    (id: schema['StudentInfo']['id'], data: schema['UpdateStudentRequest']) =>
      fetcher(`/api/auth/update-student/${id}/`, {
        method: 'POST',
        data,
      }),
    []
  );
}

export function useStudent(id: schema['StudentInfo']['id']) {
  return useSWR<schema['StudentInfo'], ApiError>(`/api/students/${id}/`);
}

export function useRegisterStudent() {
  return useCallback(
    (data: schema['RegisterStudentRequest']) =>
      fetcher<schema['UserInfo']>('/api/auth/register/student/', {
        method: 'POST',
        data,
      }),
    []
  );
}

export function useAddToTraining() {
  return useCallback(
    (id: schema['Training']['id'], data: schema['AddExerciseInputRequest']) =>
      fetcher<schema['AddExercise']>(`/api/trainings/${id}/add-exercise/`, {
        method: 'POST',
        data,
      }),
    []
  );
}

export function useExercises(params: any) {
  return useCachedSWR<schema['PaginatedExerciseList']>(
    ['/api/trainings/exercises/', params],
    (url: string, params: any) =>
      fetcher(url, {
        params,
      })
  );
}

export function useCloneTraining() {
  return useCallback(
    (id: schema['Training']['id']) =>
      fetcher(`/api/trainings/${id}/clone/`, {
        method: 'POST',
      }),
    []
  );
}

export function useDeleteTraining() {
  return useCallback(
    (id: schema['Training']['id']) =>
      fetcher(`/api/trainings/${id}`, {
        method: 'DELETE',
      }),
    []
  );
}

export function useTrainings(params: any) {
  return useCachedSWR<schema['PaginatedTrainingListList']>(
    ['/api/trainings/', params],
    (url: string, params: any) =>
      fetcher(url, {
        params,
      })
  );
}

export function useCreateTraining() {
  return useCallback(
    (data: schema['TrainingCreateUpdateRequest']) =>
      fetcher<schema['TrainingCreateUpdate']>(`/api/trainings/`, {
        method: 'POST',
        data,
      }),
    []
  );
}

export function useUpdateTraining() {
  return useCallback(
    (
      id: schema['Training']['id'],
      data: schema['TrainingCreateUpdateRequest']
    ) =>
      fetcher<schema['TrainingCreateUpdate']>(`/api/trainings/${id}/`, {
        method: 'PATCH',
        data,
      }),
    []
  );
}

export function useTraining(id?: schema['Training']['id']) {
  return useSWR<schema['Training'], ApiError>(
    id ? `/api/trainings/${id}/` : null
  );
}

export function useExercisesByIds(ids: Array<schema['Exercise']['id']>) {
  return useSWR(`exercises-${ids}`, () =>
    Promise.all(
      ids.map((id) =>
        fetcher<schema['Exercise']>(`/api/trainings/exercises/${id}/`).catch(
          () => null
        )
      )
    )
  );
}

export function useDeleteSchedule() {
  return useCallback(
    (id: schema['TrainingsScheduleItemDetail']['id']) =>
      fetcher(`/api/trainings-schedule/${id}/`, {
        method: 'DELETE',
      }),
    []
  );
}

export function useUpdateSchedule() {
  return useCallback(
    (
      id: schema['TrainingsScheduleItemDetail']['id'],
      data: schema['PatchedTrainingsScheduleItemUpdateRequest']
    ) =>
      fetcher<schema['TrainingsScheduleItemUpdate']>(
        `/api/trainings-schedule/${id}/`,
        {
          method: 'PATCH',
          data,
        }
      ),
    []
  );
}

export function useCreateSchedule() {
  return useCallback(
    (data: schema['TrainingsScheduleItemCreateRequest']) =>
      fetcher<schema['TrainingsScheduleItemUpdate']>(
        `/api/trainings-schedule/`,
        {
          method: 'POST',
          data,
        }
      ),
    []
  );
}

export function useGroupSchedules(
  id: schema['TrainerGroupDetail']['id'] | undefined,
  params: any
) {
  return useSWR<schema['PaginatedTrainingsScheduleItemDetailList']>(
    id
      ? [`/api/trainings-schedule/group/${id}/`, JSON.stringify(params)]
      : null,
    (url) =>
      fetcher(url, {
        params,
      })
  );
}

export function useStudentsAge() {
  return useSWR<schema['StudentsAge']>('/api/students/age/');
}

export function useStudents(params: any) {
  return useCachedSWR<schema['PaginatedStudentListList']>(
    [`/api/students/`, JSON.stringify(params)],
    (url: string) =>
      fetcher(url, {
        params,
      })
  );
}

export function useGroupStudents(id: schema['TrainerGroupDetail']['id']) {
  return useStudents(useMemo(() => ({ group: id }), [id]));
}

export function useMoveStudentsToGroup() {
  return useCallback(
    (
      groupId: schema['TrainerGroupDetail']['id'],
      data: schema['TrainerGroupMoveRequest']
    ) =>
      fetcher<schema['TrainingsScheduleItemUpdate']>(
        `/api/groups/move/${groupId}/`,
        {
          method: 'POST',
          data,
        }
      ),
    []
  );
}

export function useCreateGroup() {
  return useCallback(
    (data: schema['TrainerGroupCreateRequest']) =>
      fetcher<schema['TrainerGroupCreate']>('/api/groups/', {
        method: 'POST',
        data,
      }),
    []
  );
}

export function useUpdateGroup() {
  return useCallback(
    (
      id: schema['TrainerGroupDetail']['id'],
      data: schema['PatchedTrainerGroupUpdateRequest']
    ) =>
      fetcher(`/api/groups/${id}/`, {
        method: 'PATCH',
        data,
      }),
    []
  );
}

export function useStudentDailySchedule(date: string) {
  return useSWR<schema['PaginatedTrainingsScheduleStudentList']>(
    `/api/trainings-schedule/student/schedule/day/${date}/`
  );
}

export function useStudentWeeklySchedule(date: string) {
  return useSWR<schema['PaginatedTrainingsScheduleStudentList']>(
    `/api/trainings-schedule/student/schedule/week/${date}/`
  );
}

export function useArticlesOnMain() {
  return useSWR<schema['ArticlesOnMain']>('/api/articles/categories/on-main/');
}

export function useBingoList(date: string) {
  return useSWR<schema['PaginatedBingoListList']>(`/api/bingo/list/${date}/`);
}

export function useArticleCategory(id: schema['ArticleCategory']['id']) {
  return useSWR<schema['ArticleCategory'], ApiError>(
    `/api/articles/categories/${id}/`
  );
}

export function useArticle(id: schema['ArticleDetail']['id']) {
  return useSWR<schema['ArticleDetail'], ApiError>(`/api/articles/${id}/`);
}

export function useCategoryArticles(
  category?: schema['ArticleCategory']['id']
) {
  return useSWR<schema['PaginatedArticleListList'], ApiError>(
    category ? [`/api/articles/`, category] : null,
    (url, category) =>
      fetcher(url, {
        params: {
          category,
        },
      })
  );
}

export function useCheckBingo() {
  return useCallback(
    (data: schema['BingoCheckRequest']) =>
      fetcher<schema['BingoCheck']>(`/api/bingo/check/`, {
        method: 'POST',
        data,
      }),
    []
  );
}

export function useRewards() {
  return useSWR<schema['Reward'][]>('/api/rewards/');
}

export function useStudentProgress(
  id: schema['StudentList']['id'],
  date: string
) {
  return useSWR<schema['StudentProgressTotal']>(
    `/api/trainings-schedule/student/progress/${id}/${date}/`
  );
}

export function useStudentTrainings(params?: any) {
  return useSWR<schema['PaginatedTrainingListList']>(
    ['/api/trainings/for-student/', JSON.stringify(params)],
    (url) =>
      fetcher(url, {
        params,
      })
  );
}

export function useSchedule(id: schema['TrainingsScheduleItemDetail']['id']) {
  return useSWR<schema['TrainingsScheduleItemDetail'], ApiError>(
    `/api/trainings-schedule/${id}/`
  );
}

export function useCreateFeedback() {
  return useCallback(
    (data: schema['CreateTrainingsFeedbackRequest']) =>
      fetcher<schema['CreateTrainingsFeedback']>(
        `/api/trainings-schedule/feedback/`,
        {
          method: 'POST',
          data,
        }
      ),
    []
  );
}

export function useUpdateStudentByStudent() {
  return useCallback(
    (data: schema['UpdateCurrentStudentRequest']) =>
      fetcher(`/api/auth/update-student/`, {
        method: 'POST',
        data,
      }),
    []
  );
}

export function useScheduleMaxOnDay() {
  return useSWR<schema['SettingsScheduleMaxOnDay']>(
    `/api/settings/schedule-max-on-day/`
  );
}

export function usePrivacyText() {
  return useSWR<schema['SettingsPrivacyPolicyText']>(
    `/api/settings/privacy-policy-text/`
  );
}

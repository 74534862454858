import Button from 'components/Button';
import styled from 'styled-components/macro';
import { ReactComponent as ClearIcon } from './clear.svg';

type FiltersProps = GetStyledProps<typeof Filters['Root']> & {
  onClear?: () => any;
};

export default function Filters({
  onClear,
  children,
  ...restProps
}: FiltersProps) {
  return (
    <Filters.Root {...restProps}>
      {children}
      <Filters.Clear onClick={onClear} />
    </Filters.Root>
  );
}

Filters.Root = styled.div`
  & > * + * {
    margin-top: 41px;
  }
`;

Filters.Clear = styled(Button).attrs({
  color: 'primary',
  variant: 'outlined',
  fullWidth: true,
  leading: <ClearIcon />,
  children: 'Очистить фильтры',
})`
  min-height: 48px;
`;

Filters.Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px;

  & > * {
    margin: 4px;
  }
`;

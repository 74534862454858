import { ComponentProps, PropsWithChildren } from 'react';
import { Tooltip as ReactTippy } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { css } from 'styled-components/macro';
import { ibm, rem } from 'styles/mixins';
import { mq } from 'styles/theme';

export const tooltipGlobalCss = css`
  .tippy-tooltip {
    background-color: ${(props) => props.theme.colors.textPrimary};
    color: ${(props) => props.theme.colors.textLight};
    padding: 6px 16px;
    border-radius: 5px;
    ${ibm(500)}
    font-size: ${rem(14)};
    line-height: ${rem(18)};
  }

  .tippy-tooltip[data-extra] {
    background: red;
  }

  @media ${mq.notDesktop} {
    .tippy-tooltip {
      display: none;
    }
  }
`;

export default function Tooltip(
  props: PropsWithChildren<ComponentProps<typeof ReactTippy>>
) {
  return <ReactTippy animation="fade" arrow {...props} />;
}

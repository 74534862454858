import { ComponentProps } from 'react';
import ReactInputRange from 'react-input-range';
import styled from 'styled-components/macro';

const InputRange = styled(
  ({
    className,
    ...restProps
  }: ComponentProps<typeof ReactInputRange> & { className?: string }) => (
    <ReactInputRange
      classNames={
        {
          inputRange: `${className}__inputRange`,
          track: `${className}__track`,
          activeTrack: `${className}__activeTrack`,
          slider: `${className}__slider`,
          valueLabel: `${className}__valueLabel`,
          labelContainer: `${className}__labelContainer`,
        } as any
      }
      {...restProps}
    />
  )
)`
  &__inputRange {
    position: relative;
    height: 41px;
    margin: 0 20px;
  }

  &__track,
  &__activeTrack {
    position: absolute;
    left: 0;
    right: 0;
    height: 8px;
    top: 50%;
    bottom: 0;
    background: ${(props) => props.theme.colors.backgroundAccent};
    border-radius: 10px;
    transform: translateY(-50%);
  }

  &__activeTrack {
    background: ${(props) => props.theme.colors.primary};
  }

  &__slider {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.primary};
    background: ${(props) => props.theme.colors.backgroundPrimary};
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);

    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 13.5px;
      border-left: 1px solid ${(props) => props.theme.colors.primary};
      border-right: 1px solid ${(props) => props.theme.colors.primary};
    }
  }

  &__valueLabel,
  &__labelContainer {
    display: none;
  }
`;

export default InputRange;

import { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';

type ListItemProps = GetStyledProps<typeof ListItem['Root']> & {
  control?: ReactNode;
  action?: ReactNode;
};

export default function ListItem({
  control,
  action,
  children,
  ...restProps
}: ListItemProps) {
  return (
    <ListItem.Root {...restProps}>
      {control && <ListItem.Control>{control}</ListItem.Control>}
      <ListItem.Content>{children}</ListItem.Content>
      {action}
    </ListItem.Root>
  );
}

ListItem.Root = styled.li<{ divider?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 12px;

  ${({ divider = true, theme }) =>
    divider &&
    css`
      border-bottom: 1px solid ${theme.colors.backgroundAccentAlternative};
    `}
`;

ListItem.Control = styled.div`
  margin-right: 16px;
`;

ListItem.Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

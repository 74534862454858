import Container from 'components/Container';
import Modal from 'components/Modal';
import Typography from 'components/Typography';
import styled from 'styled-components/macro';
import Card from 'components/Card';
import Avatar from 'components/Avatar';
import {
  ApiError,
  useUpdateStudentByStudent,
  useUploadPhoto,
  useUser,
} from 'hooks/data';
import { Field, Form, Formik } from 'formik';
import FormField from 'components/FormField';
import Label from 'components/Label';
import Input, { DateInput, PhoneInput } from 'components/Input';
import Button from 'components/Button';
import { mq } from 'styles/theme';
import { useMemo, useRef, useState } from 'react';
import CreateAvatarModal from 'components/CreateAvatarModal';
import CloseButton from 'components/CloseButton';
import { ReactComponent as UploadIcon } from './upload.svg';
import { ReactComponent as AvatarIcon } from './avatar.svg';

type EditStudentProfileModalProps = GetStyledProps<
  typeof EditStudentProfileModal['Root']
>;

export default function EditStudentProfileModal(
  props: EditStudentProfileModalProps
) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [avatarFile, setAvatarFile] = useState<Blob>();
  const [isAvatarCreate, setAvatarCreate] = useState(false);
  const user = useUser();
  const userData = user.data!;
  const updateStudent = useUpdateStudentByStudent();
  const uploadPhoto = useUploadPhoto();

  const avatarSrc = useMemo(() => {
    if (avatarFile) {
      return URL.createObjectURL(avatarFile);
    }

    return userData.photo;
  }, [avatarFile, userData.photo]);
  return (
    <EditStudentProfileModal.Root {...props}>
      <EditStudentProfileModal.Content>
        <EditStudentProfileModal.Head>
          <Typography variant="h1">Изменение данных</Typography>
          <CloseButton onClick={props.onRequestClose} />
        </EditStudentProfileModal.Head>
        <EditStudentProfileModal.Profile>
          <Avatar src={avatarSrc} />
          <EditStudentProfileModal.ProfileActions>
            <EditStudentProfileModal.ProfileAction
              onClick={() => inputRef.current?.click()}
            >
              <UploadIcon />
              <Typography>Загрузить новое фото</Typography>
            </EditStudentProfileModal.ProfileAction>
            <EditStudentProfileModal.ProfileAction
              onClick={() => setAvatarCreate(true)}
            >
              <AvatarIcon />
              <Typography>Создать аватар</Typography>
            </EditStudentProfileModal.ProfileAction>
          </EditStudentProfileModal.ProfileActions>
        </EditStudentProfileModal.Profile>
        <Formik
          onSubmit={async (values, formik) => {
            await updateStudent(values)
              .then(async () => {
                if (avatarFile) {
                  await uploadPhoto(avatarFile);
                  setAvatarFile(undefined);
                }

                (props.onRequestClose as any)?.();
                user.revalidate();
              })
              .catch((error: ApiError) => formik.setErrors(error.info));
          }}
          initialValues={{
            first_name: userData.first_name,
            last_name: userData.last_name,
            birth_day: userData.birth_day,
            phone: userData.phone,
            vk_link: userData.vk_link,
          }}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <EditStudentProfileModal.Fields>
                <FormField
                  error={formik.errors.first_name}
                  label={<Label htmlFor="first_name">Имя</Label>}
                >
                  <Field
                    id="first_name"
                    name="first_name"
                    error={formik.errors.first_name}
                    as={Input}
                  />
                </FormField>
                <FormField
                  error={formik.errors.last_name}
                  label={<Label htmlFor="last_name">Фамилия</Label>}
                >
                  <Field
                    id="last_name"
                    name="last_name"
                    error={formik.errors.last_name}
                    as={Input}
                  />
                </FormField>
                <FormField
                  error={formik.errors.birth_day}
                  label={<Label htmlFor="birth_day">День рождения</Label>}
                >
                  <Field
                    id="birth_day"
                    name="birth_day"
                    error={formik.errors.birth_day}
                    as={DateInput}
                  />
                </FormField>
                <FormField
                  error={formik.errors.phone}
                  label={<Label htmlFor="phone">Телефон (WhatsApp)</Label>}
                >
                  <Field
                    id="phone"
                    name="phone"
                    error={formik.errors.phone}
                    as={PhoneInput}
                  />
                </FormField>
                <FormField
                  error={formik.errors.vk_link}
                  label={
                    <Label htmlFor="vk_link">Ссылка на профиль vkontakte</Label>
                  }
                >
                  <Field
                    id="vk_link"
                    name="vk_link"
                    error={formik.errors.vk_link}
                    as={Input}
                  />
                </FormField>
              </EditStudentProfileModal.Fields>
              <EditStudentProfileModal.Submit disabled={formik.isSubmitting}>
                Сохранить
              </EditStudentProfileModal.Submit>
            </Form>
          )}
        </Formik>
      </EditStudentProfileModal.Content>

      <input
        hidden
        type="file"
        ref={inputRef}
        onChange={(event) => {
          const file = event.target.files?.[0];
          file && setAvatarFile(file);
        }}
      />

      <CreateAvatarModal
        isOpen={isAvatarCreate}
        onRequestClose={() => setAvatarCreate(false)}
        onSubmit={(data) => {
          setAvatarFile(data);
          setAvatarCreate(false);
        }}
      />
    </EditStudentProfileModal.Root>
  );
}

EditStudentProfileModal.Submit = styled(Button).attrs({
  type: 'submit',
  variant: 'contained',
  color: 'accent',
})``;

EditStudentProfileModal.Content = styled(Container)`
  padding-top: 90px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

EditStudentProfileModal.Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 120px;
`;

EditStudentProfileModal.Profile = styled(Card)`
  background: ${(props) => props.theme.colors.backgroundAccent};
  padding: 56px 32px 32px;
  position: relative;
  align-self: flex-start;

  ${Avatar.Root} {
    width: 96px;
    height: 96px;
    position: absolute;
    left: 48px;
    top: 0;
    transform: translate3d(0, -60%, 0);
  }
`;

EditStudentProfileModal.ProfileActions = styled.div`
  display: flex;
  & > * + * {
    margin-left: 32px;
  }
`;

EditStudentProfileModal.ProfileAction = styled(Card).attrs({ as: 'button' })`
  padding: 17px 7px 15px;
  text-align: center;
  width: 128px;

  & > * + * {
    margin-top: 13px;
  }

  &:hover {
    ${Typography} {
      color: #fff;
    }

    &:nth-child(1) {
      background: ${(props) => props.theme.colors.primaryLight};

      svg circle {
        fill: ${(props) => props.theme.colors.primaryLight};
        stroke: #fff;
      }
    }

    &:nth-child(2) {
      background: ${(props) => props.theme.colors.accent};
    }
  }
`;

EditStudentProfileModal.Fields = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin: 57px 0;
`;

EditStudentProfileModal.Root = styled(Modal)`
  .content {
    background: ${(props) => props.theme.colors.backgroundPrimary};
    left: 0;
    top: 0;
    right: 0;
    height: 100vh;
    overflow-y: auto;
  }

  @media ${mq.notDesktop} {
    ${EditStudentProfileModal.Content} {
      padding-top: 30px;
    }

    ${EditStudentProfileModal.Fields} {
      margin: 40px 0;
    }

    ${EditStudentProfileModal.Head} {
      margin-bottom: 90px;
    }

    @media ${mq.mobile} {
      ${EditStudentProfileModal.Profile} {
        align-self: stretch;
      }

      ${EditStudentProfileModal.ProfileAction} {
        flex: 1;
      }

      ${EditStudentProfileModal.Fields} {
        grid-template-columns: 1fr;
      }

      ${EditStudentProfileModal.Submit} {
        width: 100%;
      }
    }

    @media ${mq.tablet} {
      ${EditStudentProfileModal.Fields} {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;

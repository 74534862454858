import { ReactNode } from 'react';
import { createContainer } from 'unstated-next';
import { InstallStore } from './install';
import { LayoutStore } from './layout';
import { NotificationsStore } from './notifications';
import { IsIosStore } from './isIos';
import { TrainingVideoStore } from './trainingVideo';

function useGlobal() {
  return {
    install: InstallStore.useContainer(),
    layout: LayoutStore.useContainer(),
    notifications: NotificationsStore.useContainer(),
    trainingVideo: TrainingVideoStore.useContainer(),
    ios: IsIosStore.useContainer(),
  };
}

export const GlobalStore = createContainer(useGlobal);

export const useStore = GlobalStore.useContainer;

export type StoreProviderProps = {
  children?: ReactNode;
};

export function StoreProvider({ children }: StoreProviderProps) {
  return (
    <InstallStore.Provider>
      <IsIosStore.Provider>
        <LayoutStore.Provider>
          <NotificationsStore.Provider>
            <TrainingVideoStore.Provider>
              <GlobalStore.Provider>{children}</GlobalStore.Provider>
            </TrainingVideoStore.Provider>
          </NotificationsStore.Provider>
        </LayoutStore.Provider>
      </IsIosStore.Provider>
    </InstallStore.Provider>
  );
}

import Layout from 'components/Layout';
import TrainingForm from 'components/TrainingForm';
import Typography from 'components/Typography';
import { useHistory, useRouteMatch } from 'react-router';
import * as qs from 'utils/qs';
import { useEffect, useMemo, useState } from 'react';
import useDataLayer from 'hooks/useDataLayer';

import Loader from 'components/Loader';
import {
  ApiError,
  schema,
  useCreateTraining,
  useExercisesByIds,
} from 'hooks/data';
import styled from 'styled-components/macro';
import Container from 'components/Container';
import { mq } from 'styles/theme';

export default function TrainingsCreatePage() {
  const match = useRouteMatch<{ url: string }>();
  const title = 'Создание тренировки';
  useDataLayer({ title, alias: match.url });
  const history = useHistory();
  const query = useMemo(() => qs.parse(history.location.search), [
    history.location.search,
  ]);
  const [initialExercisesByType, setInitialExercisesByType] = useState<
    Record<string, Array<schema['Exercise']>>
  >();
  const initialExercisesId = useMemo(() => {
    if (query.exercises) {
      return Array.isArray(query.exercises)
        ? query.exercises.map(Number)
        : [Number(query.exercises)];
    } else {
      return [];
    }
  }, [query.exercises]);
  const initialExercises = useExercisesByIds(initialExercisesId);
  const createTraining = useCreateTraining();
  useEffect(() => {
    if (initialExercises.data && !initialExercisesByType) {
      const exercisesByType: Record<string, Array<schema['Exercise']>> = {};
      initialExercises.data.forEach((item) => {
        if (item) {
          if (exercisesByType[item.exercise_type.id]) {
            exercisesByType[item.exercise_type.id].push(item);
          } else {
            exercisesByType[item.exercise_type.id] = [item];
          }
        }
      });
      setInitialExercisesByType(exercisesByType);
    }
  }, [initialExercises.data, initialExercisesByType]);

  if (!initialExercisesByType) return <Loader />;

  return (
    <Layout>
      <TrainingsCreatePage.Content>
        <TrainingsCreatePage.Title>{title}</TrainingsCreatePage.Title>
        <TrainingForm
          initialExercisesByType={initialExercisesByType}
          onSubmit={(values, formik) =>
            createTraining(values)
              .then((result) =>
                history.push(`/materials/trainings/${result.id}`)
              )
              .catch((error: ApiError) => formik.setErrors(error.info))
          }
        />
      </TrainingsCreatePage.Content>
    </Layout>
  );
}

TrainingsCreatePage.Title = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 31px;
`;

TrainingsCreatePage.Content = styled(Container)`
  padding-top: 90px;
  padding-bottom: 90px;

  @media ${mq.notDesktop} {
    padding-top: 0;
  }
`;

import GroupInfo from 'components/GroupInfo';

import Loader from 'components/Loader';
import TabLink from 'components/TabLink';
import TabsLayout from 'components/TabsLayout';

import { useGroup, useGroupStudents } from 'hooks/data';
import ErrorPage from 'pages/_error';
import useDataLayer from 'hooks/useDataLayer';

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import ScheduleFragment from './schedule';
import StudentsFragment from './students';

export default function GroupPage() {
  const match = useRouteMatch<{ id: string; path: string; url: string }>();
  useDataLayer({ title: match.path, alias: match.url });
  const id = Number(match.params.id);
  const group = useGroup(id);
  const students = useGroupStudents(id);

  if (group.error) {
    return <ErrorPage statusCode={group.error.status} />;
  }

  if (group.data) {
    return (
      <TabsLayout
        header={
          <GroupPage.Info
            group={group.data}
            students={students.data?.count || 0}
          />
        }
        tabs={
          <>
            <TabLink exact to={`/students/groups/${id}/students`}>
              Ученики
            </TabLink>
            <TabLink exact to={`/students/groups/${id}/schedule`}>
              Расписание
            </TabLink>
          </>
        }
      >
        <Switch>
          <Route
            exactt
            path="/students/groups/:id/students"
            component={StudentsFragment}
          />
          <Route
            exactt
            path="/students/groups/:id/schedule"
            component={ScheduleFragment}
          />
          <Redirect to={`/students/groups/${id}/students`} />
        </Switch>
      </TabsLayout>
    );
  }

  return <Loader />;
}

GroupPage.Info = styled(GroupInfo)`
  margin-top: 88px;
  margin-bottom: 45px;

  @media ${mq.notDesktop} {
    margin-top: 45px;
  }
`;

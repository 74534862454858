import BingoCard from 'components/BingoCard';
import Breadcrumbs from 'components/Breadcrumbs';
import Card from 'components/Card';
import Container from 'components/Container';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import MascotMessage from 'components/MascotMessage';
import RewardItem from 'components/RewardItem';
import Typography from 'components/Typography';
import { UserGender } from 'utils/user';
import { useBingoList, useCheckBingo, useRewards, useUser } from 'hooks/data';
import useNow from 'hooks/useNow';
import { useMemo } from 'react';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import { toApiDate } from 'utils/date';
import useDataLayer from 'hooks/useDataLayer';
import { useRouteMatch } from 'react-router-dom';
import { ReactComponent as Mascot } from './mascot.svg';

export default function BingoPage() {
  const match = useRouteMatch<{ url: string }>();
  const title = 'Бинго этой недели';
  useDataLayer({ title, alias: match.url });
  const now = useNow();
  const date = toApiDate(now);
  const bingo = useBingoList(date);
  const checkBingo = useCheckBingo();
  const rewards = useRewards();
  const user = useUser();
  const bingoReward = useMemo(
    () =>
      bingo.data?.results?.every((bingo) => bingo.done) &&
      rewards.data?.find((item) => item.achievement.action === 'bingo'),
    [bingo.data?.results, rewards.data]
  );
  return bingo.data ? (
    <Layout>
      <BingoPage.Content>
        <BingoPage.Breadcrumbs
          items={[
            {
              to: '/',
              children: 'Мои занятия',
            },
            {
              to: '/bingo',
              children: 'Бинго этой недели',
            },
          ]}
        />
        <BingoPage.Title>{title}</BingoPage.Title>
        {bingoReward && (
          <BingoPage.Reward>
            <MascotMessage
              avatarProps={{
                as: Mascot,
              }}
            >
              <div>
                Ураа! Бинго заполнено! Отличный результат!
                <br />
                Ты{' '}
                {user.data!.gender === UserGender.FEMALE
                  ? 'заработала'
                  : 'заработал'}{' '}
                новую медальку :)
              </div>
            </MascotMessage>
            <RewardItem reward={bingoReward} hiddenName />
          </BingoPage.Reward>
        )}
        <BingoPage.Items>
          {bingo.data.results?.map((item) => (
            <BingoCard
              onClick={
                item.done
                  ? undefined
                  : () =>
                      checkBingo({
                        bingo: item.id,
                        adding_date: date,
                      }).then((result) => {
                        if (result.rewards.length > 0) {
                          rewards.revalidate();
                        }

                        bingo.revalidate();
                      })
              }
              key={item.id}
              bingo={item}
            />
          ))}
        </BingoPage.Items>
      </BingoPage.Content>
    </Layout>
  ) : (
    <Loader />
  );
}

BingoPage.Reward = styled(Card)`
  background: ${(props) => props.theme.colors.backgroundAccent};
  padding: 8px 36px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 72px;
`;

BingoPage.Breadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 28px;
`;
BingoPage.Title = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 46px;
`;

BingoPage.Items = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
`;

BingoPage.Content = styled(Container)`
  padding-top: 60px;
  padding-bottom: 60px;

  @media ${mq.notDesktop} {
    padding-top: 0;

    ${BingoPage.Items} {
      grid-template-columns: 1fr;
      gap: 16px;
    }

    ${BingoPage.Reward} {
      display: none;
    }
  }
`;

import styled from 'styled-components/macro';

import Logo from 'components/Logo';
import Container from 'components/Container';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';

import { mq } from 'styles/theme';
import { ReactComponent as Mascot404 } from './404.svg';
import { ReactComponent as Mascot500 } from './500.svg';

type ErrorPageProps = {
  statusCode: number;
};

export default function ErrorPage({ statusCode }: ErrorPageProps) {
  const isNotFound = statusCode === StatusCodes.NOT_FOUND;
  return (
    <ErrorPage.Root>
      <ErrorPage.Logo />
      <ErrorPage.Content>
        <ErrorPage.Details>
          <ErrorPage.Type>Ошибка {statusCode}</ErrorPage.Type>
          <ErrorPage.Title>
            {isNotFound
              ? 'Мы не смогли найти эту страницу :('
              : 'Похоже, наш сервер не в лучшей форме!'}
          </ErrorPage.Title>
          <ErrorPage.Description>
            {isNotFound ? (
              <>
                Но наш сайт всё ещё работает!
                <br />
                Заходи на главную, там наверняка много интересного!
              </>
            ) : (
              <>
                Не волнуйся, мы скоро всё починим!
                <br />А ты пока отдохни и попробуй зайти на сайт чуть позже.
              </>
            )}
          </ErrorPage.Description>
          {isNotFound && (
            <ErrorPage.Action forwardedAs={Link} to="/" color="accent">
              Перейти на главную
            </ErrorPage.Action>
          )}
        </ErrorPage.Details>
        <ErrorPage.Mascot as={isNotFound ? Mascot404 : Mascot500} />
      </ErrorPage.Content>
    </ErrorPage.Root>
  );
}

ErrorPage.Content = styled(Container)`
  flex: 1;
  display: flex;
  align-items: center;
`;

ErrorPage.Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

ErrorPage.Mascot = styled.svg`
  max-width: 100%;
`;

ErrorPage.Logo = styled(Logo)``;

ErrorPage.Type = styled(Typography).attrs({ variant: 'h3' })`
  margin-bottom: 19px;
  color: ${(props) => props.theme.colors.primary};
`;

ErrorPage.Title = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 28px;
`;

ErrorPage.Description = styled(Typography)``;

ErrorPage.Action = styled(Button)`
  margin-top: 41px;
`;

ErrorPage.Root = styled.div`
  padding: 32px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media ${mq.notDesktop} {
    padding: 16px 0;
    height: 100vh;

    ${ErrorPage.Logo} {
      display: none;
    }

    ${ErrorPage.Mascot} {
      flex: 1;
      width: 100%;
    }

    ${ErrorPage.Content} {
      flex-direction: column-reverse;
      align-items: stretch;
      height: 100%;
    }

    ${ErrorPage.Details} {
      align-items: stretch;
    }
  }
`;

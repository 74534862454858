import Modal from 'components/Modal';
import Typography from 'components/Typography';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import CloseButton from 'components/CloseButton';

type CardModalProps = GetStyledProps<typeof CardModal['Root']> & {
  title?: ReactNode;
};

export default function CardModal({
  children,
  title,
  ...restProps
}: CardModalProps) {
  return (
    <CardModal.Root {...restProps}>
      <CardModal.Head>
        <CardModal.Title>{title}</CardModal.Title>
        <CloseButton onClick={restProps.onRequestClose} />
      </CardModal.Head>
      {children}
    </CardModal.Root>
  );
}

CardModal.Title = styled(Typography).attrs({ variant: 'h2' })`
  white-space: pre-line;
`;

CardModal.Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
`;

CardModal.Root = styled(Modal)`
  .content {
    top: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => props.theme.colors.backgroundPrimary};
    max-width: 100%;
    width: 592px;
    padding: 40px;
    border-radius: 20px 0 0 20px;
    overflow-y: auto;
  }

  @media ${mq.notDesktop} {
    .content {
      top: initial;
      max-height: 100vh;
      bottom: 0;
      left: 0;
      width: auto;
      padding: 16px;
      border-radius: 20px 20px 0 0;
    }

    ${CardModal.Head} {
      margin-bottom: 16px;
    }
  }
`;

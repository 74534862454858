import Container from 'components/Container';
import Layout from 'components/Layout';
import { useStore } from 'store';
import MascotMessage from 'components/MascotMessage';
import Steps from 'components/Steps';
import {
  useArticlesOnMain,
  useBingoList,
  useStudentWeeklySchedule,
  useUser,
} from 'hooks/data';
import useNow from 'hooks/useNow';
import { useMemo } from 'react';
import styled from 'styled-components/macro';
import plural from 'plural-ru';
import useResponsive from 'hooks/useResponsive';
import { toApiDate } from 'utils/date';
import StudentScheduleCard from 'components/StudentScheduleCard';
import StudentTrainer from 'components/StudentTrainer';
import { mq } from 'styles/theme';
import Typography from 'components/Typography';
import ArticlesList from 'components/ArticlesList';
import ArticleCard from 'components/ArticleCard';
import Button from 'components/Button';
import { Link, useRouteMatch } from 'react-router-dom';
import StudentBingoSummary from 'components/StudentBingoSummary';
import InstallMessage from 'components/InstallMessage';
import moment from 'moment';
import useDataLayer from 'hooks/useDataLayer';

export default function StudentIndexPage() {
  const { isNotDesktop } = useResponsive();
  const { ios } = useStore();

  const match = useRouteMatch<{ id: string; url: string }>();
  useDataLayer({ title: 'Главная ученика', alias: match.url });
  return (
    <Layout background="accent">
      <TrainingsSection />
      <ArticlesSection />
      <TrainerSection />
      {isNotDesktop && ios.isIos && <InstallMessage />}
    </Layout>
  );
}

function TrainingsSection() {
  const { isNotDesktop } = useResponsive();
  const { ios } = useStore();
  const user = useUser();
  const now = useNow();
  const date = toApiDate(now);
  const schedules = useStudentWeeklySchedule(date);
  const bingo = useBingoList(date);
  const trainingsCount = schedules.data?.results?.length || 0;
  const trainingsCompleted = useMemo(
    () =>
      schedules.data?.results?.reduce(
        (acc, training) => (training.done ? acc + 1 : acc),
        0
      ) || 0,
    [schedules.data?.results]
  );
  const bingoCompleted = useMemo(() => {
    return (
      bingo.data?.results?.reduce(
        (acc, item) => (item.done ? acc + 1 : acc),
        0
      ) || 0
    );
  }, [bingo.data?.results]);
  if (!schedules.data) return null;
  return (
    <TrainingsSection.Root>
      <TrainingsSection.Head>
        {isNotDesktop && !ios.isIos && <InstallMessage />}
        <MascotMessage>
          <div>
            {trainingsCount > 0 ? (
              <>
                Привет, {user.data!.first_name}!<br />
                Давай пройдём тренировку!
              </>
            ) : (
              <>
                На сегодня тренировки закончились!
                <br />
                Но ты можешь выполнить бинго или тренировку со звездой
              </>
            )}
          </div>
        </MascotMessage>
        {trainingsCount > 0 && (
          <Steps
            label={`${plural(
              trainingsCompleted,
              'Пройдена',
              'Пройдено',
              'Пройдены'
            )} ${trainingsCompleted} ${plural(
              trainingsCompleted,
              'тренировка',
              'тренировки',
              'тренировок'
            )} из ${trainingsCount}`}
            count={trainingsCount}
            current={trainingsCompleted}
          />
        )}
      </TrainingsSection.Head>
      <TrainingsSection.Trainings>
        {schedules.data.results
          ?.filter(
            (item) =>
              !item.done && moment(item.training_date).isSameOrBefore(now, 'd')
          )
          .map((item) => (
            <StudentScheduleCard schedule={item} key={item.id} />
          ))}
      </TrainingsSection.Trainings>
      {bingo.data?.results?.length! > 0 && (
        <StudentBingoSummary
          current={bingoCompleted}
          count={bingo.data!.count || 0}
        />
      )}
    </TrainingsSection.Root>
  );
}

TrainingsSection.Trainings = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 47px;
  }
`;

TrainingsSection.Head = styled.div`
  margin-bottom: 68px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > * + * {
    margin-left: 16px;
  }

  ${Steps.Root} {
    width: 352px;
    max-width: 100%;
  }
`;

TrainingsSection.Root = styled(Container).attrs({ as: 'section' })`
  padding-top: 64px;
  padding-bottom: 152px;

  ${StudentBingoSummary.Root} {
    margin-top: 56px;
  }

  ${InstallMessage.Root} {
    margin-bottom: 40px;
  }

  @media ${mq.notDesktop} {
    padding-top: 30px;
    padding-bottom: 72px;

    ${TrainingsSection.Head} {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 40px;

      & > * + * {
        margin-left: 0;
        margin-top: 27px;
      }
    }
  }
`;

function ArticlesSection() {
  const { isNotDesktop } = useResponsive();
  const articles = useArticlesOnMain();
  if (!articles.data?.categories.length) {
    return null;
  }
  return (
    <ArticlesSection.Root>
      <Container>
        {articles.data?.categories.map((category) => {
          if (!category.articles.length) {
            return null;
          }

          return (
            <div key={category.category.id}>
              <ArticlesSection.Category>
                {category.category.name}
              </ArticlesSection.Category>
              <ArticlesList>
                {category.articles.map((article) => (
                  <ArticleCard
                    as={Link}
                    to={`/articles/${category.category.id}/${article.id}`}
                    key={article.id}
                    article={article}
                  />
                ))}
              </ArticlesList>
              <Button
                as={Link}
                to={`/articles/${category.category.id}`}
                variant="outlined"
                color="accent"
              >
                Смотреть все материалы
              </Button>
            </div>
          );
        })}
        {!isNotDesktop && <InstallMessage background="accent" />}
      </Container>
    </ArticlesSection.Root>
  );
}

ArticlesSection.Category = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 43px;
`;

ArticlesSection.Root = styled.div`
  background: ${(props) => props.theme.colors.backgroundPrimary};

  ${Container} {
    padding-top: 138px;
    padding-bottom: 152px;
    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 104px;
    }
  }

  ${ArticlesList} {
    margin-bottom: 64px;
  }

  @media ${mq.mobile} {
    ${Container} {
      padding-top: 60px;
      padding-bottom: 72px;
    }
  }

  @media ${mq.tablet} {
    ${Container} {
      padding-top: 72px;
      padding-bottom: 104px;
    }
  }
`;

function TrainerSection() {
  const user = useUser();
  return user.data?.trainer ? (
    <TrainerSection.Root>
      <StudentTrainer trainer={user.data.trainer} />
    </TrainerSection.Root>
  ) : null;
}

TrainerSection.Root = styled(Container).attrs({ as: 'section' })`
  padding-top: 40px;
  padding-bottom: 40px;
`;

import TabLink from 'components/TabLink';
import TabsLayout from 'components/TabsLayout';
import Typography from 'components/Typography';
import { Redirect, Route, Switch } from 'react-router-dom';
import useDataLayer from 'hooks/useDataLayer';
import { useRouteMatch } from 'react-router-dom';
import ExercisesFragment from './exercises';
import TrainingsFragment from './trainings';
import ArticlesFragment from './articles';
import { ReactComponent as Mascot } from './mascot.svg';

export default function MaterialsPage() {
  const match = useRouteMatch<{ url: string }>();
  const title = 'Материалы';
  useDataLayer({ title, alias: match.url });

  return (
    <TabsLayout
      header={<Typography variant="h1">{title}</Typography>}
      icon={<Mascot />}
      tabs={
        <>
          <TabLink exact to="/materials/trainings">
            Тренировки
          </TabLink>
          <TabLink to="/materials/exercises">Упражнения</TabLink>
          <TabLink to="/materials/articles">Статьи</TabLink>
        </>
      }
    >
      <Switch>
        <Route
          exact
          path="/materials/trainings"
          component={TrainingsFragment}
        />
        <Route
          exact
          path="/materials/exercises"
          component={ExercisesFragment}
        />
        <Route exact path="/materials/articles" component={ArticlesFragment} />
        <Redirect to="/materials/trainings" />
      </Switch>
    </TabsLayout>
  );
}

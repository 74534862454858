import { useUser } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import { useMemo, useState } from 'react';
import { getAge } from 'utils/date';
import plural from 'plural-ru';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import Button from 'components/Button';
import styled from 'styled-components/macro';
import Container from 'components/Container';
import Typography from 'components/Typography';
import Avatar from 'components/Avatar';
import { mq } from 'styles/theme';
import Details from 'components/Details';
import EditStudentProfileModal from 'components/EditStudentProfileModal';
import { useStore } from 'store';

export default function StudentProfileInfo() {
  const { isNotDesktop } = useResponsive();
  const { layout } = useStore();
  const user = useUser();
  const userData = user.data!;
  const age = useMemo(() => userData.birth_day && getAge(userData.birth_day), [
    userData.birth_day,
  ]);
  const [isEdit, setEdit] = useState(false);
  return (
    <>
      <StudentProfileInfo.Root>
        <StudentProfileInfo.Content>
          <StudentProfileInfo.Avatar src={userData.photo} />
          <StudentProfileInfo.Main>
            <StudentProfileInfo.Name>
              {userData.first_name} {userData.last_name}
            </StudentProfileInfo.Name>
            <StudentProfileInfo.Details>
              {age && (
                <div>
                  {age} {plural(age, 'год', 'года', 'лет')}
                </div>
              )}
              {userData.level && <div>Уровень {userData.level.name}</div>}
            </StudentProfileInfo.Details>
          </StudentProfileInfo.Main>
          <StudentProfileInfo.Actions>
            <Button
              leading={<EditIcon />}
              variant="outlined"
              color="accent"
              onClick={() => setEdit(true)}
            >
              Изменить
            </Button>
            {isNotDesktop && (
              <Button
                onClick={() => layout.setLogout(true)}
                variant="outlined"
                color="accent"
              >
                Выйти
              </Button>
            )}
          </StudentProfileInfo.Actions>
        </StudentProfileInfo.Content>
      </StudentProfileInfo.Root>

      <EditStudentProfileModal
        isOpen={isEdit}
        onRequestClose={() => setEdit(false)}
      />
    </>
  );
}

StudentProfileInfo.Content = styled(Container)`
  display: flex;
`;
StudentProfileInfo.Main = styled.div`
  flex: 1;
  align-self: center;
`;
StudentProfileInfo.Actions = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 24px;
  }

  ${Button.Root} {
    min-height: 48px;
    font-weight: 500;
  }
`;

StudentProfileInfo.Avatar = styled(Avatar)`
  width: 96px;
  height: 96px;
  margin-right: 29px;
`;

StudentProfileInfo.Name = styled(Typography).attrs({ variant: 'h1' })`
  margin-bottom: 18px;
`;
StudentProfileInfo.Details = styled(Details)``;

StudentProfileInfo.Root = styled.section`
  padding-top: 96px;
  padding-bottom: 40px;

  @media ${mq.tablet} {
    padding-top: 80px;
  }

  @media ${mq.mobile} {
    padding-top: 28px;

    ${StudentProfileInfo.Content} {
      flex-direction: column;
    }

    ${StudentProfileInfo.Main} {
      align-self: stretch;
      margin-bottom: 21px;
    }

    ${StudentProfileInfo.Avatar} {
      margin-right: 0;
      margin-bottom: 16px;
    }

    ${StudentProfileInfo.Name} {
      margin-bottom: 4px;
    }

    ${StudentProfileInfo.Actions} {
      & > * {
        flex: 1;
      }
    }
  }
`;

import Button from 'components/Button';
import Container from 'components/Container';
import List from 'components/List';
import ProgressListItem from 'components/ProgressListItem';
import ProgressPeriodToggle, {
  ProgressPeriod,
} from 'components/ProgressPeriodToggle';
import Typography from 'components/Typography';
import { useSkills, useStudentProgress, useUser } from 'hooks/data';
import useNow from 'hooks/useNow';
import useResponsive from 'hooks/useResponsive';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import { toApiDate } from 'utils/date';
import { ReactComponent as Mascot } from './mascot.svg';
import { ReactComponent as MascotMobile } from './mascot_mobile.svg';

export default function StudentProfileProgress() {
  const { isNotDesktop } = useResponsive();
  const [period, setPeriod] = useState(ProgressPeriod.WEEK);
  const now = useNow();
  const user = useUser();
  const progress = useStudentProgress(user.data!.id, toApiDate(now));
  const skills = useSkills();
  const progressData = progress.data?.[period];
  const hasProgress = useMemo(
    () => progress.data?.month.progress.some((item) => item.done_count > 0),
    [progress.data?.month.progress]
  );
  return (
    <StudentProfileProgress.Root>
      <StudentProfileProgress.Content>
        <StudentProfileProgress.Head>
          <StudentProfileProgress.Title variant="h3">
            Мои успехи
          </StudentProfileProgress.Title>
          <ProgressPeriodToggle value={period} onChange={setPeriod} />
        </StudentProfileProgress.Head>
        <List>
          {skills?.map((skill) => (
            <ProgressListItem
              key={skill.id}
              skill={skill}
              progress={progressData?.progress.find(
                (item) => item.skill.id === skill.id
              )}
            />
          ))}
        </List>
        <StudentProfileProgress.Footer>
          <StudentProfileProgress.Trainings
            forwardedAs={Link}
            to="/trainings/week"
            variant="contained"
            color="accent"
          >
            Тренировки недели
          </StudentProfileProgress.Trainings>
          {hasProgress && (isNotDesktop ? <MascotMobile /> : <Mascot />)}
        </StudentProfileProgress.Footer>
      </StudentProfileProgress.Content>
    </StudentProfileProgress.Root>
  );
}

StudentProfileProgress.Trainings = styled(Button)`
  margin-bottom: 152px;
`;

StudentProfileProgress.Content = styled(Container)``;

StudentProfileProgress.Footer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

StudentProfileProgress.Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 49px;
`;

StudentProfileProgress.Title = styled(Typography)``;

StudentProfileProgress.Root = styled.section`
  background: ${(props) => props.theme.colors.backgroundPrimary};
  padding-top: 39px;

  ${List} {
    margin-bottom: 48px;
  }

  @media ${mq.mobile} {
    ${StudentProfileProgress.Head} {
      flex-direction: column;
      align-items: stretch;

      & > * + * {
        margin-top: 22px;
      }
    }

    ${StudentProfileProgress.Footer} {
      flex-direction: column;
      align-items: stretch;

      & > * + * {
        margin-top: 39px;
      }

      svg {
        max-width: 100%;
        align-self: center;
        height: auto;
      }
    }

    ${StudentProfileProgress.Trainings} {
      margin-bottom: 16px;
    }
  }
`;

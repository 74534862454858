import Typography from 'components/Typography';
import { NavLink } from 'react-router-dom';
import { ComponentProps, Fragment } from 'react';
import styled from 'styled-components/macro';
import useResponsive from 'hooks/useResponsive';
import { ReactComponent as Separator } from './separator.svg';
import { ReactComponent as Back } from './back.svg';

type BreadcrumbsProps = GetStyledProps<typeof Breadcrumbs['Root']> & {
  items?: GetStyledProps<typeof Breadcrumbs['Item']>[];
};

export default function Breadcrumbs({ items, ...restProps }: BreadcrumbsProps) {
  const { isNotDesktop } = useResponsive();

  if (isNotDesktop) {
    const previousItem = items?.[items?.length - 2];
    return (
      <Breadcrumbs.Root {...restProps}>
        {previousItem && (
          <Breadcrumbs.Item {...previousItem}>
            <Breadcrumbs.Back />
            {previousItem.children}
          </Breadcrumbs.Item>
        )}
      </Breadcrumbs.Root>
    );
  }

  return (
    <Breadcrumbs.Root {...restProps}>
      {items?.map((item, key) => (
        <Fragment key={key}>
          <Breadcrumbs.Item {...item} />
          {key + 1 !== items.length && <Breadcrumbs.Separator />}
        </Fragment>
      ))}
    </Breadcrumbs.Root>
  );
}

Breadcrumbs.Root = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 10px;
  }
`;

Breadcrumbs.Back = styled(Back)`
  margin-right: 6px;
`;

Breadcrumbs.Separator = styled(Separator)``;

Breadcrumbs.Item = styled(Typography).attrs({
  as: NavLink,
  activeClassName: 'active',
  exact: true,
  variant: 'text',
})<ComponentProps<typeof NavLink>>`
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;

  &.active {
    color: ${(props) => props.theme.colors.gray3};
  }
`;

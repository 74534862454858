import Button from 'components/Button';
import Container from 'components/Container';
import Logo from 'components/Logo';
import Typography from 'components/Typography';
import { ReactNode, ComponentProps } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ibm, rem } from 'styles/mixins';
import { mq } from 'styles/theme';
import { ReactComponent as HeaderLogo } from './logo.svg';

type HeaderProps = GetStyledProps<typeof Header['Root']> & {
  end?: ReactNode;
};

export default function Header({ children, end, ...restProps }: HeaderProps) {
  return (
    <Header.Root {...restProps}>
      <Header.Start>
        <Link to="/">
          <Header.Logo />
        </Link>
      </Header.Start>
      <Header.Container>{children}</Header.Container>
      <Header.End>{end}</Header.End>
    </Header.Root>
  );
}

Header.Logo = styled(HeaderLogo)``;

Header.Start = styled.div`
  flex: 1;
`;

Header.Container = styled(Container)`
  height: 100%;
  display: flex;

  @media ${mq.notDesktopXl} {
    padding: 0 24px;
  }
`;

Header.End = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

Header.AuthButton = styled(Button).attrs({
  variant: 'outlined',
  ignoreHover: true,
})`
  ${ibm(400)}
  font-size: ${rem(18)};
  line-height: ${rem(18)};
  min-height: 40px;
  color: ${(props) => props.theme.colors.textLight};
  border-color: ${(props) => props.theme.colors.textLight};
  border-width: 1px;
`;

Header.NavItem = styled(Typography).attrs({
  as: NavLink,
  activeClassName: 'active',
})<ComponentProps<typeof NavLink>>`
  color: ${(props) => props.theme.colors.textLight};
  padding: 0 8px;
  position: relative;
  display: inline-flex;
  align-items: center;

  & + & {
    margin-left: 34px;
  }

  &.active {
    &::after {
      content: '';
      background-color: ${(props) => props.theme.colors.backgroundAccent};
      height: 8px;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
`;

Header.Root = styled.header`
  height: ${(props) => props.theme.header.height}px;
  background: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
`;

type MobileHeaderProps = GetStyledProps<typeof MobileHeader['Root']>;

export function MobileHeader(props: MobileHeaderProps) {
  return (
    <MobileHeader.Root {...props}>
      <Link to="/">
        <Logo />
      </Link>
    </MobileHeader.Root>
  );
}

MobileHeader.Root = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px;
`;

import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Card from 'components/Card';
import Container from 'components/Container';
import Layout from 'components/Layout';
import MascotMessage from 'components/MascotMessage';
import Typography from 'components/Typography';
import { schema, useGroups, useUser } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import useDataLayer from 'hooks/useDataLayer';

import { useMemo } from 'react';
import List from 'components/List';
import GroupListItem from 'components/GroupListItem';
import { useStore } from 'store';
import InstallMessage from 'components/InstallMessage';
import TrainerActionCard from 'components/TrainerActionCard';
import { ReactComponent as StudentIcon } from './student.svg';
import { ReactComponent as GroupIcon } from './group.svg';
import { ReactComponent as TrainingIcon } from './training.svg';

export default function TrainerIndexPage() {
  const match = useRouteMatch<{ url: string }>();
  useDataLayer({ title: 'Главная тренера', alias: match.url });
  const { isNotDesktop } = useResponsive();
  const { ios } = useStore();
  const user = useUser();
  const groups = useGroups();
  const actions = useMemo(
    () => [
      {
        icon: <StudentIcon />,
        text: 'Добавить ученика',
        to: '/students/add',
      },
      {
        icon: <GroupIcon />,
        text: 'Создать группу',
        to: '/students/groups/create',
      },
      {
        icon: <TrainingIcon />,
        text: 'Создать тренировку',
        to: '/materials/trainings/create',
      },
    ],
    []
  );
  return (
    <Layout background="accent">
      <TrainerIndexPage.Content>
        {isNotDesktop && !ios.isIos && <InstallMessage />}
        <TrainerIndexPage.Mascot>
          <div>
            Добрый день, {user.data!.first_name}! Давайте
            <br /> проверим прогресс учеников
          </div>
        </TrainerIndexPage.Mascot>
        <TrainerIndexPage.Main>
          <TrainerIndexPage.Left>
            {groups.data && <TrainerGroupsCard data={groups.data} />}
            {isNotDesktop && <TrainerIndexPage.Profile info={user.data!} />}
          </TrainerIndexPage.Left>
          <TrainerIndexPage.Actions>
            {actions.map((action, key) => (
              <TrainerIndexPage.Action key={key} icon={action.icon}>
                <Button
                  color="accent"
                  variant="outlined"
                  as={Link}
                  to={action.to}
                  leading={<PlusIcon />}
                >
                  {action.text}
                </Button>
              </TrainerIndexPage.Action>
            ))}
            {(!isNotDesktop || ios.isIos) && <InstallMessage />}
          </TrainerIndexPage.Actions>
        </TrainerIndexPage.Main>
      </TrainerIndexPage.Content>
    </Layout>
  );
}

TrainerIndexPage.Profile = styled(TrainerProfileCard)`
  margin-top: 48px;
`;

TrainerIndexPage.Main = styled.div`
  display: flex;
  align-items: flex-start;
`;
TrainerIndexPage.Left = styled.div`
  flex: 1;
`;
TrainerIndexPage.Actions = styled.div`
  margin-left: 96px;
  flex-basis: 352px;

  & > * + * {
    margin-top: 80px;
  }
`;
TrainerIndexPage.Action = styled(TrainerActionCard)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Button.Root} {
    width: 100%;
  }
`;
TrainerIndexPage.Mascot = styled(MascotMessage)`
  margin-bottom: 48px;
`;

TrainerIndexPage.Content = styled(Container)`
  padding-top: 72px;
  padding-bottom: 24px;

  ${InstallMessage.Root} {
    margin-bottom: 46px;
  }

  @media ${mq.notDesktop} {
    padding-top: 20px;

    ${TrainerIndexPage.Main} {
      flex-direction: column;
      align-items: stretch;
    }

    ${TrainerIndexPage.Actions} {
      flex-basis: auto;
      margin-left: 0;
      margin-bottom: 48px;
      margin-top: 48px;
      order: 0;
    }

    ${TrainerIndexPage.Left} {
      order: 1;
    }
  }

  @media ${mq.tablet} {
    ${TrainerIndexPage.Action} ${Button.Root} {
      width: 312px;
    }
  }
`;

type TrainerGroupsCardProps = GetStyledProps<
  typeof TrainerGroupsCard['Root']
> & {
  data: schema['PaginatedTrainerGroupListList'];
};
function TrainerGroupsCard({ data, ...restProps }: TrainerGroupsCardProps) {
  return (
    <TrainerGroupsCard.Root {...restProps}>
      <TrainerGroupsCard.Title>Группы</TrainerGroupsCard.Title>
      {data.results?.length! > 0 ? (
        <List>
          {data.results?.map((item) => (
            <GroupListItem
              button
              forwardedAs={Link}
              to={`/students/groups/${item.id}`}
              key={item.id}
              group={item}
            />
          ))}
        </List>
      ) : (
        <Typography>
          У вас пока нет групп. Для начала работы добавьте учеников, затем
          создайте группы.
        </Typography>
      )}
    </TrainerGroupsCard.Root>
  );
}

TrainerGroupsCard.Title = styled(Typography).attrs({ variant: 'h3' })`
  margin-bottom: 17px;
`;
TrainerGroupsCard.Root = styled(Card)`
  padding: 24px 32px;
`;

type TrainerProfileCardProps = GetStyledProps<
  typeof TrainerProfileCard['Root']
> & {
  info: schema['UserInfo'];
};
function TrainerProfileCard({ info, ...restProps }: TrainerProfileCardProps) {
  const { layout } = useStore();
  return (
    <TrainerProfileCard.Root {...restProps}>
      <TrainerProfileCard.Main>
        <TrainerProfileCard.Avatar src={info.photo} />
        <Typography variant="h3">
          {info.first_name} {info.last_name}
        </Typography>
      </TrainerProfileCard.Main>
      <TrainerProfileCard.Actions>
        <Button to="/profile" color="accent" variant="outlined" as={Link}>
          Настройки
        </Button>
        <Button
          onClick={() => layout.setLogout(true)}
          variant="text"
          color="primary"
        >
          Выйти из профиля
        </Button>
      </TrainerProfileCard.Actions>
    </TrainerProfileCard.Root>
  );
}

TrainerProfileCard.Main = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 38px;
`;
TrainerProfileCard.Actions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`;
TrainerProfileCard.Avatar = styled(Avatar)`
  margin-right: 16px;
`;
TrainerProfileCard.Root = styled(Card)`
  padding: 25px;

  @media ${mq.mobile} {
    ${TrainerProfileCard.Actions} {
      grid-template-columns: 1fr;
    }
  }
`;

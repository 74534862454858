import moment from 'moment';
import capitalize from 'lodash/capitalize';

export const daysInWeek = 7;

export function getMonthOptions(now: moment.Moment) {
  const ret: moment.Moment[] = [];
  const current = toMonthOption(now);
  const startDate = current.clone().subtract(1, 'year');
  const endDate = current.clone().add(1, 'year');
  while (startDate.isSameOrBefore(endDate)) {
    ret.push(startDate.clone());
    startDate.add(1, 'month');
  }
  return ret;
}

export function toMonthOption(now: moment.Moment): moment.Moment {
  return now.clone().startOf('month');
}

export function getCalendarItems(month: moment.Moment) {
  const start = month.clone().startOf('month').startOf('isoWeek');
  const end = month.clone().endOf('month').endOf('isoWeek');
  const ret: moment.Moment[] = [];
  while (start.isSameOrBefore(end)) {
    ret.push(start.clone());
    start.add(1, 'day');
  }
  return ret;
}

export const API_DATE_FORMAT = 'YYYY-MM-DD';

export function toApiDate(date: moment.Moment): string {
  return date.format(API_DATE_FORMAT);
}

export function fromApiDate(date: any): moment.Moment {
  return moment(date, API_DATE_FORMAT);
}

export function getAge(date: string) {
  return moment().diff(fromApiDate(date), 'years');
}

export function humanize(date: moment.Moment) {
  const days = date
    .clone()
    .startOf('day')
    .diff(moment().startOf('day'), 'days');

  if (days === -1) {
    return 'Вчера';
  } else if (days === 0) {
    return 'Сегодня';
  } else if (days === 1) {
    return 'Завтра';
  } else {
    return capitalize(date.format('dddd D MMM'));
  }
}

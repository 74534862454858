import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ibm, rem } from 'styles/mixins';

type BottomNavigationProps = GetStyledProps<typeof BottomNavigation['Root']> & {
  items?: Array<
    GetStyledProps<typeof BottomNavigation['Item']> & {
      title?: ReactNode;
      icon?: ReactNode;
      activeIcon?: ReactNode;
    }
  >;
};

export default function BottomNavigation({
  items,
  ...restProps
}: BottomNavigationProps) {
  return (
    <BottomNavigation.Root {...restProps}>
      {items?.map(({ title, icon, activeIcon, ...restProps }, key) => (
        <BottomNavigation.Item key={key} {...restProps}>
          <BottomNavigation.ItemIcon>{icon}</BottomNavigation.ItemIcon>
          <BottomNavigation.ItemIcon data-active>
            {activeIcon || icon}
          </BottomNavigation.ItemIcon>
          <BottomNavigation.ItemTitle>{title}</BottomNavigation.ItemTitle>
        </BottomNavigation.Item>
      ))}
    </BottomNavigation.Root>
  );
}

BottomNavigation.ItemIcon = styled.div`
  flex-basis: 50%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;

  &[data-active] {
    display: none;
  }
`;

BottomNavigation.ItemTitle = styled.div`
  flex-basis: 50%;
  color: ${(props) => props.theme.colors.textPrimary};
  ${ibm(400)}
  font-size: ${rem(16)};
  line-height: ${rem(21)};
`;

BottomNavigation.Item = styled(NavLink).attrs({
  activeClassName: 'active',
})`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  & + & {
    border-left: 1px solid
      ${(props) => props.theme.bottomNavigation.dividerColor};
  }

  &.active {
    ${BottomNavigation.ItemTitle} {
      color: ${(props) => props.theme.colors.primary};
    }

    ${BottomNavigation.ItemIcon} {
      display: none;
      &[data-active] {
        display: flex;
      }
    }
  }
`;
BottomNavigation.Root = styled.div`
  background: ${(props) => props.theme.colors.backgroundPrimary};
  height: ${(props) => props.theme.bottomNavigation.height}px;
  display: flex;
  box-shadow: inset 0px 1px 0px
    ${(props) => props.theme.bottomNavigation.dividerColor};
`;

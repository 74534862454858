import Surface from 'components/Surface';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

type FormFieldProps = GetStyledProps<typeof FormField['Root']> & {
  label?: ReactNode;
  error?: ReactNode;
};

export default function FormField({
  children,
  label,
  error,
  ...restProps
}: FormFieldProps) {
  return (
    <FormField.Root {...restProps}>
      <FormField.Label>{label}</FormField.Label>
      <FormField.Control>
        {error && <FormField.Error>{error}</FormField.Error>}
        {children}
      </FormField.Control>
    </FormField.Root>
  );
}

FormField.Root = styled.div`
  display: flex;
  flex-direction: column;
`;

FormField.Label = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

FormField.Control = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

FormField.Error = styled(Surface).attrs({ variant: 'error' })`
  left: 0;
  top: 0;
  transform: translateY(-100%);
  max-width: 100%;
  position: absolute;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: ${(props) => props.theme.colors.surfaceError};
    bottom: 0;
    left: 20px;
    transform: translateY(50%) rotate(45deg);
  }
`;

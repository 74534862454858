import Button from 'components/Button';
import Card from 'components/Card';
import FormField from 'components/FormField';
import { PasswordInput } from 'components/Input';
import Label from 'components/Label';
import Surface from 'components/Surface';
import Typography from 'components/Typography';

import { Field, Form, Formik } from 'formik';
import { ApiError, schema, useUpdatePassword } from 'hooks/data';
import useResponsive from 'hooks/useResponsive';
import { useStore } from 'store';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

export type UpdatePasswordCardValues = schema['UpdatePasswordRequest'];

type UpdatePasswordCardProps = GetStyledProps<
  typeof UpdatePasswordCard['Root']
> & {};

const initialValues: UpdatePasswordCardValues = {
  old_password: '',
  new_password: '',
};

export default function UpdatePasswordCard({
  className,
  ...restProps
}: UpdatePasswordCardProps) {
  const { isNotDesktop } = useResponsive();
  const updatePassword = useUpdatePassword();
  const { notifications } = useStore();
  return (
    <Formik
      onSubmit={(values, formik) =>
        updatePassword(values)
          .then(() => {
            notifications.add({
              content: 'Вы успешно сменили пароль',
            });
            formik.resetForm();
          })
          .catch((error: ApiError) => formik.setErrors(error.info))
      }
      initialValues={initialValues}
    >
      {(formik) => (
        <UpdatePasswordCard.Root {...restProps}>
          <UpdatePasswordCard.Title variant={isNotDesktop ? 'h3' : 'h2'}>
            Изменение пароля
          </UpdatePasswordCard.Title>
          {(formik.errors as any).detail && (
            <Surface variant="error">{(formik.errors as any).detail}</Surface>
          )}
          <UpdatePasswordCard.Fields>
            <FormField
              error={formik.errors.old_password}
              label={
                <Label htmlFor="old_password">Введите старый пароль</Label>
              }
            >
              <Field
                name="old_password"
                id="old_password"
                error={formik.errors.old_password}
                as={PasswordInput}
              />
            </FormField>
            <FormField
              error={formik.errors.new_password}
              label={
                <Label htmlFor="new_password">Придумайте новый пароль</Label>
              }
            >
              <Field
                name="new_password"
                id="new_password"
                error={formik.errors.new_password}
                as={PasswordInput}
              />
            </FormField>
          </UpdatePasswordCard.Fields>
          <Button
            disabled={
              formik.isSubmitting ||
              !formik.values.old_password ||
              !formik.values.new_password
            }
            variant="contained"
            color="accent"
            type="submit"
            fullWidth={isNotDesktop}
          >
            Сохранить пароль
          </Button>
        </UpdatePasswordCard.Root>
      )}
    </Formik>
  );
}

UpdatePasswordCard.Title = styled(Typography)`
  margin-bottom: 27px;
`;

UpdatePasswordCard.Fields = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-bottom: 40px;
`;

UpdatePasswordCard.Root = styled(Card).attrs({ as: Form })`
  padding: 40px 131px 64px 68px;

  @media ${mq.notDesktop} {
    padding: 24px 16px;

    ${UpdatePasswordCard.Fields} {
      grid-template-columns: auto;
    }
  }
`;

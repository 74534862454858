import AvatarUpload from 'components/AvatarUpload';
import Button from 'components/Button';
import Card from 'components/Card';
import FormField from 'components/FormField';
import GenderToggle from 'components/GenderToggle';
import Input, { PhoneInput } from 'components/Input';
import Label from 'components/Label';
import { Field, Form, Formik, FormikConfig } from 'formik';
import { schema } from 'hooks/data';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

export type EditTrainerCardValues = schema['UpdateTrainerRequest'] & {
  photo?: string | File;
};

type EditTrainerCardProps = {
  initialValues: EditTrainerCardValues;
  onSubmit: FormikConfig<EditTrainerCardValues>['onSubmit'];
};

export default function EditTrainerCard({
  initialValues,
  onSubmit,
  ...restProps
}: EditTrainerCardProps) {
  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {(formik) => (
        <EditTrainerCard.Root {...restProps}>
          <EditTrainerCard.Photo>
            <AvatarUpload
              value={formik.values.photo}
              onChange={(value) => formik.setFieldValue('photo', value)}
            />
          </EditTrainerCard.Photo>
          <EditTrainerCard.Fields>
            <FormField
              error={formik.errors.first_name}
              label={<Label htmlFor="first_name">Имя</Label>}
            >
              <Field
                id="first_name"
                name="first_name"
                error={formik.errors.first_name}
                as={Input}
              />
            </FormField>
            <FormField
              error={formik.errors.last_name}
              label={<Label htmlFor="last_name">Фамилия</Label>}
            >
              <Field
                id="last_name"
                name="last_name"
                error={formik.errors.last_name}
                as={Input}
              />
            </FormField>
            <FormField
              error={formik.errors.gender}
              label={<Label htmlFor="gender">Фамилия</Label>}
            >
              <Field
                id="gender"
                name="gender"
                error={formik.errors.gender}
                as={GenderToggle}
                onChange={(gender: schema['GenderEnum']) =>
                  formik.setFieldValue('gender', gender)
                }
              />
            </FormField>
            <FormField
              error={formik.errors.company}
              label={<Label htmlFor="company">Организация</Label>}
            >
              <Field
                id="company"
                name="company"
                error={formik.errors.company}
                as={Input}
              />
            </FormField>
            <FormField
              error={formik.errors.email}
              label={<Label htmlFor="email">E-mail</Label>}
            >
              <Field
                id="email"
                name="email"
                error={formik.errors.email}
                as={Input}
              />
            </FormField>
            <FormField
              error={formik.errors.phone}
              label={<Label htmlFor="phone">Телефон (WhatsApp)</Label>}
            >
              <Field
                id="phone"
                name="phone"
                error={formik.errors.phone}
                as={PhoneInput}
              />
            </FormField>
            <FormField
              error={formik.errors.vk_link}
              label={
                <Label htmlFor="vk_link">Ссылка на профиль Vkontakte</Label>
              }
            >
              <Field
                id="vk_link"
                name="vk_link"
                error={formik.errors.vk_link}
                as={Input}
              />
            </FormField>
          </EditTrainerCard.Fields>
          <EditTrainerCard.Submit disabled={formik.isSubmitting}>
            Сохранить
          </EditTrainerCard.Submit>
        </EditTrainerCard.Root>
      )}
    </Formik>
  );
}

EditTrainerCard.Photo = styled.div`
  position: absolute;
  top: 0;
  left: 66px;
  transform: translateY(-50%);
`;
EditTrainerCard.Fields = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  :nth-child(4) {
    grid-column: 1/3;
  }

  :nth-child(5) {
    grid-column-start: 1;
  }
`;
EditTrainerCard.Submit = styled(Button).attrs({
  type: 'submit',
  variant: 'contained',
  color: 'accent',
})`
  margin-top: 40px;
`;
EditTrainerCard.Root = styled(Card).attrs({ as: Form })`
  padding: 60px 68px 64px;
  position: relative;

  @media ${mq.notDesktop} {
    padding: 60px 16px 24px;

    ${EditTrainerCard.Photo} {
      left: 16px;
    }

    ${EditTrainerCard.Fields} {
      grid-template-columns: 1fr;

      :nth-child(4) {
        grid-column: auto;
      }
    }

    ${EditTrainerCard.Submit} {
      width: 100%;
    }
  }
`;

import Container from 'components/Container';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal';
import Typography from 'components/Typography';
import styled, { css } from 'styled-components/macro';
import Card from 'components/Card';
import Button from 'components/Button';
import Select from 'components/Select';
import { useMemo, useRef, useState } from 'react';
import { useUser } from 'hooks/data';
import { UserGender } from 'utils/user';
import { mq } from 'styles/theme';

import ConfirmationModal from 'components/ConfirmationModal';
import CloseButton from 'components/CloseButton';
import { ReactComponent as BackArrow } from './back_arrow.svg';
import { ReactComponent as ForwardArrow } from './forward_arrow.svg';
import { EMPTY_VALUE, hairColors, options, skinColors } from './data';

type CreateAvatarModalProps = GetStyledProps<
  typeof CreateAvatarModal['Root']
> & {
  onSubmit?: (data: Blob) => any;
};

const resolveSvgComponent = (name: string) =>
  require(`@svgr/webpack!./assets/${name}.svg`).ReactComponent;

export default function CreateAvatarModal({
  onSubmit,
  onRequestClose,
  ...restProps
}: CreateAvatarModalProps) {
  const canvasRef = useRef<SVGSVGElement>();
  const [option, setOption] = useState(0);
  const [isClose, setClose] = useState(false);
  const selectedOption = options[option];
  const userData = useUser().data!;
  const [state, setState] = useState(
    userData.gender === UserGender.FEMALE
      ? {
          face: 0,
          head: 0,
          hair_color: 0,
          hair_type: 0,
          clothes: 0,
          accessories: EMPTY_VALUE,
        }
      : {
          face: 2,
          head: 0,
          hair_color: 0,
          hair_type: 7,
          clothes: 0,
          accessories: EMPTY_VALUE,
        }
  );
  const Assets = useMemo(
    () => ({
      Face: resolveSvgComponent(`face-${state.face}`),
      Head: resolveSvgComponent(`head-${state.head}`),
      Hair: resolveSvgComponent(`hair-${state.hair_type}-${state.hair_color}`),
      Clothes: resolveSvgComponent(`clothes-${state.clothes}`),
      Accessories:
        state.accessories !== EMPTY_VALUE &&
        resolveSvgComponent(`accessories-${state.accessories}`),
    }),
    [
      state.accessories,
      state.clothes,
      state.face,
      state.hair_color,
      state.hair_type,
      state.head,
    ]
  );
  const currentPrimary = state[selectedOption.primary.key] + 1;
  const maxPrimary =
    selectedOption.primary.values[selectedOption.primary.values.length - 1] + 1;
  return (
    <CreateAvatarModal.Root
      onRequestClose={() => setClose(true)}
      {...restProps}
    >
      <CreateAvatarModal.Content>
        <CreateAvatarModal.Head>
          <Typography variant="h1">Создание аватара</Typography>
          <CloseButton onClick={() => setClose(true)} />
        </CreateAvatarModal.Head>
        <CreateAvatarModal.Card>
          <Select
            value={option}
            options={options.map((option, i) => ({
              label: option.label,
              value: i,
            }))}
            onChange={setOption}
          />
          <CreateAvatarModal.Label>
            {selectedOption.primary.label}: {currentPrimary}/{maxPrimary}
          </CreateAvatarModal.Label>
          <CreateAvatarModal.Viewport>
            <CreateAvatarModal.ArrowButton
              disabled={
                state[selectedOption.primary.key] <=
                selectedOption.primary.values[0]
              }
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  [selectedOption.primary.key]:
                    prev[selectedOption.primary.key] - 1,
                }))
              }
            >
              <BackArrow />
            </CreateAvatarModal.ArrowButton>
            <CreateAvatarModal.Canvas
              ref={canvasRef as any}
              width="232"
              height="232"
              viewBox="0 0 232 232"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="background">
                <rect width="232" height="232" fill="#D7D5EF" />
              </g>
              {Assets.Clothes && <Assets.Clothes />}
              {Assets.Head && <Assets.Head />}
              {Assets.Face && <Assets.Face />}
              {Assets.Hair && <Assets.Hair />}
              {Assets.Accessories && <Assets.Accessories />}
            </CreateAvatarModal.Canvas>
            <CreateAvatarModal.ArrowButton
              disabled={currentPrimary === maxPrimary}
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  [selectedOption.primary.key]:
                    prev[selectedOption.primary.key] + 1,
                }))
              }
            >
              <ForwardArrow />
            </CreateAvatarModal.ArrowButton>
          </CreateAvatarModal.Viewport>
          {selectedOption.secondary && (
            <CreateAvatarModal.Secondary>
              <CreateAvatarModal.Label>
                {selectedOption.secondary.label}
              </CreateAvatarModal.Label>
              <CreateAvatarModal.SecondaryValues>
                {selectedOption.secondary.values.map((value) => (
                  <CreateAvatarModal.SecondaryValue
                    key={value}
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        [selectedOption.secondary!.key]: value,
                      }))
                    }
                    $selected={value === state[selectedOption.secondary!.key]}
                    style={{
                      backgroundColor:
                        selectedOption.secondary!.key === 'hair_color'
                          ? hairColors[value]
                          : skinColors[value],
                    }}
                  />
                ))}
              </CreateAvatarModal.SecondaryValues>
            </CreateAvatarModal.Secondary>
          )}
        </CreateAvatarModal.Card>
        <Button
          onClick={() =>
            onSubmit?.(
              new Blob([canvasRef.current!.outerHTML], {
                type: 'image/svg+xml',
              })
            )
          }
          variant="contained"
          color="accent"
        >
          Сохранить
        </Button>
      </CreateAvatarModal.Content>

      <ConfirmationModal
        isOpen={isClose}
        onConfirm={() => {
          (onRequestClose as any)?.();
          setClose(false);
        }}
        onRequestClose={() => setClose(false)}
        text="Ты точно хочешь отменить?"
        description="В этом случае изменения не сохранятся"
        confirmText="Да, я хочу выйти"
        cancelText="Нет"
      />
    </CreateAvatarModal.Root>
  );
}

CreateAvatarModal.Label = styled(Typography)``;

CreateAvatarModal.ArrowButton = styled(IconButton)`
  border: 2px solid ${(props) => props.theme.colors.accent};
  border-radius: 50%;
  width: 56px;
  height: 56px;

  &:hover {
    background: ${(props) => props.theme.colors.accent};
    path {
      stroke: ${(props) => props.theme.colors.textLight};
    }
  }

  &:disabled {
    opacity: 0.35;
    pointer-events: none;
  }
`;

CreateAvatarModal.Secondary = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.backgroundAccent};
  padding-top: 10px;

  ${CreateAvatarModal.Label} {
    margin-bottom: 9px;
  }
`;

CreateAvatarModal.SecondaryValues = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
`;

CreateAvatarModal.SecondaryValue = styled.button<{ $selected?: boolean }>`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 16px;
  }

  ${(props) =>
    props.$selected &&
    css`
      border: 3px solid ${props.theme.colors.primaryLight};
    `}
`;

CreateAvatarModal.Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 46px;
  align-self: stretch;
`;

CreateAvatarModal.Content = styled(Container)`
  padding-top: 80px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

CreateAvatarModal.Viewport = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

CreateAvatarModal.Canvas = styled.svg`
  border-radius: 50%;
`;

CreateAvatarModal.Card = styled(Card)`
  padding: 64px 56px 40px;
  margin-bottom: 48px;
  min-width: 544px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

CreateAvatarModal.Root = styled(Modal)`
  .content {
    background: ${(props) => props.theme.colors.backgroundAccent};
    left: 0;
    top: 0;
    right: 0;
    height: 100vh;
    overflow-y: auto;
  }

  ${Select.Root} {
    margin-bottom: 30px;
  }

  @media ${mq.notDesktop} {
    .content {
      background: ${(props) => props.theme.colors.backgroundPrimary};
    }

    ${CreateAvatarModal.Content} {
      padding-top: 16px;
      padding-bottom: 16px;
      align-items: stretch;
    }

    ${CreateAvatarModal.Card} {
      padding: 0;
      min-width: auto;
    }
  }
`;

import { nanoid } from 'nanoid';
import { ReactNode, useCallback, useState } from 'react';
import { createContainer } from 'unstated-next';

export const NOTIFICATION_DEFAULT = 5000;

export type NotificationData = {
  key: string;
  content?: ReactNode;
  timeout: number;
};

export function useNotifications() {
  const [state, setState] = useState<NotificationData[]>([]);
  const add = useCallback(
    ({
      content,
      timeout = NOTIFICATION_DEFAULT,
    }: Partial<Pick<NotificationData, 'content' | 'timeout'>>) =>
      setState((prev) => [...prev, { content, timeout, key: nanoid() }]),
    []
  );
  const remove = useCallback(
    (key: NotificationData['key']) =>
      setState((prev) =>
        prev.filter((notification) => notification.key !== key)
      ),
    []
  );
  return {
    add,
    remove,
    state,
  };
}

export const NotificationsStore = createContainer(useNotifications);

import Input from 'components/Input';
import TextareaAutosize from 'react-autosize-textarea';
import styled from 'styled-components/macro';

const Textarea = styled(Input.Input).attrs({ as: TextareaAutosize, rows: 2 })`
  height: auto;
  padding: 16px 24px;
  resize: none;
`;

export default Textarea;

import BodyBackground from 'components/BodyBackground';
import Button from 'components/Button';
import Card from 'components/Card';
import Container from 'components/Container';
import FormField from 'components/FormField';
import Header, { MobileHeader } from 'components/Header';
import { PasswordInput } from 'components/Input';
import Label from 'components/Label';
import Surface from 'components/Surface';
import Typography from 'components/Typography';
import { Field, Form, Formik } from 'formik';
import { ApiError, useSetPassword } from 'hooks/data';
import useDataLayer from 'hooks/useDataLayer';
import useResponsive from 'hooks/useResponsive';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { mq } from 'styles/theme';

export default function ResetPasswordPage() {
  const match = useRouteMatch<{ token: string; url: string }>();
  const title = 'Восстановление пароля';
  useDataLayer({ title, alias: match.url });
  const { isNotDesktop } = useResponsive();
  const setPassword = useSetPassword();
  return (
    <>
      <BodyBackground color="accent" />
      <ResetPasswordPage.Root>
        {isNotDesktop ? <MobileHeader /> : <Header />}
        <ResetPasswordPage.Content>
          <Typography variant="h1">{title}</Typography>
          <Formik
            onSubmit={(values, formik) =>
              setPassword(values)
                .then(() => {
                  window.location.href = '/';
                })
                .catch((error: ApiError) => formik.setErrors(error.info))
            }
            initialValues={{ new_password: '', token: match.params.token }}
          >
            {(formik) => (
              <ResetPasswordPage.Form>
                {formik.errors.token && (
                  <Surface variant="error">{formik.errors.token}</Surface>
                )}
                <FormField
                  error={formik.errors.new_password}
                  label={
                    <Label htmlFor="new_password">Введите новый пароль</Label>
                  }
                >
                  <Field
                    name="new_password"
                    id="new_password"
                    error={formik.errors.new_password}
                    as={PasswordInput}
                  />
                </FormField>
                <ResetPasswordPage.Submit disabled={formik.isSubmitting}>
                  Подтвердить
                </ResetPasswordPage.Submit>
              </ResetPasswordPage.Form>
            )}
          </Formik>
        </ResetPasswordPage.Content>
      </ResetPasswordPage.Root>
    </>
  );
}

ResetPasswordPage.Content = styled(Container)`
  padding-top: 64px;
  padding-bottom: 32px;
`;

ResetPasswordPage.Form = styled(Card).attrs({ as: Form })`
  padding: 32px;
  margin-top: 40px;
`;

ResetPasswordPage.Submit = styled(Button).attrs({
  type: 'submit',
  color: 'accent',
  variant: 'contained',
})`
  margin-top: 24px;
`;

ResetPasswordPage.Root = styled.div`
  @media ${mq.notDesktop} {
    ${ResetPasswordPage.Content} {
      padding-top: 0;
    }

    ${ResetPasswordPage.Form} {
      padding: 16px;
    }

    ${ResetPasswordPage.Submit} {
      width: 100%;
    }
  }
`;

import { useDebounce } from 'use-debounce';

export const DEBOUNCE_DEFAULT = 300;

export default function useDebouncedValue<T>(
  value: T,
  delay = DEBOUNCE_DEFAULT
) {
  return useDebounce(value, delay)[0];
}

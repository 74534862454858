import Layout from 'components/Layout';
import TrainingForm from 'components/TrainingForm';
import { useHistory, useRouteMatch } from 'react-router';
import { ApiError, useTraining, useUpdateTraining } from 'hooks/data';
import ErrorPage from 'pages/_error';
import Loader from 'components/Loader';
import useDataLayer from 'hooks/useDataLayer';

import TrainingsCreatePage from '../create';

export default function TrainignsEditPage() {
  const history = useHistory();
  const match = useRouteMatch<{ id: string; url: string }>();
  const title = 'Редактирование тренировки';
  useDataLayer({ title, alias: match.url });
  const id = Number(match.params.id);
  const training = useTraining(id);
  const updateTraining = useUpdateTraining();

  if (training.error) {
    return <ErrorPage statusCode={training.error.status} />;
  }

  if (training.data) {
    return (
      <Layout>
        <TrainingsCreatePage.Content>
          <TrainingsCreatePage.Title>{title}</TrainingsCreatePage.Title>
          <TrainingForm
            initialValues={{
              name: training.data.name,
              skill: training.data.skill.id,
              level: training.data.level.id,
              equipment: training.data.equipment,
              description: training.data.description,
            }}
            initialExercisesByType={training.data.exercise_types.reduce(
              (acc, exercise) => ({
                ...acc,
                [exercise.id]: exercise.exercises,
              }),
              {}
            )}
            onSubmit={(values, formik) =>
              updateTraining(id, values)
                .then(() => history.push(`/materials/trainings/${id}`))
                .catch((error: ApiError) => formik.setErrors(error.info))
            }
          />
        </TrainingsCreatePage.Content>
      </Layout>
    );
  }

  return <Loader />;
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

export function useInstall() {
  const [event, setEvent] = useState<BeforeInstallPromptEvent>();
  const onBeforeInstallPrompt = useCallback((e: BeforeInstallPromptEvent) => {
    e.preventDefault();
    setEvent(e);
  }, []);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt);
    };
  }, [onBeforeInstallPrompt]);

  return useMemo(() => {
    if (!event) {
      return undefined;
    }

    return () => {
      event.prompt();
      return event.userChoice.then((choice) => {
        if (choice.outcome === 'accepted') {
          setEvent(undefined);
        }
        return choice;
      });
    };
  }, [event]);
}

export const InstallStore = createContainer(useInstall);

import Modal from 'components/Modal';
import Button from 'components/Button';
import Typography from 'components/Typography';
import styled, { css } from 'styled-components/macro';
import { mq } from 'styles/theme';
import { ReactNode } from 'react';
import { ReactComponent as Mascot } from './mascot.svg';

type ConfirmationModalProps = GetStyledProps<
  typeof ConfirmationModal['Root']
> & {
  text?: ReactNode;
  description?: ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => any;
};

export default function ConfirmationModal({
  text,
  confirmText,
  cancelText,
  onConfirm,
  description,
  ...restProps
}: ConfirmationModalProps) {
  return (
    <ConfirmationModal.Root $hasDescription={!!description} {...restProps}>
      <Mascot />
      <ConfirmationModal.Text>{text}</ConfirmationModal.Text>
      {description && (
        <ConfirmationModal.Description>
          {description}
        </ConfirmationModal.Description>
      )}
      <ConfirmationModal.Actions>
        <ConfirmationModal.Action onClick={onConfirm}>
          {confirmText}
        </ConfirmationModal.Action>
        <ConfirmationModal.Action onClick={restProps.onRequestClose}>
          {cancelText}
        </ConfirmationModal.Action>
      </ConfirmationModal.Actions>
    </ConfirmationModal.Root>
  );
}

ConfirmationModal.Description = styled(Typography)`
  margin-bottom: 45px;
`;

ConfirmationModal.Text = styled(Typography).attrs({ variant: 'h1' })`
  text-align: center;
  max-width: 597px;
  margin: 33px 0 55px;
`;

ConfirmationModal.Actions = styled.div`
  display: flex;
  align-items: center;
`;

ConfirmationModal.Action = styled(Button).attrs({
  variant: 'outlined',
  color: 'accent',
})`
  & + & {
    margin-left: 32px;
  }
`;

ConfirmationModal.Root = styled(Modal)<{ $hasDescription?: boolean }>`
  .content {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: ${(props) => props.theme.colors.backgroundPrimary};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${(props) =>
      props.$hasDescription &&
      css`
        ${ConfirmationModal.Text} {
          margin-bottom: 24px;
        }
      `}

    @media ${mq.notDesktop} {
      padding: 16px;

      ${ConfirmationModal.Actions} {
        flex-direction: column;
        align-items: stretch;
        align-self: stretch;
      }

      ${ConfirmationModal.Action} + ${ConfirmationModal.Action} {
        margin-left: 0;
        margin-top: 24px;
      }
    }
  }
`;

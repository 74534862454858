import Avatar from 'components/Avatar';
import { schema } from 'hooks/data';
import { ComponentProps } from 'react';
import styled from 'styled-components/macro';
import { ibm, rem } from 'styles/mixins';

type GroupAvatarProps = ComponentProps<typeof Avatar> & {
  name?: schema['TrainerGroupDetail']['name'];
  level?: Pick<schema['Level'], 'color'>;
};

const GroupAvatar = styled(
  ({ name, level, ...restProps }: GroupAvatarProps) => (
    <Avatar style={{ background: level?.color }} {...restProps}>
      {name && Array.from(name)[0]}
    </Avatar>
  )
)`
  ${ibm(500)}
  color: ${(props) => props.theme.colors.textLight};
  font-size: ${rem(22)};
  line-height: ${rem(26)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

export default GroupAvatar;

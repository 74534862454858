import ButtonToggle from 'components/ButtonToggle';
import styled from 'styled-components/macro';

export enum ProgressPeriod {
  WEEK = 'week',
  MONTH = 'month',
}

const ProgressPeriodToggle = styled(ButtonToggle).attrs({
  items: [
    {
      value: ProgressPeriod.WEEK,
      renderTitle: () => 'Неделя',
    },
    {
      value: ProgressPeriod.MONTH,
      renderTitle: () => 'Месяц',
    },
  ],
})`
  ${ButtonToggle.Item} {
    min-width: 140px;
    min-height: 48px;
  }
`;

export default ProgressPeriodToggle;
